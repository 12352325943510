import React from 'react'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import TextField from '@mui/material/TextField';
import { useField } from 'formik';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';



export default function DatePicker({ label, ...props }) {
    const [field, meta, helpers] = useField(props);
    // const timeZoneFromServer = "Asia/Kolkata";
    return (
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                    // onChange={(option) => helpers.setValue(moment(option).format('YYYY-MM-DD'))}
                    onChange={(option) => helpers.setValue(option)}
                    label={label}                    
                    inputFormat="DD-MM-YYYY"
                    // views={["year", "month", "date"]}      
                    renderInput={(params) => <TextField variant='outlined' size="small" fullWidth {...field} {...params} autoComplete='off' error={meta.touched && Boolean(meta.error)}
                    helperText={meta.touched && meta.error} />}
                    {...meta}
                    {...props}
                />
            </LocalizationProvider>
    )
}