import React from 'react'
import { Formik, Form } from 'formik';
import * as Yup from 'yup'
import LoadingButton from '@mui/lab/LoadingButton';
import { InputField } from '../../Common/Fields/BSField';
// import { useUnderMaintenance } from '../../../hooks/circuit/under_maintenance';
import toast from 'react-hot-toast';
// import { FlashAutoRounded } from '@mui/icons-material';
import { useTerminateCircuitHandle } from '../../../hooks/circuit/terminate';
import AbortTerminationButton from './AbortTerminationButton';

export default function TerminateForm({ data, setFormMode, circuitData }) {
    const { handleTerminateAction, terminateIsLoading } = useTerminateCircuitHandle(data?.circuit ? 'PATCH' : 'POST')

    const handleSubmit = async (values, { resetForm }) => {
        const toastId = toast.loading('Processing...');
        let res = null
        if (data?.circuit) {
            res = await handleTerminateAction({
                expiry_date: values.expiry_date,
                comment: values.comment,
                id: data?.id
            })
        } else {
            res = await handleTerminateAction({
                expiry_date: values.expiry_date,
                comment: values.comment,
                circuit: circuitData?.id
            })
        }
        if (res.success) {
            toast.success(res.detail, { id: toastId });

            setFormMode('')
            resetForm({ values: '' });
        } else {
            toast.error(res.detail, { id: toastId })
        }
    }



    const validate = Yup.object({
        expiry_date: Yup.date().required('Required').nullable(),
        comment: Yup.string().required('Required'),
    })

    return (
        <Formik
            initialValues={{
                expiry_date: data?.expiry_date ?? new Date(),
                circuit: data?.circuit,
                comment: data?.comment ?? '',
            }}
            validationSchema={validate}
            onSubmit={handleSubmit}
        >
            {formik => (
                <Form>
                    <div className='row'>
                        <div className='col-md-12'>
                            <InputField label='Terminate Date' type='date' name='expiry_date' placeholder='' />
                        </div>
                        <div className='col-md-12'>
                            <InputField label='Comment *' type='text' name='comment' placeholder='Comment here' />
                        </div>
                    </div>
                    <LoadingButton
                        loading={terminateIsLoading}
                        variant="contained"
                        disableElevation
                        type="submit"
                        size="small"
                        color="success"
                        sx={{ mr: 1 }}
                    >
                        {data?.circuit ? 'Update' : 'Add'} Terminate
                    </LoadingButton>
                    {data?.circuit && !data?.is_terminated && <AbortTerminationButton data={data} setFormMode={setFormMode} />}

                    {/* <Link to="/" className="auth-forgot-password float-end">das</Link> */}
                </Form>)}
        </Formik>
    )
}
