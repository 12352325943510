import React, { useEffect, useState } from 'react';
import DataTableBase from '../../../components/Common/DataTableBase';
import { formatDateTime } from '../../../utils/shortcuts';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useUser } from '../../../hooks/user';
import PageBreadcrumbs from '../../../components/Common/Breadcrumbs';
import Alert from '../../../components/Common/Alert';
import { useCloseDocket, useDocketUpdate, useDocketUpdateRequest, useDocketUpdateRequestList } from '../../../hooks/docket';
import { toast } from 'react-toastify';
import DocketUpdateRequestModal from '../../../components/Models/DocketUpdateRequestModal';
import DocketUpdateRequestFilterModal from '../../../components/Models/DocketUpdateRequestFilterModal';
import { useDebouncedCallback } from 'use-debounce';

export default function DocketUpdateRequestList() {
    let navigate = useNavigate();
    const { user } = useUser();
    const [enableApi, setAPI] = useState(false)

    const { handleDocketRequestAction, docketRequestIsLoading } = useDocketUpdateRequest('PATCH');
    const { handleDocketCloseAction, docketCloseActionIsLoading } = useCloseDocket('PATCH');
    const { handleDocketUpdate, isDocketUpdating } = useDocketUpdate(); // Using for File Uploading (Axios)
    const { updateRequestsData, updateRequestsIsLoading, filterUpdateRequest } = useDocketUpdateRequestList();
    const [requestsFilterModal, setRequestsFilterModal] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    // console.log(updateRequestsData)
    // Handling Docket Requests
    const [docketReqModal, setDocketReqModal] = useState(false)
    const [currentDocketReqData, setCurrentDocketReqData] = useState(null)

    const columns = [
        {
            name: '# ID',
            selector: row => row.id,
            sortable: true,
            width: '50px !important',
        },
        {
            name: 'Circuit',
            cell: (row) => (<>
                <Link to={`/circuit/${row.get_circuit.id}`}>{row.get_circuit.code}</Link>
            </>)
        },
        {
            name: 'Docket',
            cell: (row) => (<>
                <Link to={`/docket/${row.get_docket.code}`}>{row.get_docket.code}</Link>
            </>)
        },
        {
            name: 'Requested by',
            selector: row => `${row.get_req_by.account_name} (${row?.get_req_by?.roles?.join(', ')})`,
        },
        {
            name: 'Action by',
            selector: row => row.get_update_by ? `${row.get_update_by.account_name} (${row?.get_update_by?.roles?.join(', ')})` : 'N/A',
        },
        {
            name: 'Status',
            selector: row => row.status,
            sortable: true,
        },
        {
            name: 'Type',
            selector: row => `${row.update_type} Request`,
            sortable: true,
        },
        {
            name: 'Created at',
            sortable: true,
            selector: row => formatDateTime(row.created_at, 'D-M-YY h:ma'),
        },
        {
            name: 'Last Updated',
            sortable: true,
            selector: row => formatDateTime(row.updated_at, 'D-M-YY h:ma'),
        },
        {
            name: 'Actions',
            sortable: true,
            selector: (row) => (
                <div className='d-flex gap-1'>
                    <button className="btn btn-info btn-style-light pb-0" onClick={() => {
                        setCurrentDocketReqData(row)
                        setDocketReqModal(true)
                    }} title='Info' ><span className="material-icons-sharp">info</span></button>
                    {row.status === 'Pending' && user?.roles?.includes('EXP') && <>
                        <div className='d-flex gap-2'>
                            <button className="btn btn-primary btn-style-light pb-0" disabled={docketRequestIsLoading | docketCloseActionIsLoading | isDocketUpdating} onClick={() => takeAction(row, 'accept', row.update_type)} title='Accept' ><span className="material-icons-sharp">check</span></button>
                            <button className="btn btn-danger btn-style-light pb-0" disabled={docketRequestIsLoading | docketCloseActionIsLoading | isDocketUpdating} onClick={() => takeAction(row, 'cancel', row.update_type)} title='Cancel' ><span className="material-icons-sharp">close</span></button>
                        </div>
                    </>}


                </div>
            ),
        },
    ];
    const conditionalRowStyles = [
        {
            when: row => row.status === 'Accept',
            style: {
                backgroundColor: '#70d9106b',
                color: '#000000de',
                '&:hover': {
                    cursor: 'pointer',
                },
            },
        },
        {
            when: row => row.status === 'Pending',
            style: {
                backgroundColor: '#f5f5f5',
                color: '#686767',
                '&:hover': {
                    cursor: 'pointer',
                },
            },
        },
        {
            when: row => row.status === 'Cancel',
            style: {
                backgroundColor: '#fe8e8e',
                color: '#fff',
                '&:hover': {
                    cursor: 'pointer',
                },
            },
        },

    ];


    const takeAction = async (data, status, type) => {
        if (status === 'accept' && type === 'Close') {
            // ACCEPT CLOSE ACTION
            // console.log('accept close')

            const update_docket_close = await handleDocketCloseAction({
                ...data,
                docket: {
                    technician: data.technician,
                    technician2: data.technician2
                },
                id: data.get_docket.code,
            })
            if (update_docket_close.success) {
                const res = await handleDocketRequestAction({
                    docket: data.docket,
                    update_type: data.update_type,
                    status: 'Accept',
                    id: data.id
                })
                if (res.success) {
                    toast.success(`Action performed on ${data.get_docket.code}`);
                    navigate(`/docket/close/${data.get_docket.code}`);
                } else {
                    toast.error(res.detail)
                }
            } else {
                toast.error(update_docket_close.detail)
            }
        } else if (status === 'accept' && type === 'Hold') {
            // ACCEPT HOLD ACTION
            // console.log('accept hold', data)
            if (data.get_docket.status === "Hold") {
                toast.error('Docket already in hold state. Please cancel this request.')
                return;
            }
            const formData = new FormData();
            // files && files.forEach(file => {
            //     formData.append("files", file);
            // });
            const { remarks, vehicle1, vehicle1_start_km, vehicle1_end_km, vehicle2, vehicle2_start_km, vehicle2_end_km } = data;
            // formData.append("files", files);
            formData.append("status", 'Hold');
            formData.append("remarks", remarks);
            // formData.append("vehicle1", vehicle1);
            vehicle1 && formData.append("vehicle1", vehicle1);
            formData.append("vehicle1_start_km", vehicle1_start_km);
            formData.append("vehicle1_end_km", vehicle1_end_km);
            if (vehicle2) {
                formData.append("vehicle2", vehicle2);
                formData.append("vehicle2_start_km", vehicle2_start_km);
                formData.append("vehicle2_end_km", vehicle2_end_km);
            }
            formData.append("action", 'Hold');
            const res = await handleDocketUpdate(formData, data.get_docket.code)
            // console.log(res)
            if (res.success) {

                const res = await handleDocketRequestAction({
                    docket: data.docket,
                    update_type: data.update_type,
                    status: 'Accept',
                    id: data.id
                })
                if (res.success) {
                    // toast.success(`Action performed on ${data.get_docket.code}`);
                    toast.success(`#${data.get_docket.code} - Set on Hold.`);
                    navigate(`/docket/${data.get_docket.code}`);
                } else {
                    toast.error(res.detail)
                }
            } else {
                toast.error(res.detail)
            }

        } else if (status === 'accept' && type === 'Resume') {
            // ACCEPT RESUME ACTION
            console.log('accept resume', data)
            if (data.get_docket.status !== "Hold") {
                toast.error('Docket already in resume state. Please cancel this request.')
                return;
            }
            const formData = new FormData();
            const { remarks } = data;
            formData.append("status", 'Resume');
            formData.append("remarks", remarks);
            formData.append("action", 'Resume');
            const res = await handleDocketUpdate(formData, data.get_docket.code)
            if (res.success) {
                const res = await handleDocketRequestAction({
                    docket: data.docket,
                    update_type: data.update_type,
                    status: 'Accept',
                    id: data.id
                })
                if (res.success) {
                    toast.success(`#${data.get_docket.code} - Resumed.`);
                    navigate(`/docket/${data.get_docket.code}`);
                } else {
                    toast.error(res.detail)
                }
            } else {
                toast.error(res.detail)
            }

        } else if (status === 'accept' && type === 'Cancel') {
            // ACCEPT CANCEL ACTION
            if (data.get_docket.status === "Hold") {
                toast.error('Docket in hold state. Please cancel this request.')
                return;
            }
            const formData = new FormData();
            const { remarks } = data;
            formData.append("status", 'Cancelled');
            formData.append("remarks", remarks);
            formData.append("action", 'Cancelled');
            const res = await handleDocketUpdate(formData, data.get_docket.code)
            if (res.success) {

                const res = await handleDocketRequestAction({
                    docket: data.docket,
                    update_type: data.update_type,
                    status: 'Accept',
                    id: data.id
                })
                if (res.success) {
                    toast.success(`#${data.get_docket.code} - has Cancelled.`);
                    navigate(`/docket/${data.get_docket.code}`);
                } else {
                    toast.error(res.detail)
                }
            } else {
                toast.error(res.detail)
            }

        } else if (status === 'cancel') {
            // CANCEL ACTION
            const res = await handleDocketRequestAction({
                docket: data.docket,
                update_type: data.update_type,
                status: 'Cancel',
                id: data.id
            })
            if (res.success) {
                toast.success(`Action performed on ${data.get_docket.code}`);
            } else {
                toast.error(res.detail)
            }

        }

    }

    // Filter Data 
    // useEffect(() => {
    //     const currentParams = Object.fromEntries([...searchParams]);
    //     let params = ''
    //     params = new URLSearchParams(currentParams).toString() || 'status=&limit=20';
    //     params && filterUpdateRequest(`?${params}`)
    // }, [searchParams])

    // Fetch Data
    const fetchData = useDebouncedCallback(() => {
        const currentParams = Object.fromEntries([...searchParams]);
        let params = ''
        const limit = parseInt(currentParams?.limit ?? '10');
        const status = '';
      

        params = new URLSearchParams({ ...currentParams, limit }).toString();
        
        setAPI(true)
        params && filterUpdateRequest(`?${params}`)
    }, 300)

    useEffect(() => {
        fetchData()

    }, [searchParams])
    return (
        <>
            <Helmet>
                <title>Docket Requests - List</title>
            </Helmet>
            <PageBreadcrumbs
                title='Docket Quick Action Requests'
                RightComp={() =>
                    <div className='d-flex gap-1'>
                        {user?.permissions?.dockets?.change && <button className="btn btn-dark btn-style-light" onClick={() => setRequestsFilterModal(true)}><i className="material-icons">filter_alt</i>Filter</button>}
                    </div>
                }
            />
            <div className='col-md-12'>
                {user?.permissions?.dockets?.change ? <DataTableBase
                    columns={columns}
                    data={updateRequestsData?.data}
                    direction="auto"
                    fixedHeaderScrollHeight="300px"
                    pagination
                    responsive
                    subHeaderAlign="right"
                    subHeaderWrap
                    progressPending={updateRequestsIsLoading}
                    defaultPageSize={10}
                    conditionalRowStyles={conditionalRowStyles}
                /> :
                    <Alert
                        title='Error!'
                        text='Permission Denied!'
                        type='danger'
                    />
                }
            </div>

            <DocketUpdateRequestFilterModal
                modal={requestsFilterModal}
                setModal={setRequestsFilterModal}
                searchParams={searchParams}
                setSearchParams={setSearchParams}
            />

            {docketReqModal && <DocketUpdateRequestModal
                modal={docketReqModal}
                setModal={setDocketReqModal}
                data={currentDocketReqData}
                setCurrentDocketReqData={setCurrentDocketReqData}

            />}
        </>
    )
}
