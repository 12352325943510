import React from 'react'
import { useTerminateCircuitHandle } from '../../../hooks/circuit/terminate';
import toast from 'react-hot-toast';

export default function AbortTerminationButton({ data, setFormMode }) {
    const { handleTerminateAction, terminateIsLoading } = useTerminateCircuitHandle('DELETE')


    const handleAbortTermination = async () => {

        const isConfirmed = window.confirm('Are you sure you want to abort this termination?');
        if (!isConfirmed) {
            return;
        }
        const toastId = toast.loading('Processing...');
        const res = await handleTerminateAction({ id: data?.id })
        if (res.success) {
            toast.success(res.detail, { id: toastId });
            setFormMode('')
        } else {
            toast.error(res.detail, { id: toastId })
        }
    }
    return (
        <button type='button' className="btn btn-danger btn-sm my-2" onClick={() => handleAbortTermination()}>Abort Termination</button>
    )
}
