import React from 'react'
import { Formik, Form } from 'formik';
import * as Yup from 'yup'
import LoadingButton from '@mui/lab/LoadingButton';
import { InputField } from '../../Common/Fields/BSField';
import { useUnderMaintenance } from '../../../hooks/circuit/under_maintenance';
import toast from 'react-hot-toast';

export default function AddMaintenanceForm({ data, isLoading, currentData, setFormMode, method = 'POST' }) {

    const { handleUnderMaintenanceAction, underMaintenanceIsLoading } = useUnderMaintenance(method)

    const handleSubmit = async (values, { resetForm }) => {
        const toastId = toast.loading('Processing...');
        let res = null
        if (method === 'POST') {
            res = await handleUnderMaintenanceAction(values)
        } else {
            res = await handleUnderMaintenanceAction({
                ...values,
                id: currentData.id
            })
        }
        if (res.success) {
            toast.success(res.detail, { id: toastId });

            setFormMode('')
            resetForm({ values: '' });
        } else {
            toast.error(res.detail, { id: toastId })
        }
    }

    const validate = Yup.object({
        date: Yup.date().required('Required').nullable(),
        start_time: Yup.string().required('Required').nullable(),
        end_date: Yup.string().required('Required').nullable(),
        end_time: Yup.string().required('Required').nullable(),
        comment: Yup.string().required('Required'),
    })

    return (
        <Formik
            initialValues={{
                date: currentData?.date ?? new Date(),
                start_time: currentData?.start_time ?? new Date().getTime(),
                end_date: currentData?.end_date ?? new Date(),
                end_time: currentData?.end_time ?? new Date().getTime(),
                comment: currentData?.comment ?? '',
                circuit: data.id
            }}
            validationSchema={validate}
            onSubmit={handleSubmit}
        >
            {formik => (
                <Form>

                    <div className='row mt-4'>
                        <div className='col-md-6'>
                            <InputField label='Start Date' type='date' name='date' placeholder='' />
                        </div>
                        <div className='col-md-6'>
                            <InputField label='Start Time' type='time' name='start_time' placeholder='' />
                        </div>
                        <div className='col-md-6'>
                            <InputField label='End Date' type='date' name='end_date' placeholder='' />
                        </div>
                        <div className='col-md-6'>
                            <InputField label='End Time' type='time' name='end_time' placeholder='' />
                        </div>
                        <div className='col-md-12'>
                            <InputField label='Comment *' type='text' name='comment' placeholder='Comment here' />
                        </div>
                    </div>

                    <LoadingButton
                        loading={underMaintenanceIsLoading}
                        variant="contained"
                        disableElevation
                        type="submit"
                        size="small"
                        color="success"
                    >
                        {method === 'PATCH' ? 'Update' : 'Add'}
                    </LoadingButton>
                    {/* <Link to="/" className="auth-forgot-password float-end">das</Link> */}
                </Form>)}
        </Formik>
    )
}
