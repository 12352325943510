import React from 'react'
import { useUnderMaintenance, useUnderMaintenanceCircuit } from '../../../hooks/circuit/under_maintenance';
import AddMaintenanceForm from './AddMaintenanceForm';
import { formatDateTime } from '../../../utils/shortcuts';
import toast from 'react-hot-toast';
import TerminateForm from './TerminateForm';
import { useTerminateCircuit } from '../../../hooks/circuit/terminate';

export default function MaintenanceTerminateManagement({ data }) {
    const [tab, setTab] = React.useState('maintenance');
    const [formMode, setFormMode] = React.useState('');
    const [currentData, setCurrentData] = React.useState(null);

    const { underMaintenanceData, isLoadingUnderMaintenance, filterUnderMaintenance } = useUnderMaintenanceCircuit(`?circuit=${data?.id}`, tab === 'maintenance');
    const { handleUnderMaintenanceAction, underMaintenanceIsLoading } = useUnderMaintenance('DELETE')


    const { terminateData, isLoadingTerminateCircuit, filterTerminateCircuit } = useTerminateCircuit(`?circuit=${data?.id}`, true);


    const handleDelete = async (item) => {
        const isConfirmed = window.confirm('Are you sure you want to delete this item?');
        if (!isConfirmed) {
            return;
        }
        const toastId = toast.loading('Processing...');
        const res = await handleUnderMaintenanceAction({ id: item.id })
        if (res.success) {
            filterUnderMaintenance(`?circuit=${data?.id}`)
            toast.success(res.detail, { id: toastId });
        } else {
            toast.error(res.detail, { id: toastId })
        }
    }

    return (
        <div className='container-fluid'>
            {/* Tab Mangement [Maintenance / Terminate] */}
            <div className="d-flex justify-content-start gap-5">
                <button className={`btn btn-${tab === 'maintenance' ? 'primary' : 'secondary'} btn-style-light btn-sm position-relative`} onClick={() => setTab('maintenance')}>
                    Under Maintenances
                    {underMaintenanceData?.data?.length > 0 && <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                        {underMaintenanceData?.data?.length ?? 0} rcords
                    </span>}
                </button>
                <button className={`btn btn-${tab === 'terminate' ? 'primary' : 'secondary'} btn-style-light btn-sm position-relative`} onClick={() => setTab('terminate')}>

                    {terminateData?.data?.[0]?.is_terminated ? 'Terminated' : 'Terminate'}

                    {terminateData?.data?.length > 0 && <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                        {formatDateTime(terminateData?.data?.[0]?.expiry_date, 'DD-MM-YYYY')}
                    </span>}
                </button>
            </div>
            <hr />
            {/* List Under Maintenances Table / Top Right Add Button / every row last column edit or delete sm button */}
            {tab === 'maintenance' && <div>
                <div className='row '>
                    <table className="table table-hover table-bordered table-striped w-100">
                        <thead>
                            <tr>
                                <th>Start Date</th>
                                <th>Start Time</th>
                                <th>End Date</th>
                                <th>End Time</th>
                                <th>Comment</th>
                                <th>Status</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>

                            {underMaintenanceData?.data?.length > 0 && underMaintenanceData?.data?.map((item, index) => (
                                <tr key={index}>
                                    <td>{formatDateTime(item.date, 'DD-MM-YYYY')}</td>
                                    <td>{item.start_time}</td>
                                    <td>{formatDateTime(item.end_date, 'DD-MM-YYYY')}</td>
                                    <td>{item.end_time}</td>
                                    <td>{item.comment}</td>
                                    <td>{item.is_started && !item.is_ended ? 'In Job' : '-'}</td>
                                    <td>
                                        {!data?.is_terminated && <div>
                                            <button className="btn btn-sm btn-primary" onClick={() => { setFormMode(formMode === '' ? 'update' : ''); setCurrentData(item) }}>Edit</button>
                                            <button disabled={underMaintenanceIsLoading} className="btn btn-sm btn-danger" onClick={() => handleDelete(item)}>Delete</button>
                                        </div>}
                                    </td>
                                </tr>
                            ))}
                            {/* Loading message */}
                            {!underMaintenanceData && isLoadingUnderMaintenance && <tr>
                                <td colSpan={7} className='text-center'>Loading...</td>
                            </tr>}
                            {/* No data message */}
                            {underMaintenanceData?.data?.length === 0 && <tr>
                                <td colSpan={7} className='text-center'>No maintenances are found</td>
                            </tr>}
                        </tbody>
                    </table>
                </div>
                {!data?.is_terminated && <button className="btn btn-primary btn-sm" onClick={() => setFormMode(formMode === '' ? 'add' : '')}>Add New Maintenance</button>}
                {formMode === 'add' && <AddMaintenanceForm
                    data={data}
                    setFormMode={setFormMode}
                    method='POST'
                />}
                {formMode === 'update' && <AddMaintenanceForm
                    data={data}
                    currentData={currentData}
                    method='PATCH'
                    setFormMode={setFormMode}
                />}
            </div>}

            {/* Set Termination Date Time And Save or Cancel Terminal Button only one record can be set */}
            {tab === 'terminate' && <div>
                {!data?.is_terminated ? <TerminateForm data={terminateData?.data?.[0] ?? {}} circuitData={data} /> : <div className='text-center'>Circuit already terminated. Ask the admin to reactivate the circuit for changes.</div>}
            </div>}

        </div>
    )
}
