import { Container } from '@mui/material';
import React from 'react'
import { Helmet } from 'react-helmet';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import PageBreadcrumbs from '../../components/Common/Breadcrumbs';
import CircuitForm from '../../components/Forms/CircuitForm';
import { useCircuit, useCircuitDetail } from '../../hooks/circuit';
import { formatDateTime } from '../../utils/shortcuts';
import { useUser } from '../../hooks/user';

export default function CircuitUpdatePage() {
    const { user } = useUser();
    let navigate = useNavigate();
    const { id } = useParams();
    const { circuitData, circuitIsLoading } = useCircuitDetail(id);
    const { handleCircuitAction, circuitActionIsLoading } = useCircuit('PATCH');


    // console.log(circuitData)
    const handleSubmit = async (values) => {
        // console.log('date submit: ', formatDateTime(values.expiry_date.toString()))
        // console.log('date submit: ', values)
        const res = await handleCircuitAction({
            ...values, id: id,
            // expiry_date: values.expiry_date ? formatDateTime(values.expiry_date.toString()) : null,
            // start_from: values.start_from ? values.start_from : null,
            // end_to: values.end_to ? values.end_to : null,
        });
        // console.log('circuit update res -? ', res);
        if (res.success) {
            toast.success(`#${id} - ${res.detail}`);
            navigate(`/circuit`);
        } else {
            toast.error(res.detail)
        }
    }
    return (
        <>
            <Helmet>
                <title>Circuits - Circuit Update</title>
            </Helmet>
            <Container maxWidth="xxl" disableGutters>
                <PageBreadcrumbs
                    title="Circuit Update"
                    alt='Update'
                    history={[{ name: 'Circuits', to: '/circuit' }, { name: circuitData?.data?.code, to: `/circuit/${id}` },]}
                />
            </Container>

            {circuitData?.data?.is_terminated && !user?.roles?.includes('EXP') && <div className='container'>
                <div className="row">
                    <div className="col mt-2">
                        <div className="alert alert-danger" role="alert">
                            This circuit is already terminated. You can't update any field until you change the status of the current circuit.
                        </div>
                    </div>
                </div>
            </div>}
            
            {(user?.roles?.includes('EXP') || !circuitData?.data?.is_terminated) && <div className='container'>
                <div className="row ">
                    <div className="col mt-2">
                        {/* <div className="">
                        <h1 className='display-6'>CIRCUIT UPDATE</h1>
                    </div> */}
                        {circuitData && !circuitIsLoading && <CircuitForm
                            data={circuitData.data}
                            isLoading={circuitActionIsLoading}
                            handleSubmit={handleSubmit}
                        />}
                    </div>
                </div>

            </div>}
        </>

    )
}
