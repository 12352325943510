import React from 'react'
import Modal from 'react-modal'
// import { toast } from 'react-toastify';
// import Loader from '../Common/Loader';
import { useShiftRoastersList } from '../../hooks/core/shiftroasters';
// import { useUser } from '../../hooks/user';
import ShiftRoasterFilterForm from './FilterForm';
// import { serializeQueryStrings } from '../../utils';
import moment from 'moment';
import { formatDateTime } from '../../utils/shortcuts';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        maxWidth: '700px',
        width: '100%'
    },
    overlay: { zIndex: 2000 }
};
export default function ShiftRoasterModal({ modal, setModal }) {
    // const [modal, setModal] = React.useState(false);
    const [enableApi, setEnableApi] = React.useState(true);
    // const { user } = useUser();
    const { shiftRoastersData, shiftRoastersIsLoading, filterShiftRoastersList } = useShiftRoastersList('?limit=20', enableApi);
    // console.log(shiftRoastersData)

    // const afterOpenModal = async () => {
    //     setEnableApi(true);
    //     // filterCircuitAssets(`/${circuitData?.code}`);
    // };

    // const handleDownload = (item) => {
    //     toast.success('Download started. Please wait...');

    //     const fileUrl = item.get_file_path;
    //     const link = document.createElement('a');
    //     link.href = fileUrl;
    //     link.target = '_blank'; // Opens in a new tab
    //     link.rel = 'noopener noreferrer'; // Security best practice when using target="_blank"
    //     document.body.appendChild(link);
    //     link.click();
    //     link.remove();
    // };

    const handleFilter = (values) => {

        let params = '';
        const limit = parseInt('20');
        if (values.from_date) {
            params = new URLSearchParams({
                from_date: moment(values?.from_date).format('YYYY-MM-DD') ?? null,
                to_date: moment(values?.to_date).format('YYYY-MM-DD') ?? null,
                limit,
                q: values?.q
            }).toString();
        } else {
            params = new URLSearchParams({ q: values?.q, limit }).toString();
        }
        setEnableApi(true)
        params && filterShiftRoastersList(`?${params}`)
    }
    return (
        <div>
            {/* <button className="btn btn-info btn-style-light btn-sm" onClick={() => setModal(true)}>
                <i className="material-icons">cloud_upload</i> Shift Roasters</button> */}

            <Modal
                isOpen={modal}
                // onAfterOpen={afterOpenModal}
                // onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Assets Modal"
            >
                <div className="container-fluid">

                    <div className="d-flex justify-content-between">
                        <h5 className="modal-title text-center text-info"> Shift Roasters</h5>
                        <button type="button" className="btn-close shadow-none" data-bs-dismiss="modal" aria-label="Close" onClick={() => { setModal(false); }}></button>
                    </div>
                    <div className="modal-body" style={{ width: '100%' }}>

                        <div>
                            <ShiftRoasterFilterForm isLoading={shiftRoastersIsLoading} handleSubmit={handleFilter} setModal={setModal} searchParams={{}} />
                            {/* {shiftRoastersIsLoading && <div className="text-center"><Loader /></div>} */}

                            {shiftRoastersData?.data?.length > 0 && <div className="text-center text-sm mb-2">Total Shift Roasters: {shiftRoastersData?.data?.length}</div>}

                            <div className="container-fluid">
                                <div className="row scrollable-row">
                                    {shiftRoastersData?.success && shiftRoastersData?.data?.length > 0 ? (
                                        shiftRoastersData.data.map((item, index) => (
                                            <div key={index} className="col-md-6 mb-4">
                                                <div className="card h-100">
                                                    <div className="card-body d-flex flex-column justify-content-between">
                                                        <div className="mb-2">
                                                            <p className="text-sm m-0 fw-bold">{item.title}</p>
                                                            <p className="text-sm m-0">
                                                                {formatDateTime(item.from_date, 'DD-MM-YYYY')} to {formatDateTime(item.to_date, 'DD-MM-YYYY')}
                                                            </p>
                                                        </div>
                                                        <div className='d-flex gap-1'>
                                                            <a href={`/tools/xlsx?url=${item?.get_file_path}`} without rel="noreferrer" target='_blank' className="btn btn-danger btn-sm mt-auto mr-2">
                                                                <i className="material-symbols-outlined">download</i>
                                                            </a>
                                                            {/* <a href={`${item?.get_file_path}`} target='_blank' className="btn btn-danger btn-sm mt-auto">
                                                                <i className="material-symbols-outlined">
                                                                    download
                                                                </i>
                                                            </a> */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    ) : null}

                                    {/* If no data is found */}
                                    {shiftRoastersData?.data?.length === 0 && (
                                        <div className="text-center text-danger">No data found</div>
                                    )}
                                </div>
                            </div>





                        </div>

                    </div>
                </div>
            </Modal>
        </div>
    )
}
