import React, { useState } from 'react'
import { Formik, Form } from 'formik';
import * as Yup from 'yup'
import LoadingButton from '@mui/lab/LoadingButton';
import { Button, Typography } from '@mui/material';
import { formatSelectOptionsList } from '../../hooks/circuit';
import SelectField from '../Common/Fields/SelectField';
import TextField from '../Common/Fields/TextField';
import { useProblemsList } from '../../hooks/docket';
import { formatUserOptionsList, useUser, useUsersList } from '../../hooks/user';
import { useFilterUsers } from '../../hooks/user/roles';
import { useDebouncedCallback } from 'use-debounce';


export default function MaintDocketRequestForm({ isLoading, handleSubmit, setModal }) {
    const { user } = useUser();
    const { problemsData, problemsIsLoading } = useProblemsList();
    const [ftDatas, setFtDatas] = useState([]) // Fiber Technician *
    const fiber_tl = useUsersList('?user_role=FTL');
    const { filterUsers, isFilterIsLoading } = useFilterUsers()
    const [currentFTL, setCurrentFTL] = useState(null)
    const validate = Yup.object({
        // problem: Yup.string().required('Select problem.'),
        problem: Yup.string().nullable().when("status", {
            is: (val) => val === 'Hold',
            then: Yup.string().required('Select problem.').nullable()
        }),
        comment: Yup.string().required('Comment is required.'),
        location_b: Yup.string().required('Location B is required.'),
        location_a: Yup.string().required('Location A is required.'),
    })
    const fetchData = useDebouncedCallback(async () => {
        if (user?.roles?.includes('FTL')) {
            setCurrentFTL(user.id)
            const data = await filterUsers(`?user_role=FT&parent_role=${user.id}`);
            if (data.success) {
                setFtDatas(data.data);
            } else {
                setFtDatas([]);
            }
        }
    }, 600)

    React.useEffect(() => {
        fetchData();
    }, [user]);
    return (
        <Formik
            initialValues={{
                location_a: '',
                location_b: '',
                comment: '',
                maint_problem: null,
                fiber_tl: currentFTL ?? null,
                technician: null,
                is_maint_docket: true,
            }}
            validationSchema={validate}
            onSubmit={handleSubmit}
        >
            {formik => (

                <Form>
                    <div className='row'>
                        <div className="col-md-12 mb-2">
                            <Typography variant='caption'>Select Problem <span className='req'>*</span></Typography>
                            <SelectField
                                name='maint_problem'
                                options={problemsData?.data?.map(formatSelectOptionsList)}
                                value={problemsData?.data?.map(formatSelectOptionsList).filter(function (option) {
                                    return option.value === formik.values.maint_problem;
                                })}
                                onChange={(e) => formik.setFieldValue('maint_problem', e.value)}
                                // isDisabled={disabled}
                                isLoading={problemsIsLoading}
                            />

                        </div>
                        {!user?.roles?.includes('FTL') && <div className="col-md-6 mb-2">
                            <Typography variant='caption'>Fiber TL</Typography>
                            <SelectField
                                name='fiber_tl'
                                options={fiber_tl?.usersData?.data?.map(formatUserOptionsList)}
                                value={fiber_tl?.usersData?.data?.map(formatUserOptionsList).filter(function (option) {
                                    return option.value === formik.values.fiber_tl;
                                })}
                                isDisabled={user?.roles?.includes('FTL')}
                                onChange={async (e) => {
                                    formik.setFieldValue('fiber_tl', e.value)
                                    const data = await filterUsers(`?user_role=FT&parent_role=${e.value}`)
                                    if (data.success) {
                                        setFtDatas(data.data)
                                    } else {
                                        setFtDatas([])
                                    }
                                }}
                                isLoading={fiber_tl?.usersIsLoading}
                            />
                        </div>}
                        <div className="col-md-6 col-12 mb-2">
                            <Typography variant='caption'>Technician {formik.values.technician && <small className='text-primary' role='button' onClick={() => formik.setFieldValue('technician', null)}>Clear</small>}</Typography>
                            <SelectField
                                name='technician'
                                options={ftDatas.map(formatUserOptionsList)}
                                value={ftDatas.map(formatUserOptionsList).filter(function (option) {
                                    return option.value === formik.values.technician;
                                })}
                                onChange={(e) => formik.setFieldValue('technician', e.value)}
                                isLoading={isFilterIsLoading}
                                isDisabled={ftDatas?.length === 0}
                                isClearable={false}
                            />
                        </div>

                    </div>
                    <div className='row'>
                        <div className="col-md-6">

                            <TextField label='Location A *' type="text" name='location_a' placeholder='Location A' sx={{ mt: 2 }} />
                        </div>
                        <div className="col-md-6">

                            <TextField label='Location B *' type="text" name='location_b' placeholder='Location B' sx={{ mt: 2 }} />
                        </div>
                        <div className='col-md-12'>
                            <TextField label='Comment *' type="text" name='comment' placeholder='Comment' sx={{ mt: 2 }} />
                        </div>
                    </div>


                    <LoadingButton
                        loading={isLoading}
                        type="submit"
                        variant="contained"
                        disableElevation
                    >
                        Raise Docket Request
                    </LoadingButton>
                    <Button variant="outlined" sx={{ ml: 1 }} disableElevation onClick={() => setModal(false)}>Close</Button>
                </Form>
            )}
        </Formik>
    )
}
