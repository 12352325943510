import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
// import moment from "moment/moment";
import { useState } from "react";
import { API_HOST_URL } from "../../config";
import { endpoints } from "../../config/endpoints";
import { fetchJson } from "../../libs/api";
import { getTokens } from "../../utils/shortcuts";


const CIR_TERMINATE_QUERY_KEY = 'terminate_circuit'


export function useTerminateCircuit(defaultParam = "", enabled = true) {
    const [param, setParam] = useState(null);
    const { isLoading, data } = useQuery(
        [CIR_TERMINATE_QUERY_KEY, { param: param, defaultParam }],
        async (key) => {
            const param = key.queryKey[1].param;
            const defaultParam = key.queryKey[1].defaultParam;
            try {
                const { accessToken } = getTokens();
                const param_url = param ? param : defaultParam;
                const res = await fetchJson(
                    `${API_HOST_URL}/${endpoints.circuit.terminate}${param_url}`,
                    {
                        headers: { Authorization: `Bearer ${accessToken}` },
                    },
                    true,
                );
                return await res.json();
            } catch (err) {
                return { success: false, detail: err };
            }
        },
        {
            cacheTime: Infinity,
            staleTime: 30_000,
            enabled: enabled,
        },
    );
    return {
        terminateData: data,
        isLoadingTerminateCircuit: isLoading,
        filterTerminateCircuit: async (value) => setParam(value),
    };
}


export function useTerminateCircuitHandle(method) {
    const { accessToken } = getTokens();
    const queryClient = useQueryClient();
    const mutation = useMutation((data) => fetchJson(`${API_HOST_URL}/${endpoints.circuit.terminate}${['PATCH', 'DELETE'].includes(method) ? '/' + data.id : ''}`, {
        method: method,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`
        },
        body: JSON.stringify({ ...data })
    }, true), {
        retry: 2,
    })
    return {
        handleTerminateAction: async (data) => {
            try {
                const res = await mutation.mutateAsync(data);
                const resData = await res.json()
                if (resData.success) {
                    await queryClient.invalidateQueries([CIR_TERMINATE_QUERY_KEY])
                }
                return resData

            } catch (err) {
                return {
                    success: false,
                    detail: err
                }
            }
        },

        terminateIsLoading: mutation.isLoading,
    }
}