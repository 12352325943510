import { Container, Grid } from '@mui/material';
import React, { useState } from 'react'
// import { Helmet } from 'react-helmet';
import { Link, useParams } from 'react-router-dom';
import PageBreadcrumbs from '../../../components/Common/Breadcrumbs';
import { useUser } from '../../../hooks/user';
import { useCorpateDocketList } from '../../../hooks/docket/corporate';
import Loader from '../../../components/Common/Loader';
import CorpDocketInformation from '../../../components/Corporate/CorpDocketInformation';
import Alert from '../../../components/Common/Alert';


export default function CorporateDocketDetail() {
    const { user } = useUser();
    const { id } = useParams();
    const { corporateDockets, isCorporateDocketsLoading } = useCorpateDocketList(`/${id}`);
    const [historyTab, setHistoryTab] = useState(false);

    if (!user?.permissions?.dockets?.can_view_corp_docket_ticket) {
        return <Alert type='danger' title='Permission Error!' text='You do not have permission to view this page.' />
    }

    const renderDocketInformation = (data) => {
        // console.log(data)
        if (data.success) {
            return <CorpDocketInformation data={data.data} historyTab={historyTab} setHistoryTab={setHistoryTab} />

        } else {
            return <div className='alert alert-warning' role='alert'>
                Something wents wrong! <br /> <Link to='/docket/corporate' className='btn btn-sm btn-dark mt-2'>Back to Dockets</Link>
            </div>
        }
    }

    return (
        <>
            {/* <Helmet>
                <title>Dockets - {`${docketData?.data?.code ?? 'Loading...'} ${docketData?.data?.is_maintenance ? 'Maintenance Docket' : ''} Information`}</title>
            </Helmet> */}
            <Container maxWidth="xxl" disableGutters>
                <PageBreadcrumbs
                    title={corporateDockets?.data?.code ?? 'Loading...'}
                    history={[{ name: 'Corporate Docket', to: '/docket/corporate' }]}
                    RightComp={() => {
                        return <>

                            {user?.permissions?.dockets?.can_view_corp_docket_ticket && <button className="btn btn-dark btn-style-light" title='History' onClick={() => setHistoryTab(!historyTab)}>
                                <i className="material-icons">settings_backup_restore</i>{historyTab ? '' : ''}</button>}
                        </>
                    }}
                />

                <Grid container>
                    <Grid item xs={12} sx={{ p: 1 }}>
                        {isCorporateDocketsLoading && <Loader />}
                        {corporateDockets && !isCorporateDocketsLoading && renderDocketInformation(corporateDockets)}
                    </Grid>
                </Grid>
              
            </Container>
        </>
    )
}
