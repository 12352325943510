import React, { useEffect, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { toast } from 'react-toastify';
import Alert from '../../../../components/Common/Alert';
import {  useDocketUpdatesList } from '../../../../hooks/docket';
import { useUser } from '../../../../hooks/user';
import PageBreadcrumbs from '../../../../components/Common/Breadcrumbs';
import DataTableBase from '../../../../components/Common/DataTableBase';
import { formatDateTime } from '../../../../utils/shortcuts';
import { useCorpDocketNotifications, useCorporateDocketNotificationList } from '../../../../hooks/docket/corporate';
import { useDebouncedCallback } from 'use-debounce';

export default function CorporateDocketNotificationsList() {
    const { user } = useUser();
    const [searchParams] = useSearchParams();
    const [enableApi, setAPI] = useState(false)

    // Handling Docket Updates
    const { corpDocketNotifications, corpDocketNotificationsIsLoading, retriveCorpNotificationData, filterUpdatesList } = useCorporateDocketNotificationList('', enableApi)

    const { handleMarkAllAsRead, markingIsLoading } = useCorpDocketNotifications()

    const columns = [
        {
            name: '# ID',
            selector: row => row.id,
            sortable: true,
            width: '50px !important',
        },
        {
            name: 'Docket',
            sortable: true,
            width: "150px",
            cell: (row) => (<>
                <Link to={`/docket/corporate/${row?.get_docket_code}`}>{row?.get_docket_code}</Link>
            </>)
        },
        {
            name: 'Update',
            selector: row => row.comment,
        },
        {
            name: 'Is Public',
            selector: row =>  row.is_public ? 'Yes' : 'No',
        },
        {
            name: 'By',
            width: "200px",
            sortable: true,
            selector: row => row?.get_created_by?.name,
        },
        {
            name: 'File',
            width: "200px",
            sortable: true,
            cell: (row) => row?.get_file_path ? <Link to={`${row?.get_file_path}`} target="_blank">Preview</Link>: 'N/A'
        },
        {
            name: 'Date Time',
            width: "150px",
            selector: row => formatDateTime(row.created_at, 'D-M-YY h:ma'),
        },
    ];


    const handleMarkAllAsReadBtn = async () => {
        const res = await handleMarkAllAsRead()
        toast.info(res?.detail);
    }

     // Fetch Data
     const fetchData = useDebouncedCallback(() => {
        const currentParams = Object.fromEntries([...searchParams]);
        let params = ''
        const limit = parseInt(currentParams?.limit ?? '10');
        const type = 'all';

        params = new URLSearchParams({ ...currentParams, limit, type }).toString();
        
        setAPI(true)
        params && filterUpdatesList(`?${params}`)
    }, 300)

    useEffect(() => {
        fetchData()

    }, [searchParams])
    return (
        <>
            <Helmet>
                <title>Corp. Docket Notifications - List</title>
            </Helmet>
            <PageBreadcrumbs
                title='Corp. Docket Notifications'
                history={[{ name: 'Corp. Docket', to: '/docket/corporate' }]}
                RightComp={() =>
                    <div className='d-flex gap-1'>
                        {user?.roles?.includes('EXP') && <button className="btn btn-dark btn-style-light" onClick={() => handleMarkAllAsReadBtn()} disabled={markingIsLoading}><i className="material-icons">visibility</i> Mark all as Seen</button>}
                    </div>
                }
            />
            <div className='col-md-12'>
                {user?.permissions?.dockets?.can_view_corporate_notification ? <DataTableBase
                    columns={columns}
                    data={corpDocketNotifications?.data}
                    direction="auto"
                    fixedHeaderScrollHeight="300px"
                    pagination
                    responsive
                    subHeaderAlign="right"
                    subHeaderWrap
                    progressPending={corpDocketNotificationsIsLoading}
                    defaultPageSize={10}
                /> :
                    <Alert
                        title='Error!'
                        text='Permission Denied!'
                        type='danger'
                    />
                }
            </div>

            {/* <DocketRequestsFilterModal
                modal={requestsFilterModal}
                setModal={setRequestsFilterModal}
                searchParams={searchParams}
                setSearchParams={setSearchParams}
            /> */}

            {/* {currentDocketReqData && <DocketRequestModal
                modal={docketReqModal}
                setModal={setDocketReqModal}
                data={currentDocketReqData}
                setCurrentDocketReqData={setCurrentDocketReqData}

            />} */}
        </>
    )
}
