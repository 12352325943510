import { Card, CardContent, Grid, Paper } from "@mui/material";
import moment from "moment";
import React, { useState } from "react";
import { Link } from "react-router-dom";
// import { capitalize } from '../../utils'
import DocketHoldTable from "./DocketHoldTable";
import DocketHistory from "./History";
import { formatDateTime, latLongToGmap, showUser } from "../../utils/shortcuts";
import DocketVehicleHoldTable from "./DocketVehicleHoldTable";
import { isEmptyArr, secondsToHMS } from "../../utils";
import DocketUploadsModal from "./DocketUploadsModal";
import DocketProgress from "./DocketProgress";
import { useUser } from "../../hooks/user";

export default function DocketInformation({ data, historyTab, setHistoryTab }) {
    const { user } = useUser();
    // console.log("Docket Info: ", data);
    const [uploadFilesModal, setUploadFilesModal] = useState(false);
    const [holdUploads, setHoldUploads] = useState(null);

    const location_a_gmap = latLongToGmap(
        data.get_circuit.latitude_a,
        data.get_circuit.longitude_a,
    );
    const location_b_gmap = latLongToGmap(
        data.get_circuit.latitude_b,
        data.get_circuit.longitude_b,
    );
    return (
        <>
            <div className="row justify-content-between">
                <div className="col-md-6">
                    {data.is_reopened && (
                        <span className="badge bg-info text-white"> Re-opened</span>
                    )}
                    <h3 className="display-6">
                        {data.get_circuit.is_maintenance && "Maintenance"} Docket Info{" "}
                    </h3>
                    <p className="lead">
                        <em>Last updated at {moment(data.updated_at).fromNow()}...</em>
                    </p>
                </div>
                <div className="col-md-6 text-md-end">
                    <h3 className="display-6">Docket ID: {data.code}</h3>
                    <p className="lead">
                        Status: <span className="badge badge-info">{data.status}</span>
                    </p>
                </div>
            </div>
            <hr />
            <DocketProgress data={data} />
            <Grid container className="trinClass">
                {/* <h3 className='display-6'>Docket Informations</h3> */}

                {/* CIRCUIT DETAILS */}
                <Grid item lg={4} xs={12} sx={{ p: 0.5 }}>
                    <Card>
                        <Paper
                            className="fullheightIner"
                            style={{ maxHeight: 360, overflow: "auto" }}
                        >
                            <CardContent>
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <td colSpan={2}>
                                                <em>
                                                    {data.get_circuit.is_maintenance && "MAINTENANCE"}{" "}
                                                    CIRCUIT DETAILS
                                                </em>{" "}
                                                &nbsp;
                                                <span
                                                    className="badge text-white"
                                                    style={{
                                                        backgroundColor: data.get_provider.background,
                                                    }}
                                                >
                                                    {data.get_provider.title}
                                                </span>
                                            </td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {/* {!data.get_circuit.is_maintenance && */}
                                        <tr>
                                            <th scope="col">Circuit ID:</th>
                                            <td className="text-dark">
                                                <Link to={`/circuit/${data.get_circuit.id}`}>
                                                    {data.get_circuit.code}
                                                </Link>
                                                <br />
                                                <small>
                                                    {data.get_circuit.circuit_type} -{" "}
                                                    {data.get_circuit.link_type} <br />
                                                    {!data.get_circuit.is_maintenance && (
                                                        <>
                                                            Hirer Name: {data?.get_circuit?.get_hirer?.name}
                                                        </>
                                                    )}
                                                </small>
                                            </td>
                                        </tr>
                                        {/* } */}
                                        {!data.get_circuit.is_maintenance && (
                                            <>
                                                <tr>
                                                    <th scope="col">Customer ID:</th>
                                                    <td>{data.get_circuit.customer_id}</td>
                                                </tr>
                                            </>
                                        )}
                                        <tr>
                                            <th scope="col">Loc. A:</th>
                                            <td>
                                                {/* {data.get_circuit.location_a} */}
                                                {location_a_gmap ? (
                                                    <a
                                                        href={location_a_gmap}
                                                        target="_blank"
                                                        rel="noreferrer"
                                                        title="Open in Google Map"
                                                    >
                                                        {data.get_circuit.location_a}
                                                    </a>
                                                ) : (
                                                    data.get_circuit.location_a
                                                )}
                                                <br />
                                                <small>
                                                    {data.get_circuit.pin_a &&
                                                        `(${data.get_circuit.pin_a})`}
                                                </small>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th scope="col">Addr. A:</th>
                                            <td>{data?.get_circuit?.address_a}</td>
                                        </tr>
                                        <tr>
                                            <th scope="col">Loc. B:</th>
                                            <td>
                                                {/* {data.get_circuit.location_b} */}
                                                {location_b_gmap ? (
                                                    <a
                                                        href={location_b_gmap}
                                                        target="_blank"
                                                        rel="noreferrer"
                                                        title="Open in Google Map"
                                                    >
                                                        {data.get_circuit.location_b}
                                                    </a>
                                                ) : (
                                                    data.get_circuit.location_b
                                                )}
                                                <br />
                                                <small>
                                                    {data.get_circuit.pin_b &&
                                                        `(${data.get_circuit.pin_b})`}
                                                </small>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th scope="col">Addr. B:</th>
                                            <td>{data?.get_circuit?.address_b}</td>
                                        </tr>
                                        <tr>
                                            <th scope="col">OFC Dist:</th>
                                            <td>{data?.get_circuit?.ofc_distance || "-"}</td>
                                        </tr>
                                        <tr>
                                            <th scope="col">Connectivity:</th>
                                            <td>{data.get_circuit.type_of_connectivity}</td>
                                        </tr>
                                        <tr>
                                            <th scope="col">Fiber Details:</th>
                                            <td>
                                                <small>
                                                    OFC Color: {data.get_circuit.ofc_color || "-"} <br />
                                                    {`Ofc Distance: ${data.get_circuit.ofc_distance || "-"}`}{" "}
                                                    <br />
                                                    {/* {data.get_circuit.latitude && data.get_circuit.longitude && `Lat: ${data.get_circuit.latitude} - Long: ${data.get_circuit.longitude}`} */}
                                                </small>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th scope="col">Status:</th>
                                            <td>{data.get_circuit.status} </td>
                                        </tr>
                                        <tr>
                                            <th scope="col">
                                                Created at:
                                                <br />
                                                <em>
                                                    <small>last updated at:</small>
                                                </em>
                                            </th>
                                            <td>
                                                {formatDateTime(
                                                    data.created_at,
                                                    "MMMM Do YYYY, h:mm:ss a",
                                                )}
                                                <br />
                                                <em>
                                                    <small>
                                                        {formatDateTime(
                                                            data.updated_at,
                                                            "MMMM Do YYYY, h:mm:ss a",
                                                        )}
                                                    </small>
                                                </em>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </CardContent>
                        </Paper>
                    </Card>
                </Grid>

                {/* DOCKET DETAILS */}
                <Grid item lg={4} xs={12} sx={{ p: 0.5 }}>
                    <Card>
                        <Paper
                            className="fullheightIner"
                            style={{ maxHeight: 360, overflow: "auto" }}
                        >
                            <CardContent>
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <td colSpan={2}>
                                                <em>
                                                    {data.get_circuit.is_maintenance && "MAINTENANCE"}{" "}
                                                    DOCKET DETAILS
                                                </em>{" "}
                                                &nbsp;
                                                <span
                                                    className="badge text-white"
                                                    style={{
                                                        backgroundColor: data.get_provider.background,
                                                    }}
                                                >
                                                    {data.get_provider.title}
                                                </span>
                                            </td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <th scope="col">Problem:</th>
                                            <td>{data?.get_problem?.title}</td>
                                        </tr>
                                        {data.third_party_docket_no && (
                                            <tr>
                                                <th scope="col">Third Party Docket No:</th>
                                                <td>{data.third_party_docket_no}</td>
                                            </tr>
                                        )}

                                        <tr>
                                            <th scope="col">Fiber TL:</th>
                                            <td
                                                onClick={() => {
                                                    !isEmptyArr(data.docket_files) &&
                                                        setHoldUploads(data.docket_files);
                                                    setUploadFilesModal(true);
                                                }}
                                            >
                                                {/* {data.get_fiber_tl.name} @ {data.get_fiber_tl.mobile}{" "} */}
                                                {showUser(data?.get_fiber_tl)} <span className="badge bg-primary text-white">
                                                    Current
                                                </span>
                                                <br />
                                                {typeof data.forward_log !== "undefined" &&
                                                    data.forward_log !== 0 &&
                                                    data.forward_log.map((tl, i) => {
                                                        return (
                                                            <small key={i} title={tl?.remarks}>
                                                                {i + 1}. {tl.name} (
                                                                {secondsToHMS(tl.total_lead, "num")}) <br />-{" "}
                                                                <span className="text-dark">
                                                                    {tl?.remarks ?? ""}
                                                                </span>
                                                                <br />
                                                            </small>
                                                        );
                                                    })}
                                            </td>
                                        </tr>
                                        {data.get_technician && (
                                            <tr>
                                                <th scope="col">Technician:</th>
                                                <td>
                                                    {/* {data.get_technician.name} @{" "}
                          {data.get_technician.mobile} */}
                                                    {showUser(data?.get_technician)}
                                                </td>
                                            </tr>
                                        )}
                                        {data.get_hirer && (
                                            <tr>
                                                <th scope="col">Hirer Name:</th>
                                                <td>{data.get_hirer.name}</td>
                                            </tr>
                                        )}
                                        {data.comment && (
                                            <tr>
                                                <th scope="col">Comment:</th>
                                                <td>{data.comment || "-"}</td>
                                            </tr>
                                        )}
                                        <tr>
                                            <th scope="col">Docketed By:</th>
                                            <td>
                                                {data.docketed_by ?? "-"} @{" "}
                                                {data.docketed_by_contact === "9999999999"
                                                    ? "N/A"
                                                    : data.docketed_by_contact}
                                            </td>
                                        </tr>
                                        <tr>
                                            <th scope="col">Status:</th>
                                            <td>
                                                {data.status === "Cancelled" ? (
                                                    <span
                                                        className={`${!isEmptyArr(data.docket_cancel_files) && "text-danger fw-bold pointer"}`}
                                                        onClick={() => {
                                                            !isEmptyArr(data.docket_cancel_files) &&
                                                                setHoldUploads(data.docket_cancel_files);
                                                            setUploadFilesModal(true);
                                                        }}
                                                    >
                                                        {data.status}
                                                    </span>
                                                ) : (
                                                    data.status
                                                )}
                                            </td>
                                        </tr>
                                        <tr>
                                            <th scope="col">
                                                Created at:
                                                <br />
                                                <em>
                                                    <small>last updated at:</small>
                                                </em>
                                            </th>
                                            <td>
                                                {formatDateTime(
                                                    data.created_at,
                                                    "MMMM Do YYYY, h:mm:ss a",
                                                )}
                                                <br />
                                                <em>
                                                    <small>
                                                        {formatDateTime(
                                                            data.updated_at,
                                                            "MMMM Do YYYY, h:mm:ss a",
                                                        )}
                                                    </small>
                                                </em>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </CardContent>
                        </Paper>
                    </Card>
                </Grid>
                <DocketUploadsModal
                    modal={uploadFilesModal}
                    setModal={setUploadFilesModal}
                    holdUploads={holdUploads}
                    setHoldUploads={setHoldUploads}
                />


                {/* DOCKET CLOSED INFO */}
                {data.get_closed_info && data?.status !== 'Cancelled' && !user?.roles?.includes('LC') && (
                    <Grid item lg={4} xs={12} sx={{ p: 0.5 }}>
                        <Card>
                            <Paper
                                className="fullheightIner"
                                style={{ maxHeight: 360, overflow: "auto" }}
                            >
                                <CardContent>
                                    <table className="table" style={{ verticalAlign: 'middle' }}>
                                        <thead>
                                            <tr>
                                                <td colSpan={2}>
                                                    <em>DOCKET CLOSED DETAILS</em> &nbsp;
                                                    {data.has_recent_docket.status && (
                                                        <span className="badge badge-info badge-style-light">
                                                            Cooling down,{" "}
                                                            <small>
                                                                {formatDateTime(
                                                                    data.has_recent_docket.opened_until,
                                                                    "MMMM Do YYYY, h:mm a",
                                                                )}
                                                            </small>
                                                        </span>
                                                    )}
                                                </td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data?.get_closed_info?.get_vehicles?.vehicle1_name && (
                                                <tr>
                                                    <th scope="col">Vehicle 1:</th>
                                                    <td>
                                                        <p><span title='Start KM'>{data?.get_closed_info?.vehicle1_start_km}</span> - <span title='End KM'>{data?.get_closed_info?.vehicle1_end_km}</span> {data?.get_closed_info?.vehicle1_to_pop && <span title='POP KM'> - {data?.get_closed_info?.vehicle1_pop_km}</span>}</p>
                                                        {`${data?.get_closed_info?.get_vehicles?.vehicle1_name} (${data?.get_closed_info?.get_vehicles?.vehicle1_total_km} Km)` ??
                                                            "-"}{" "}
                                                        {data?.get_closed_info?.vehicle1_to_pop && (
                                                            <span
                                                                className="badge badge-info badge-style-light"
                                                                title="Return to POP"
                                                            >
                                                                {`${data?.get_closed_info?.get_vehicles?.vehicle1_pop_km} KM`}{" "}
                                                            </span>
                                                        )}
                                                    </td>
                                                </tr>
                                            )}
                                            {data?.get_closed_info?.get_vehicles?.vehicle2_name && (
                                                <tr>
                                                    <th scope="col">Vehicle 2:</th>
                                                    <td>
                                                        <p><span title='Start KM'>{data?.get_closed_info?.vehicle2_start_km}</span> - <span title='End KM'>{data?.get_closed_info?.vehicle2_end_km}</span> {data?.get_closed_info?.vehicle2_to_pop && <span title='POP KM'> - {data?.get_closed_info?.vehicle2_pop_km}</span>}</p>
                                                        {`${data?.get_closed_info?.get_vehicles?.vehicle2_name} (${data?.get_closed_info?.get_vehicles?.vehicle2_total_km} Km)` ??
                                                            "-"}{" "}
                                                        {data?.get_closed_info?.vehicle2_to_pop && (
                                                            <span
                                                                className="badge badge-info badge-style-light"
                                                                title="Return to POP"
                                                            >
                                                                {`${data?.get_closed_info?.get_vehicles?.vehicle2_pop_km} KM`}{" "}
                                                            </span>
                                                        )}
                                                    </td>
                                                </tr>
                                            )}
                                            {data?.get_closed_info?.get_vehicles?.vehicle3_name && (
                                                <tr>
                                                    <th scope="col">Vehicle 3:</th>
                                                    <td>
                                                        <p><span title='Start KM'>{data?.get_closed_info?.vehicle3_start_km}</span> - <span title='End KM'>{data?.get_closed_info?.vehicle3_end_km}</span> {data?.get_closed_info?.vehicle3_to_pop && <span title='POP KM'> - {data?.get_closed_info?.vehicle3_pop_km}</span>}</p>
                                                        {`${data?.get_closed_info?.get_vehicles?.vehicle3_name} (${data?.get_closed_info?.get_vehicles?.vehicle3_total_km} Km)` ??
                                                            "-"}{" "}
                                                        {data?.get_closed_info?.vehicle3_to_pop && (
                                                            <span
                                                                className="badge badge-info badge-style-light"
                                                                title="Return to POP"
                                                            >
                                                                {`${data?.get_closed_info?.get_vehicles?.vehicle3_pop_km} KM`}{" "}
                                                            </span>
                                                        )}
                                                    </td>
                                                </tr>
                                            )}
                                            <tr>
                                                <th scope="col">Confirmed:</th>
                                                <td>{data.get_closed_info.confirmed_by || "-"} </td>
                                            </tr>
                                            <tr>
                                                <th scope="col">Comment:</th>
                                                <td>{data.get_closed_info.comments || "-"}</td>
                                            </tr>
                                            <tr>
                                                <th scope="col">
                                                    Materials: <br />
                                                    {/* {console.log(user?.permissions?.account?.is_admin)} */}
                                                    {/* {user?.user_role === "EXP" && user?.permissions?.dockets?.can_update_docket_materials ? ( */}
                                                    {user?.permissions?.dockets?.can_update_docket_materials ? (
                                                        <Link
                                                            to={`/docket/update/material/${data.code}`}
                                                            className="btn btn-sm btn-warning"
                                                        >
                                                            Modify
                                                        </Link>
                                                    ) : (
                                                        ""
                                                    )}
                                                </th>
                                                <td>
                                                    {data.get_closed_info.materials.length !== 0 ? (
                                                        <table className="table">
                                                            <thead className="table-dark table-sm">
                                                                <tr>
                                                                    <th>
                                                                        <small>Material</small>
                                                                    </th>
                                                                    <th>
                                                                        <small>Value</small>
                                                                    </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {data.get_closed_info.materials &&
                                                                    data.get_closed_info.materials.map(
                                                                        (val, i) => (
                                                                            <tr key={i}>
                                                                                <td>
                                                                                    {val.field}{" "}
                                                                                    {val.sub_field !== val.field
                                                                                        ? `(${val.sub_field})`
                                                                                        : ""}
                                                                                </td>
                                                                                <td>
                                                                                    {val.value}{" "}
                                                                                    <small>
                                                                                        {val.unit && `(${val.unit})`}
                                                                                    </small>
                                                                                </td>
                                                                            </tr>
                                                                        ),
                                                                    )}
                                                            </tbody>
                                                        </table>
                                                    ) : (
                                                        "- Not Updated -"
                                                    )}
                                                    <hr />
                                                    {data?.get_closed_info?.materials_additionals
                                                        ?.length !== 0 ? (
                                                        <table className="table">
                                                            <thead className="table-dark table-sm">
                                                                <tr>
                                                                    <th style={{ width: "5%" }}>
                                                                        <small>ID</small>
                                                                    </th>
                                                                    <th style={{ width: "20%" }}>
                                                                        <small>Fiber Type</small>
                                                                    </th>
                                                                    <th style={{ width: "20%" }}>
                                                                        <small>Comp. Name</small>
                                                                    </th>
                                                                    <th style={{ width: "10%" }}>
                                                                        <small>Drum No</small>
                                                                    </th>
                                                                    <th style={{ width: "10%" }}>
                                                                        <small>Start MT.</small>
                                                                    </th>
                                                                    <th style={{ width: "10%" }}>
                                                                        <small>End MT.</small>
                                                                    </th>
                                                                    <th style={{ width: "10%" }}>
                                                                        <small>Total MT.</small>
                                                                    </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {data.get_closed_info.materials_additionals &&
                                                                    data.get_closed_info.materials_additionals.map(
                                                                        (val, i) => (
                                                                            <tr key={i}>
                                                                                <td>{val.id} </td>
                                                                                <td>{val.fiber_type} </td>
                                                                                <td>{val.company_name} </td>
                                                                                <td>{val.drum_no} </td>
                                                                                <td>{val.start_mt} </td>
                                                                                <td>{val.end_mt} </td>
                                                                                <td>{val.end_mt - val.start_mt} </td>
                                                                            </tr>
                                                                        ),
                                                                    )}
                                                            </tbody>
                                                        </table>
                                                    ) : (
                                                        "- Additional Not Updated -"
                                                    )}
                                                </td>
                                            </tr>
                                            <tr>
                                                <th scope="col">Closed by:</th>
                                                <td>
                                                    {showUser(data?.get_closed_by)}
                                                </td>
                                            </tr>
                                            <tr>
                                                <th scope="col">
                                                    Closed at:
                                                </th>
                                                <td>
                                                    {formatDateTime(
                                                        data.closed_at,
                                                        "MMMM Do YYYY, h:mm:ss a",
                                                    )}

                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </CardContent>
                            </Paper>
                        </Card>
                    </Grid>
                )}
                {/* {console.log(typeof data.docket_hold_data !== 'undefined' && data.docket_hold_data.length !== 0)} */}

                <div className="row">
                    {/* DOCKET TAT */}
                    {(data.status === "Resolved" || data.status === "Closed") &&
                        !user?.roles?.includes('LC')  ? (
                        <>
                            <h3 className="display-6 mt-4">Tat Informations</h3>
                            <Grid item lg={6} md={12} sx={{ p: 0.5 }}>
                                <Card>
                                    <Paper style={{ maxHeight: 500, overflow: "auto" }}>
                                        <CardContent>
                                            <DocketHoldTable data={data} showTotalCopm={true} />
                                        </CardContent>
                                    </Paper>
                                </Card>
                            </Grid>
                        </>
                    ) : (
                        ""
                    )}
                    {/* DOCKET KM */}
                    {data.docket_hold_data.length > 0 && (
                        <Grid item xs={12} sx={{ p: 0.5 }}>
                            <Card>
                                <Paper style={{ maxHeight: 500, overflow: "auto" }}>
                                    <CardContent>
                                        <DocketVehicleHoldTable data={data} showTotalCopm={true} />
                                    </CardContent>
                                </Paper>
                            </Card>
                        </Grid>
                    )}
                </div>

                {/* HISTORY */}
                <DocketHistory
                    data={data}
                    historyTab={historyTab}
                    setHistoryTab={setHistoryTab}
                />
            </Grid>
        </>
    );
}
