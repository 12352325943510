/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import PageBreadcrumbs from "../../components/Common/Breadcrumbs";
import { useClosedDocketList, useDocket } from "../../hooks/docket";
import { Grid } from "@mui/material";
import Loader from "../../components/Common/Loader";
import CloseDocketInfo from "../../components/Docket/CloseDocketInfo";
import CloseDocketAction from "../../components/Docket/CloseDocketAction";
import Alert from "../../components/Common/Alert";
import { formatDateTime } from "../../utils/shortcuts";
import { toast } from "react-toastify";
import DocketInfoModal from "../../components/Models/DocketInfoModal";
import { useUser } from "../../hooks/user";
import { Helmet } from "react-helmet";
import HandleHoldVehicleBackToPop from "./Components/HandleHoldVehicleBackToPop";

export default function CloseDocketPage() {
    const { user } = useUser();
    const { id } = useParams();
    let navigate = useNavigate();
    const [apiEnable, setApiEnable] = useState(false);
    const docketFindForm = useRef(null);
    const { handleDocketAction, docketActionIsLoading } = useDocket("PATCH");
    const { closedDocketsData, closedDocketsIsLoading, handleParam } =
        useClosedDocketList(null, apiEnable);
    const [modal, setInfoModal] = useState(false);
    // console.log('object -> ', closedDocketsData);
    useEffect(() => {
        setApiEnable(true);
        handleParam(`/${id}`);
    }, [id]);

    const handleFindDocket = async () => {
        setApiEnable(true);
        const form = docketFindForm.current;
        handleParam(`/${form["docketId"].value}`);
        navigate(`/docket/close/${form["docketId"].value}`);
        // console.log('submit', form['docketId'].value)
    };
    const handleForceCloseDocket = async (docket_id) => {
        const res = await handleDocketAction({
            id: docket_id,
            action: `Resume`,
            remarks: `Force Closed`,
            status: "Resume",
        });
        if (res.success) {
            toast.success(`${docket_id} - Docket Force Closed!`);
            window.location.reload();
        } else {
            toast.error(res.detail);
        }
    };

    const renderDocketClose = (data) => {
        // console.log(data?.docket?.get_circuit?.circuit_type)
        if (!user?.permissions?.dockets?.can_close_docket) {
            return (
                <Alert
                    title="Permission Error!"
                    text={`You do not have the permission to close the docket.`}
                    type="warning"
                />
            );
        }
        if (
            // user?.user_role !== "EXP"
            !user?.roles?.includes('EXP') &&
            (data?.docket?.get_circuit?.circuit_type === "Leased Line" ||
                data?.docket?.get_circuit?.circuit_type === "WAN")
        ) {
            return (
                <Alert
                    title="You are not authorized to close this docket!"
                    text={`To close this docket, please raise a docket close request.`}
                    type="warning"
                />
            );
        }
        if (data?.docket?.status === "Closed") {
            return (
                <>
                    <Alert
                        title="Docket already Closed!"
                        text={`Docket ${data.docket.code} has been closed on ${formatDateTime(data?.created_at, "DD-MM-YYYY")}`}
                        type="primary"
                    />
                    <Link className="btn btn-info" to={`/docket/${data.docket.code}`}>
                        View Docket
                    </Link>
                </>
            );
        } else if (data?.docket?.status === "Hold") {
            return (
                <>
                    <Alert
                        title="Docket is on Hold!"
                        text={`Please Resume the Docket ${data?.docket?.code} to Close.`}
                        type="warning"
                    />
                    <Link className="btn btn-info" to={`/docket/${data.docket.code}`}>
                        View Docket
                    </Link>
                    <button
                        className="btn btn-danger mx-2"
                        onClick={() => handleForceCloseDocket(data?.docket?.code)}
                        disabled={docketActionIsLoading}
                    >
                        Force Close the Docket
                    </button>
                </>
            );
        } else if (data?.docket?.pending_back_to_pop && data?.docket?.pending_back_to_pop.length > 0) {
            return (
                <>
                    <Alert
                        title="Update all the pending back to pop!"
                        text={`Please update pending back to pop ${data?.docket?.code} to Close.`}
                        type="warning"
                    />

                    <div className='mb-3'>
                        <HandleHoldVehicleBackToPop docket={data?.docket} />
                        {/* {data?.docket?.pending_back_to_pop.map((item) => (
                            item.type === 'forward' ? (
                                <span key={item.id} className="badge text-danger bg-light" role='button'>pending 1 forw.</span>
                            ) : item.type === 'hold' ? (
                                <span key={item.id} className="badge text-primary bg-light" role='button'>pending 1 hold.</span>
                            ) : null
                        ))} */}
                    </div>
                    <Link className="btn btn-info" to={`/docket/${data.docket.code}`}>
                        View Docket
                    </Link>

                </>
            );
        } else {
            // if (user?.permissions?.dockets?.can_close_docket) {

            return <CloseDocketAction data={data} setInfoModal={setInfoModal} />;
            // } else {
            //     <>
            //     <Alert
            //         title='Permission Error!'
            //         text={`You do not have the permission to close the docket.`}
            //         type='warning'
            //     />
            // </>
            // }
        }
    };

    return (
        <>
            <Helmet>
                <title>Close Docket</title>
            </Helmet>
            <PageBreadcrumbs
                title="Docket Close"
                history={[
                    { name: "Docket", to: "/docket" },
                    { name: id, to: `/docket/${id}` },
                ]}
                RightComp={() => (
                    <>
                        <form ref={docketFindForm} onSubmit={handleFindDocket}>
                            <input
                                type="text"
                                className="form-control form-control-solid m-b-lg"
                                defaultValue={id}
                                name="docketId"
                                placeholder="Enter Docket ID"
                                required
                            />
                        </form>
                    </>
                )}
            />

            <Grid container>
                <Grid item xs={12} sx={{ p: 1 }}>
                    {closedDocketsIsLoading && <Loader />}
                    {!closedDocketsData?.success && !closedDocketsIsLoading && (
                        <Alert
                            title="Docket Not Found!"
                            text={`Entered a Invalid Docket Id.`}
                            type="danger"
                        />
                    )}
                </Grid>
                <div className="row customInfoDesign w-100">
                    {user?.permissions?.dockets?.view && (
                        <Grid item xs={4} sx={{ p: 1 }}>
                            {closedDocketsData?.data &&
                                closedDocketsData?.success &&
                                !closedDocketsIsLoading && (
                                    <CloseDocketInfo data={closedDocketsData?.data} />
                                )}
                        </Grid>
                    )}
                    <Grid item xs={8} sx={{ p: 2 }}>
                        {/* {closedDocketsData?.data && closedDocketsData?.success && !closedDocketsIsLoading && <CloseDocketAction data={closedDocketsData?.data} />} */}
                        {user?.permissions?.dockets?.change ? (
                            <>
                                {closedDocketsData?.data &&
                                    closedDocketsData?.success &&
                                    !closedDocketsIsLoading &&
                                    renderDocketClose(closedDocketsData?.data)}
                            </>
                        ) : (
                            <Alert
                                title="Permission Error!"
                                text="You do not have permission to perform this action."
                                type="danger"
                            />
                        )}
                    </Grid>
                </div>
            </Grid>

            <div className="container">
                <div className="row ">
                    <div className="col mt-2">
                        {closedDocketsData?.data && !closedDocketsIsLoading && (
                            <DocketInfoModal
                                modal={modal}
                                setModal={setInfoModal}
                                docData={closedDocketsData?.data?.docket}
                                redirect={true}
                            />
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}
