import React from 'react'
import { useUser } from '../../hooks/user';
import moment from 'moment';
import { Card, CardContent, Grid, Paper } from "@mui/material";
import { formatDateTime, formatTime, getCorporateDocketTAT, latLongToGmap, showUser } from '../../utils/shortcuts';
import { Link } from 'react-router-dom';
import DocketHistory from '../Docket/History';

export default function CorpDocketInformation({ data, historyTab, setHistoryTab }) {
    const { user } = useUser();


    const location_a_gmap = latLongToGmap(
        data?.get_circuit?.latitude_a,
        data?.get_circuit?.longitude_a,
    );
    const location_b_gmap = latLongToGmap(
        data?.get_circuit?.latitude_b,
        data?.get_circuit?.longitude_b,
    );
    return (
        <>
            <div className="row justify-content-between">
                <div className="col-md-6">
                    {data.is_reopened && (
                        <span className="badge bg-info text-white"> Re-opened</span>
                    )}
                    <h3 className="display-6">
                        Corp. Docket Info{" "}
                    </h3>
                    <p className="lead">
                        <em>Last updated at {moment(data.updated_at).fromNow()}...</em>
                    </p>
                </div>
                <div className="col-md-6 text-md-end">
                    <h3 className="display-6">Docket ID: {data.code}</h3>
                    <p className="lead">
                        Status: <span className="badge badge-info">{data.status}</span>
                    </p>
                </div>
            </div>
            <hr />

            {/* MAIN */}
            <Grid container className="trinClass">

                {/* CIRCUIT DETAILS */}
                {!data.is_manual_docket && <Grid item lg={4} xs={12} sx={{ p: 0.5 }}>
                    <Card>
                        <Paper
                            className="fullheightIner"
                            style={{ maxHeight: 360, overflow: "auto" }}
                        >
                            <CardContent>
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <td colSpan={2}>
                                                <em>
                                                    CIRCUIT DETAILS
                                                </em>{" "}
                                                &nbsp;
                                                <span
                                                    className="badge text-white"
                                                    style={{
                                                        backgroundColor: data?.get_provider?.background,
                                                    }}
                                                >
                                                    {data.get_provider.title}
                                                </span>
                                            </td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <th scope="col">Circuit ID:</th>
                                            <td className="text-dark">
                                                <Link to={`/circuit/${data?.get_circuit?.id}`}>
                                                    {data?.get_circuit?.code}
                                                </Link>
                                                <br />
                                                <small>
                                                    {data?.get_circuit?.circuit_type} -{" "}
                                                    {data?.get_circuit?.link_type} <br />
                                                    {!data?.get_circuit?.is_maintenance && (
                                                        <>
                                                            Hirer Name: {data?.get_circuit?.get_hirer?.name}
                                                        </>
                                                    )}
                                                </small>
                                            </td>
                                        </tr>


                                        <tr>
                                            <th scope="col">Customer ID:</th>
                                            <td>{data?.get_circuit?.customer_id}</td>
                                        </tr>

                                        <tr>
                                            <th scope="col">Loc. A:</th>
                                            <td>
                                                {/* {data.get_circuit.location_a} */}
                                                {location_a_gmap ? (
                                                    <a
                                                        href={location_a_gmap}
                                                        target="_blank"
                                                        rel="noreferrer"
                                                        title="Open in Google Map"
                                                    >
                                                        {data?.get_circuit?.location_a}
                                                    </a>
                                                ) : (
                                                    data?.get_circuit?.location_a
                                                )}
                                                <br />
                                                <small>
                                                    {data?.get_circuit?.pin_a &&
                                                        `(${data?.get_circuit?.pin_a})`}
                                                </small>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th scope="col">Addr. A:</th>
                                            <td>{data?.get_circuit?.address_a}</td>
                                        </tr>
                                        <tr>
                                            <th scope="col">Loc. B:</th>
                                            <td>
                                                {/* {data.get_circuit.location_b} */}
                                                {location_b_gmap ? (
                                                    <a
                                                        href={location_b_gmap}
                                                        target="_blank"
                                                        rel="noreferrer"
                                                        title="Open in Google Map"
                                                    >
                                                        {data?.get_circuit?.location_b}
                                                    </a>
                                                ) : (
                                                    data?.get_circuit?.location_b
                                                )}
                                                {location_b_gmap ? (
                                                    <a
                                                        href={location_b_gmap}
                                                        target="_blank"
                                                        rel="noreferrer"
                                                        title="Open in Google Map"
                                                    >
                                                        {data?.get_circuit?.location_b}
                                                    </a>
                                                ) : (
                                                    data?.get_circuit?.location_b
                                                )}
                                                <br />
                                                <small>
                                                    {data?.get_circuit?.pin_b &&
                                                        `(${data?.get_circuit?.pin_b})`}
                                                </small>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th scope="col">Addr. B:</th>
                                            <td>{data?.get_circuit?.address_b}</td>
                                        </tr>
                                        {/* <tr>
                                            <th scope="col">OFC Dist:</th>
                                            <td>{data?.get_circuit?.ofc_distance || "-"}</td>
                                        </tr> */}
                                        <tr>
                                            <th scope="col">Connectivity:</th>
                                            <td>{data?.get_circuit?.type_of_connectivity}</td>
                                        </tr>
                                        {/* <tr>
                                            <th scope="col">Fiber Details:</th>
                                            <td>
                                                <small>
                                                    OFC Color: {data?.get_circuit?.ofc_color || "-"} <br />
                                                    {`Ofc Distance: ${data?.get_circuit?.ofc_distance || "-"}`}{" "}
                                                </small>
                                            </td>
                                        </tr> */}
                                        <tr>
                                            <th scope="col">Status:</th>
                                            <td>{data?.get_circuit?.status} </td>
                                        </tr>
                                        <tr>
                                            <th scope="col">
                                                Created at:
                                                <br />
                                                <em>
                                                    <small>last updated at:</small>
                                                </em>
                                            </th>
                                            <td>
                                                {formatDateTime(
                                                    data.created_at,
                                                    "MMMM Do YYYY, h:mm:ss a",
                                                )}
                                                <br />
                                                <em>
                                                    <small>
                                                        {formatDateTime(
                                                            data.updated_at,
                                                            "MMMM Do YYYY, h:mm:ss a",
                                                        )}
                                                    </small>
                                                </em>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </CardContent>
                        </Paper>
                    </Card>
                </Grid>}

                {/* DOCKET DETAILS */}
                <Grid item lg={4} xs={12} sx={{ p: 0.5 }}>
                    <Card>
                        <Paper
                            className="fullheightIner"
                            style={{ maxHeight: 360, overflow: "auto" }}
                        >
                            <CardContent>
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <td colSpan={2}>
                                                <em> CORP. {data?.is_manual_docket && 'MANUAL'} DOCKET DETAILS
                                                </em>{" "}
                                                &nbsp;
                                                <span
                                                    className="badge text-white"
                                                    style={{
                                                        backgroundColor: data.get_provider.background,
                                                    }}
                                                >
                                                    {data.get_provider.title}
                                                </span>
                                            </td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <th scope="col">Problem:</th>
                                            <td>{data?.get_problem?.title}</td>
                                        </tr>
                                        {data.third_party_docket_no && (
                                            <tr>
                                                <th scope="col">Third Party Docket No:</th>
                                                <td>{data.third_party_docket_no}</td>
                                            </tr>
                                        )}


                                        {data?.case_title && (
                                            <tr>
                                                <th scope="col">Case Title:</th>
                                                <td>{data?.case_title} </td>
                                            </tr>
                                        )}
                                        {data?.customer_name && (
                                            <tr>
                                                <th scope="col">Customer name:</th>
                                                <td>{data?.customer_name} </td>
                                            </tr>
                                        )}
                                        {data?.case_reason && (
                                            <tr>
                                                <th scope="col">Case reason:</th>
                                                <td>{data?.case_reason} </td>
                                            </tr>
                                        )}
                                        {data?.case_origin && (
                                            <tr>
                                                <th scope="col">Case Origin:</th>
                                                <td>{data?.case_origin} </td>
                                            </tr>
                                        )}
                                        {data?.partner && (
                                            <tr>
                                                <th scope="col">Partner:</th>
                                                <td>{data?.partner} </td>
                                            </tr>
                                        )}
                                        {data?.contact_person && (
                                            <tr>
                                                <th scope="col">Contact Person:</th>
                                                <td>{data?.contact_person} </td>
                                            </tr>
                                        )}
                                        {data?.zone && (
                                            <tr>
                                                <th scope="col">Zone:</th>
                                                <td>{data?.zone} </td>
                                            </tr>
                                        )}
                                        {data?.next_followup_date && (
                                            <tr>
                                                <th scope="col">Next Follow Up Date:</th>
                                                <td>{formatDateTime(data?.next_followup_date, 'DD-MM-YYYY')} </td>
                                            </tr>
                                        )}
                                        {data?.next_followup_time && (
                                            <tr>
                                                <th scope="col">Next Follow Up Time:</th>
                                                <td>{formatTime(data?.next_followup_time, 'h:mm A')} </td>
                                            </tr>
                                        )}
                                        {/* {data.get_team && (
                                            <tr>
                                                <th scope="col">Team:</th>
                                                <td>{showUser(data?.get_team)} </td>
                                            </tr>
                                        )}
                                        {data.alt_team && (
                                            <tr>
                                                <th scope="col">Alt Team:</th>
                                                <td>{showUser(data?.alt_team)}</td>
                                            </tr>
                                        )}
                                        {data.field_team && (
                                            <tr>
                                                <th scope="col">Field Team:</th>
                                                <td>{showUser(data?.field_team)}</td>
                                            </tr>
                                        )} */}

                                        {data.comment && (
                                            <tr>
                                                <th scope="col">Comment:</th>
                                                <td>{data.comment || "-"}</td>
                                            </tr>
                                        )}



                                        {data.get_created_by && (
                                            <tr>
                                                <th scope="col">Docketed By:</th>
                                                <td>{showUser(data?.get_created_by)}</td>
                                            </tr>
                                        )}
                                        <tr>
                                            <th scope="col">Status:</th>
                                            <td>
                                                {data.status === "Cancelled" ? (
                                                    <span
                                                        className={`text-danger fw-bold pointer`}
                                                        onClick={() => { }}>
                                                        {data.status}
                                                    </span>
                                                ) : (
                                                    data.status
                                                )}
                                            </td>
                                        </tr>
                                        {data.is_moved_fiber && <tr>
                                            <th scope="col">Moved Fiber Docket:</th>
                                            <td>{data?.fiber_docket_id || '-'}</td>
                                        </tr>}
                                        <tr>
                                            <th scope="col">TAT:</th>
                                            <td>{getCorporateDocketTAT(data?.closed_at, data.created_at)}</td>
                                        </tr>
                                        <tr>
                                            <th scope="col">
                                                Created at:
                                                <br />
                                                <em>
                                                    <small>last updated at:</small>
                                                </em>
                                            </th>
                                            <td>
                                                {formatDateTime(
                                                    data.created_at,
                                                    "MMMM Do YYYY, h:mm:ss a",
                                                )}
                                                <br />
                                                <em>
                                                    <small>
                                                        {formatDateTime(
                                                            data.updated_at,
                                                            "MMMM Do YYYY, h:mm:ss a",
                                                        )}
                                                    </small>
                                                </em>
                                            </td>
                                        </tr>

                                    </tbody>
                                </table>
                            </CardContent>
                        </Paper>
                    </Card>
                </Grid>


                {/* DOCKET CLOSED INFO */}
                {data.status === 'Closed' && !user?.roles?.includes('LC') && (
                    <Grid item lg={4} xs={12} sx={{ p: 0.5 }}>
                        <Card>
                            <Paper
                                className="fullheightIner"
                                style={{ maxHeight: 360, overflow: "auto" }}
                            >
                                <CardContent>
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <td colSpan={2}>
                                                    <em>CORP. DOCKET CLOSED DETAILS</em> &nbsp;
                                                    {/* {data.has_recent_docket.status && (
                                                        <span className="badge badge-info badge-style-light">
                                                            Cooling down,{" "}
                                                            <small>
                                                                {formatDateTime( data.closed_at, "MMMM Do YYYY, h:mm a", )}
                                                            </small>
                                                        </span>
                                                    )} */}
                                                </td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <th scope="col">Attend By:</th>
                                                <td>{data.attend_by_team ?? "-"} <br /> <small>Remarks: {data.attend_by_team_remarks || "-"}</small></td>
                                            </tr>
                                            <tr>
                                                <th scope="col">Confirmed By:</th>
                                                <td>{data.confirmed_by ?? "-"} <br /> <small>Remarks: {data.confirmed_by_remarks || "-"}</small></td>
                                            </tr>
                                            <tr>
                                                <th scope="col">Comment:</th>
                                                <td>{data.close_comment || "-"}</td>
                                            </tr>
                                            <tr>
                                                <th scope="col">RFO:</th>
                                                <td>{data?.get_rfo?.name || "-"}</td>
                                            </tr>
                                            {!user?.roles?.includes('CU') && <tr>
                                                <th scope="col">Work Done By:</th>
                                                <td>{showUser(data?.get_work_done_by)} <br /><small>Remarks: {data.work_done_by_remarks || "-"}</small></td>
                                            </tr>}
                                            <tr>
                                                <th scope="col">Physical Visit Required:</th>
                                                <td>{data?.physical_visit_required} {data?.physical_visit_required === 'Yes' && (<small><br />Name: {data.person_name || "-"}</small>)}</td>
                                            </tr>
                                            <tr>
                                                <th scope="col">Equipment Field:</th>
                                                <td>{data?.equipment_field || '-'} </td>
                                            </tr>
                                            {!user?.roles?.includes('CU') && <tr>
                                                <th scope="col">KM Field:</th>
                                                <td>{data?.km_field || '-'} </td>
                                            </tr>}


                                            <tr>
                                                <th scope="col">Closed by:</th>
                                                <td>
                                                    {showUser(data?.get_closed_by)}
                                                </td>
                                            </tr>
                                            <tr>
                                                <th scope="col">
                                                    Closed at:
                                                </th>
                                                <td>
                                                    {formatDateTime(
                                                        data.closed_at,
                                                        "MMMM Do YYYY, h:mm:ss a",
                                                    )}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </CardContent>
                            </Paper>
                        </Card>
                    </Grid>
                )}

                {/* HISTORY */}
                <DocketHistory
                    data={data}
                    historyTab={historyTab}
                    setHistoryTab={setHistoryTab}
                />
            </Grid>
        </>
    )
}
