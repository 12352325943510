// import moment from 'moment';
import React from 'react'
import Modal from 'react-modal'
import { Link, useNavigate } from 'react-router-dom';
import { useSendDocketEmail, useSendDocketSMS } from '../../hooks/docket/send';
import { toast } from 'react-toastify';
import { handleWhatsappShare } from '../../utils/whatsappShare';
import { formatDateTime, showUser } from '../../utils/shortcuts';
import { secondsToHMS } from '../../utils';
import { useUser } from '../../hooks/user';
import { useState } from 'react';
import EmailPreviewModal from './EmailPreviewModal';
const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        maxWidth: '700px',
        width: '100%'
    },
    overlay: { zIndex: 1000 }
};
export default function DocketInfoModal({ modal, setModal, docData, redirect }) {
    const { user } = useUser();
    // console.log('user ->', user)
    let navigate = useNavigate();
    const { handleSendDocketSMS, isSendingDocketSMS } = useSendDocketSMS()
    const { handleSendDocketEmail, isSendingDocketEmail } = useSendDocketEmail()
    const [emailPreviewModal, setEmailPreviewModal] = useState(false)
    // console.log('docData', docData)


    // Handle Send Docket SMS
    const handleDocketSMS = async () => {
        const res = await handleSendDocketSMS(docData.code)
        res.success ? toast.success(res.detail) : toast.error(res.detail);
    }
    const handleDocketEmail = async () => {
        const res = await handleSendDocketEmail(docData.code)
        res.success ? toast.success(res.detail) : toast.error(res.detail);
    }
    return (
        <Modal
            isOpen={modal}
            // onAfterOpen={afterOpenModal}
            // onRequestClose={closeModal}
            style={customStyles}
            contentLabel="Example Modal"
        >
            <div className="container-fluid">

                <div className="d-flex justify-content-between">
                    <h5 className="modal-title mb-3 text-center text-info">{docData.get_circuit.is_maintenance && 'Maintenance'} Docket Information</h5>
                    <button type="button" className="btn-close shadow-none" data-bs-dismiss="modal" aria-label="Close" onClick={() => { setModal(false); redirect && navigate('/docket'); }}></button>

                </div>

                <div className="modal-body" style={{ width: '100%' }}>
                    <div className="d-flex justify-content-between">
                        <div>
                            <p className="display-6 text-dark m-0">Docket ID:</p>
                        </div>
                        <div>
                            <h3 className="display-6 text-danger m-0">{docData.code}</h3>
                        </div>
                    </div>
                    <hr />
                    <div className="d-flex justify-content-between align-items-center mt-3">
                        <div>
                            {user?.roles?.includes('EXP') && <div className='d-flex gap-2'>
                                <button className='btn btn-primary' onClick={handleDocketSMS} disabled={isSendingDocketSMS}><i className="fa fa-comment" aria-hidden="true"></i></button>
                                <button className='btn btn-primary' onClick={handleDocketEmail} disabled={isSendingDocketEmail}> <i className="fa fa-envelope" aria-hidden="true"></i>{isSendingDocketEmail ? '...' : ''}</button>
                                <button className='btn btn-success' onClick={() => handleWhatsappShare(docData.get_circuit, docData)}> <i className="fa fa-share" aria-hidden="true"></i></button>
                                <button className='btn btn-info' onClick={() => setEmailPreviewModal(true)}> <i className="fa fa-eye" aria-hidden="true"></i></button>

                                <EmailPreviewModal
                                    modal={emailPreviewModal}
                                    setModal={setEmailPreviewModal}
                                    docData={docData}
                                />
                            </div>}
                        </div>
                        <div></div>
                    </div>
                    <hr />
                    <table className='table m-0'>
                        <tbody>
                            <tr>
                                <td className='fw-bold text-dark text-center' colSpan={2}>
                                    <p className='text-center'>New Docket Raised @ {docData?.get_hirer?.name}</p>
                                </td>
                            </tr>
                            <tr>
                                <th>Status:</th>
                                <td className='fw-bold text-dark'>{docData.status} {docData.alt_status && docData.status === 'Pending' ? ` - ${docData.alt_status}` : ''}</td>
                            </tr>
                            <tr>
                                <th>Problem:</th>
                                <td className='text-dark'>{docData?.get_problem?.title ?? 'NA'}</td>
                            </tr>
                            {!docData.get_circuit.is_maintenance && <>
                                <tr>
                                    <th>Circuit ID:</th>
                                    <td className='text-dark'><strong>{docData.get_circuit.is_maintenance ? 'N/A' : <Link to={`/circuit/${docData.get_circuit.id}?ref=docketinfo&id=${docData.code}`} target="_blank">{docData.get_circuit.code}</Link>}</strong></td>
                                </tr>
                                <tr>
                                    <th>Customer ID:</th>
                                    <td>{docData.get_circuit.customer_id}</td>
                                </tr>
                            </>}
                            <tr>
                                <th>LC Details:</th>
                                <td>{docData.get_circuit.lc_contact_persion || '-'} @ {docData.get_circuit.lc_mobile_no || 'NOT AVAILABLE'}</td>
                            </tr>
                            <tr>
                                <th>OFC Distance:</th>
                                <td>{docData.get_circuit.ofc_distance || '-'} </td>
                            </tr>
                            <tr>
                                <th>Location A:</th>
                                <td>{docData.get_circuit.location_a}</td>
                            </tr>
                            <tr>
                                <th>Location B:</th>
                                <td>{docData.get_circuit.location_b}</td>
                            </tr>

                            <tr>
                                <th>Docketed By:</th>
                                <td>{docData.docketed_by} @ {docData.docketed_by_contact ?? 'NOT AVAILABLE'}</td>
                            </tr>
                            <tr>
                                <th>3rd Party Docket No:</th>
                                <td>{docData.third_party_docket_no || 'NA'}</td>
                            </tr>
                            <tr>
                                <th>Docket Opening Time:</th>
                                {/* <td>{moment(docData.created_at).format('LLLL')}</td> */}
                                <td>{formatDateTime(docData.created_at, 'LLLL')}</td>
                            </tr>
                            {docData.get_fiber_tl && <tr>
                                <th>Fiber TL:</th>
                                {/* <td>{docData.get_fiber_tl.name} @ {docData.get_fiber_tl.mobile ?? 'NOT AVAILABLE'}</td> */}
                                <td>{showUser(docData?.get_fiber_tl)}</td>
                            </tr>}
                            {docData.get_technician && <tr>
                                <th>Technician:</th>
                                {/* <td>{docData.get_technician.name} @ {docData.get_technician.mobile ?? 'NOT AVAILABLE'} </td> */}
                                <td>{showUser(docData?.get_technician)}</td>
                            </tr>}

                            <tr>
                                <th>Total TAT:</th>
                                <td>{secondsToHMS(docData?.get_total_tat)}</td>
                            </tr>
                            <tr>
                                <th>Hold TAT:</th>
                                <td>{secondsToHMS(docData?.get_hold_tat) || 'N/A'}</td>
                            </tr>
                            {docData.status === 'Resolved' | docData.status === 'Closed' ? <tr>
                                <th>Wait TAT:</th>
                                <td>{secondsToHMS(docData?.get_wait_tat) || 'N/A'}</td>
                            </tr> : ''}
                            <tr>
                                <th>Cust. Access Issue:</th>
                                <td>{docData?.get_closed_info?.ch_hh || '0'}:{docData?.get_closed_info?.ch_mm || '0'}:{docData?.get_closed_info?.ch_ss || '0'}</td>
                            </tr>
                            <tr>
                                <th>Other`s Issue:</th>
                                <td>{docData?.get_closed_info?.oh_hh || '0'}:{docData?.get_closed_info?.oh_mm || '0'}:{docData?.get_closed_info?.oh_ss || '0'}</td>
                            </tr>
                            <tr>
                                <th>Others Hold Reason:</th>
                                <td>{docData?.get_closed_info?.others_hold_reason || 'N/A'}</td>
                            </tr>
                            <tr>
                                <th>Actual TAT:</th>
                                <td>{secondsToHMS(docData?.get_actual_tat) || '-'}</td>
                            </tr>

                            {/* {docData.status === 'Resolved' | docData.status === 'Closed' && docData.get_closed_info ? <> */}
                            <tr>
                                <th>Docket Closing Time:</th>
                                {/* <td>{formatDateTime(docData?.get_closed_info?.updated_at, 'LLLL')}</td> */}
                                <td>{formatDateTime(docData?.closed_at, 'LLLL')}</td>
                            </tr>
                            <tr>
                                <th>RFO:</th>
                                {/* <td>{formatDateTime(docData?.get_closed_info?.created_at, 'DD/MM/YY hh:mm A')}</td> */}
                                <td>{docData?.get_closed_info?.get_rfo?.name ?? 'N/A'}</td>
                            </tr>

                            <tr>
                                <th>Total KM:</th>
                                <td>{docData?.get_total_km} KM</td>
                            </tr>
                            <tr>
                                <th>Confirmed By:</th>
                                <td>{docData?.get_closed_info?.confirmed_by ?? 'N/A'}</td>
                            </tr>
                            <tr>
                                <th>Comments:</th>
                                <td><code>Open:</code> {docData?.comment || 'NA'} <br />
                                    <code>Close:</code> {docData?.get_closed_info?.comments || 'NA'}
                                </td>
                            </tr>


                            {/* </> : ''} */}
                        </tbody>
                    </table>


                    <div className="d-flex justify-content-between align-items-center mt-3">
                        <div>

                        </div>
                        <div>
                            <button className='btn btn-dark' onClick={() => { setModal(false); redirect && navigate('/docket'); }}> <i className="fa fa-times" aria-hidden="true"></i>Close</button>
                        </div>
                    </div>

                </div>
            </div>
        </Modal>
    )
}
