import React, { useEffect, useState } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import Loader2 from '../Common/Loader2';
import TextField from '../Common/Fields/TextField';
import DatePicker from '../Common/Fields/DatePickerField';
import { LoadingButton } from '@mui/lab';
import { Button } from '@mui/material';
import Datetime from 'react-datetime';

export default function ShiftRoasterFilterForm({ isLoading, handleSubmit, setModal, resetFrom, searchParams }) {
    const [params, setParams] = useState({});

    const validate = Yup.object({
        q: Yup.string(),

    }).shape({
        from_date: Yup.string().when('to_date', {
            is: (val) => val !== null,
            then: Yup.string().required('required').nullable(),
            otherwise: Yup.string().nullable()
        }),
        to_date: Yup.string().when('from_date', {
            is: (val) => val !== null,
            then: Yup.string().required('required').nullable(),
            otherwise: Yup.string().nullable()
        }),
    }, ['from_date', 'to_date']);

    return (
        <Formik
            initialValues={{
                from_date: params?.from_date ?? null,
                to_date: params?.to_date ?? null,
                q: params?.q ?? '',
            }}
            validationSchema={validate}
            onSubmit={handleSubmit}
        >
            {formik => (
                <Form>
                    <div className='row'>
                        <div className="col-md-12 mb-2">
                            <TextField label='Search' type="text" name='q' placeholder='Search' />
                        </div>
                    </div>

                    {/* <div className='row'>
                        <div className="col-md-6 mb-4">

                            <Datetime
                                onChange={date => formik.setFieldValue('from_date', date)}
                                value={formik.values.from_date}
                                timeFormat={false}
                                inputProps={{ placeholder: 'Select From Date' }}
                                className="datepicker-top"

                            />

                        </div>
                        <div className="col-md-6 mb-4">
                            <Datetime
                                onChange={date => formik.setFieldValue('to_date', date)}
                                value={formik.values.to_date}
                                timeFormat={false}
                                inputProps={{ placeholder: 'Select To Date' }}

                            />
                        </div>
                    </div> */}

                    <LoadingButton
                        loading={isLoading}
                        type="submit"
                        variant="contained"
                        disableElevation
                    >
                        Search
                    </LoadingButton>
                    <Button variant="outlined" sx={{ ml: 1 }} disableElevation onClick={() => setModal(false)}>Close</Button>
                </Form>
            )}
        </Formik>
    );
}