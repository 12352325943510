import { API_HOST_URL, TOKEN_STORE_NAME } from "../../config";
import { endpoints } from "../../config/endpoints";
import { useQueryClient, useQuery, useMutation } from '@tanstack/react-query';
import { fetchJson } from "../../libs/api";
import { getTokens } from "../../utils/shortcuts";
import { useState } from "react";

const USER_QUERY_KEY = 'user'
const USERS_LITE_QUERY_KEY = 'user_lite'
// const USER_DETAIL_QUERY_KEY = 'user_detail'
const USERS_QUERY_KEY = 'users'
const USERS_REFERRALS_QUERY_KEY = 'users_referrals'
const TEAMS_QUERY_KEY = 'teams'


// Get User
export function useUser() {
    const { isLoading, error, data, status } = useQuery([USER_QUERY_KEY], async () => {
        try {
            const { accessToken } = getTokens();
            const user = await fetchJson(`${API_HOST_URL}/${endpoints.account.user}`, {
                headers: { 'Authorization': `Bearer ${accessToken}` },
            })
            return user.data
        } catch (err) {
            return null;
        }
    }, {
        cacheTime: Infinity,
        staleTime: 30_000, // ms
    })
    return { user: data, isLoading, error, status }
}

// Login 
export function useLogin() {
    const queryClient = useQueryClient();
    const mutation = useMutation(({ username, password, device_info }) => fetchJson(`${API_HOST_URL}/${endpoints.account.login}`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            username,
            password,
            device_info
        })
    }, true))
    return {
        handleLogin: async (username, password, device_info) => {
            try {
                const res = await mutation.mutateAsync({ username, password, device_info });
                const user = await res.json();
                if (user.success) {
                    const { tokens } = user.data;
                    // await queryClient.invalidateQueries(['circuits'])
                    // await queryClient.invalidateQueries(['providers'])
                    // await queryClient.invalidateQueries(['dockets'])
                    localStorage.setItem(TOKEN_STORE_NAME, JSON.stringify(tokens));
                    queryClient.setQueryData([USER_QUERY_KEY], user.data);
                }
                return user
                // return {
                //     status: res.status,
                //     success: res.status === 200,
                //     ...user.data
                // }
            } catch (err) {
                return {
                    success: false,
                    detail: 'Server: something wents wrong.'
                }
            }
        },
        loginError: mutation.isError,
        loginLoading: mutation.isLoading,
    }
}

// Login with OTP // LCO
export function useLoginOTP() {
    const queryClient = useQueryClient();
    const mutation = useMutation(({ mobile, otp }) => fetchJson(`${API_HOST_URL}/${endpoints.account.login_with_otp}`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            mobile,
            otp
        })
    }, true))
    return {
        handleLogin: async (mobile, otp) => {
            try {
                const res = await mutation.mutateAsync({ mobile, otp });
                const user = await res.json();
                if (user.success) {
                    const { tokens } = user.data;
                    localStorage.setItem(TOKEN_STORE_NAME, JSON.stringify(tokens));
                    queryClient.setQueryData([USER_QUERY_KEY], user.data);
                }
                return user
                // return {
                //     status: res.status,
                //     success: res.status === 200,
                //     ...user.data
                // }
            } catch (err) {
                return {
                    success: false,
                    detail: 'Server: something wents wrong.'
                }
            }
        },
        loginError: mutation.isError,
        loginLoading: mutation.isLoading,
    }
}

// Logout
export function useLogout() {
    const queryClient = useQueryClient();
    const { accessToken, refreshToken } = getTokens();
    const mutation = useMutation(() => fetchJson(`${API_HOST_URL}/${endpoints.account.logout}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`
        },
        body: JSON.stringify({ refresh: refreshToken }),
    }, true))
    return async () => {
        try {
            await mutation.mutateAsync();
            localStorage.removeItem(TOKEN_STORE_NAME);
            queryClient.setQueryData([USER_QUERY_KEY], undefined);
            queryClient.setQueryData(['providers'], undefined);
            queryClient.setQueryData(['circuits'], undefined);
            queryClient.setQueryData(['dockets'], undefined);
            return true
        } catch (err) {
            return false
        }
    }
}


// Get Users List
// export function useUsersList() {
//     const { isLoading, data } = useQuery([USERS_QUERY_KEY], async () => {
//         try {
//             const { accessToken } = getTokens();
//             const users = await fetchJson(`${API_HOST_URL}/${endpoints.users.list}`, {
//                 headers: { 'Authorization': `Bearer ${accessToken}` },
//             })
//             return users;
//         } catch (err) {
//             return null;
//         }
//     }, {
//         cacheTime: Infinity,
//         staleTime: 30_000, // ms
//     })
//     return { usersData: data, usersIsLoading: isLoading }
// }

export function useUsersList(defaultParam = '', enabled = true) {
    const [param, setParam] = useState(null);
    const { isLoading, data } = useQuery([USERS_QUERY_KEY, { param: param, defaultParam }], async (key) => {
        const param = key.queryKey[1].param;
        const defaultParam = key.queryKey[1].defaultParam;
        try {
            const { accessToken } = getTokens();
            const param_url = param ? param : defaultParam;
            const res = await fetchJson(`${API_HOST_URL}/${endpoints.users.list}${param_url}`, {
                headers: { 'Authorization': `Bearer ${accessToken}` },
            }, true)
            return await res.json();

        } catch (err) {
            return { success: false, detail: err };
        }
    }, {
        cacheTime: Infinity,
        staleTime: 30_000,
        enabled: enabled,
    })
    return {
        usersData: data,
        usersIsLoading: isLoading,
        filterUser: async (value) => setParam(value),
    }
}

export function useUsersListLite(defaultParam = '', enabled = true) {
    // CREATED FOR QUICK USERS FETCH
    const [param, setParam] = useState(null);
    const { isLoading, data } = useQuery([USERS_LITE_QUERY_KEY, { param: param, defaultParam }], async (key) => {
        const param = key.queryKey[1].param;
        const defaultParam = key.queryKey[1].defaultParam;
        try {
            const { accessToken } = getTokens();
            const param_url = param ? param : defaultParam;
            const res = await fetchJson(`${API_HOST_URL}/${endpoints.users.lite}${param_url}`, {
                headers: { 'Authorization': `Bearer ${accessToken}` },
            }, true)
            return await res.json();

        } catch (err) {
            return { success: false, detail: err };
        }
    }, {
        cacheTime: Infinity,
        staleTime: 30_000,
        enabled: enabled,
    })
    return {
        usersData: data,
        usersIsLoading: isLoading,
        filterUser: async (value) => setParam(value),
    }
}


// export const formatUserOptionsList = (data) => {
//     console.log(data)
//     return {
//         value: data.id,
//         // label: `${data.account_name.toUpperCase() } (${data.user_role.toUpperCase() })`
//         label: `${data.account_name.toUpperCase() } (${data?.roles?.join(', ')})`
//     }
// }


export const formatUserOptionsList = (data) => {
    return {
        value: data.id,
        label: `${data.account_name.toUpperCase()} (${Array.isArray(data?.roles) ? data.roles.join(', ') : 'No roles'})`
    };
};

export const formatUserOptionsDetailList = (data) => {
    return {
        value: data.id,
        account_name: data.account_name,
        mobile: data.mobile,
        label: `${data.account_name.toUpperCase() } (${Array.isArray(data?.roles) ? data.roles.join(', ') : 'No roles'})`
    }
}

// // Get User's Detail
// export function useGetUserDetail(username) {
//     const { accessToken } = getTokens();
//     const { isLoading, isError, data, status } = useQuery(
//         [USER_DETAIL_QUERY_KEY], async () => {
//             try {
//                 const res = await fetchJson(`${API_HOST_URL}/${endpoints.users.list}/${username}`, {
//                     method: 'GET',
//                     headers: {
//                         'Content-Type': 'application/json',
//                         'Authorization': `Bearer ${accessToken}`
//                     }
//                 }, true)
//                 // console.log('hook data ->', data)
//                 const data = await res.json()
//                 return data
//             } catch (err) {
//                 return undefined;
//             }
//         }, {
//         cacheTime: 0,
//         staleTime: 1,
//         // enabled: !!questions,
//         retry: 3,
//         refetchOnMount: true, // ms
//     })
//     return { userData: data, userIsLoading: isLoading, isError, status }
// }


// Password Change
export function usePasswordChange() {
    const { accessToken } = getTokens();
    const mutation = useMutation((values) => fetchJson(`${API_HOST_URL}/${endpoints.account.change_password}`, {
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`
        },
        body: JSON.stringify(values)
    }, true))
    return {
        handlePwdChange: async (values) => {
            try {
                const res = await mutation.mutateAsync(values);
                const resData = await res.json()
                return resData
            } catch (err) {
                return false
            }
        },
        PwdChangeisError: mutation.isError,
        PwdChangeisLoading: mutation.isLoading,
    }
}


// Get User's Referrals List
export function useUsersReferralList(user_id, enabled) {
    const { accessToken } = getTokens();

    const { isLoading, isError, data, status } = useQuery(
        [USERS_REFERRALS_QUERY_KEY, enabled], async () => {
            try {
                // console.log('CALLED DIPENDED QUERY',user_id )
                const res = await fetchJson(`${API_HOST_URL}/${endpoints.users.referrals}/${user_id}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${accessToken}`
                    }
                }, true)
                // console.log('hook data ->', data)
                const data = await res.json()
                return data
            } catch (err) {
                return undefined;
            }
        }, {
        cacheTime: 0,
        staleTime: 1,
        enabled: !!enabled,
        retry: 3,
        refetchOnMount: true, // ms
    })

    return { usersReferralData: data, usersReferralIsLoading: isLoading, isError, status }
}


// Get Team List
export function useTeamList() {
    const { isLoading, data } = useQuery([TEAMS_QUERY_KEY], async () => {
        try {
            const { accessToken } = getTokens();
            const res = await fetchJson(`${API_HOST_URL}/${endpoints.users.team_list}`, {
                headers: { 'Authorization': `Bearer ${accessToken}` },
            }, true)
            return await res.json();
        } catch (err) {
            return {
                success: false,
                detail: err
            };
        }
    }, {
        // cacheTime: Infinity,
        // staleTime: 30_000, // ms
        cacheTime: 0,
        staleTime: 1,
    })
    return { teamsData: data, teamsIsLoading: isLoading }
}


// Save / Update User (Super Admin)
export function useUserAction(method) {
    const { accessToken } = getTokens();
    const queryClient = useQueryClient();
    const mutation = useMutation((data) => fetchJson(`${API_HOST_URL}/${endpoints.users.list}${method === 'PATCH' ? '/' + data.id : ''}`, {
        method: method,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`
        },
        body: JSON.stringify({...data, groups: [data.groups]})
    }, true), {
        retry: 2,
    })
    return {
        handleUserAction: async (data) => {
            try {
                const res = await mutation.mutateAsync(data);
                const resData = await res.json()
                if (resData.success) {
                    await queryClient.invalidateQueries([USERS_QUERY_KEY])
                }
                return resData
            } catch (err) {
                return {
                    success: false,
                    detail: err
                }
            }
        },

        userActionIsLoading: mutation.isLoading,
    }
}


export function useSendOTP() {
    const mutation = useMutation((value) => fetchJson(`${API_HOST_URL}/${endpoints.account.send_otp}`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(value)
    }, true))
    return {
        handleSendOTP: async (value) => {
            try {
                const res = await mutation.mutateAsync(value);
                return await res.json();
                
            } catch (err) {
                return {
                    success: false,
                    detail: 'Server: something wents wrong.'
                }
            }
        },
        isOTPSending: mutation.isLoading,
    }
}

export function useResetPassword() {
    const mutation = useMutation((value) => fetchJson(`${API_HOST_URL}/${endpoints.account.reset_password}`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(value)
    }, true))
    return {
        handleSetPassword: async (value) => {
            try {
                const res = await mutation.mutateAsync(value);
                return await res.json();
                
            } catch (err) {
                return {
                    success: false,
                    detail: 'Server: something wents wrong.'
                }
            }
        },
        isLoadingPassword: mutation.isLoading,
    }
}