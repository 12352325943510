export const API_HOST_URL = process.env.REACT_APP_API_HOST_URL;
export const IP_FETCH_API_HOST = 'https://api.ipify.org/?format=json';

// Core [*]
export const FIBER_MATERIAL_ID = parseInt(process.env.REACT_APP_DB_FIBER_MATERIAL_ID) // # Parent Material / Additional Material Fetch
export const APPROVAL_REQUIRED_PROBLEM_IDS = [84,85,153,100] // # When finding this ID, direct new docket will be prohibited.

// Naming 
export const PROJECT_TITLE = 'Meghbela'
export const TOKEN_STORE_NAME = 'mb_tokens'

// Default Config (Local Storage)
export const DARK_MODE_LOCAL = 'dark_mode'

// export const NOTIFICATION_TRACK_DATA = 'user-notifications' 
export const CURRENT_PROVIDER_LOCAL_DATA = 'current_provider'
export const DOCKET_LIST_COLUMNS = 'docket_columns'
export const CORP_DOCKET_LIST_COLUMNS = 'docket_columns'
export const CIRCUIT_LIST_COLUMNS = 'circuit_columns'
export const DOCKET_REQUESTS_LIST_COLUMNS = 'docket_requests'

// PUSHER
export const ENABLE_PUSHER =  process.env.NODE_ENV === 'production'
export const PUSHER_APP_KEY = 'daf4d328cf7d7cdc8ca2'
export const APP_CLUSTER = 'ap2'
export const PUSHER_DEFAULT_CHANNEL = 'md-default-channel'
export const PUSHER_DEFAULT_EVENT = 'md-default-event'