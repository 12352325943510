import React from 'react'
import DataTableBase from '../../../components/Common/DataTableBase';
import MobileDockets from '../../../components/MobileTables/MobileDockets';
import { formatDateTime } from '../../../utils/shortcuts';
import { truncate } from '../../../utils';
import { Link } from 'react-router-dom';
import { DocketActionSkeleton, docketSkeletonData, skeletonRows } from './DocketSkeletonLoader';
import HandleHoldVehicleBackToPop from './HandleHoldVehicleBackToPop';

export default function DocketTables({
    docketsData,
    docketsIsLoading,
    visibleCols,
    setBacktoPopModal,
    setDocData,
    setCurrentDocketData,
    handleDocketInfoModal,
    setfiberTLChangeModal,
    setDocketChangeModal,
    setLCCancelDocket,
    user,

}) {
    const [maximizedRows, setMaximizedRows] = React.useState({});

    const toggleMinimize = (rowId) => {
        setMaximizedRows(prevState => ({
            ...prevState,
            [rowId]: !prevState[rowId]
        }));
    };
    // Docket Table Data
    const columns = [
        {
            name: 'Docket ID',
            wrap: true,
            selector: row => {
                return <div title={`${row?.is_reopened ? 'Docket Reopened' : ''}`}>
                    {row?.get_closed_info?.vehicle1_to_pop && !row?.get_closed_info?.vehicle1_pop_km | row?.get_closed_info?.vehicle2_to_pop && !row?.get_closed_info?.vehicle2_pop_km | row?.get_closed_info?.vehicle3_to_pop && !row?.get_closed_info?.vehicle3_pop_km ? <span className="badge text-danger bg-light" role='button' onClick={() => {
                        setBacktoPopModal(true);
                        setDocData(row);
                    }}>update</span> : ''}
                    {row?.code}
                    {row?.is_reopened && <span className="red-dot" title='Reopened'></span>}
                    <HandleHoldVehicleBackToPop docket={row} />
                </div>
            },
            sortable: true,
            width: '150px !important',
        },
        {
            name: 'Created at',
            wrap: true,
            selector: row => formatDateTime(row?.created_at, 'D-M-YY h:ma'),
            sortable: true,
            omit: !visibleCols.created_at
        },
        {
            name: 'Circuit ID',
            wrap: true,
            // selector: row => row?.get_circuit?.is_maintenance ? '-' : row?.get_circuit?.code,
            selector: row => row?.get_circuit?.code,
            sortable: true,
            omit: !visibleCols.circuit_id,
            width: '150px !important',
        },
        {
            name: 'Customer ID 1',
            wrap: true,
            selector: row => row?.get_circuit?.customer_id,
            sortable: true,
            omit: !visibleCols.customer_id,
            width: '150px !important',
        },
        {
            name: 'Customer ID 2',
            wrap: true,
            selector: row => row?.get_circuit?.customer_id_alt,
            sortable: true,
            omit: !visibleCols.customer_id_alt,
            width: '150px !important',
        },
        {
            name: 'Location A',
            wrap: true,
            selector: row => row?.get_circuit?.location_a,
            sortable: true,
            omit: !visibleCols.location_a
        },
        {
            name: 'Location A Addr.',
            wrap: true,
            selector: row => row?.get_circuit?.address_a,
            sortable: true,
            omit: !visibleCols.address_a
        },
        {
            name: 'Location B',
            wrap: true,
            selector: row => row?.get_circuit?.location_b,
            sortable: true,
            omit: !visibleCols.location_b
        },
        {
            name: 'Location B Addr.',
            wrap: true,
            selector: row => row?.get_circuit?.address_b,
            sortable: true,
            omit: !visibleCols.address_b
        },
        {
            name: 'Ofc. Distance',
            wrap: true,
            selector: row => row?.get_circuit?.ofc_distance || 'N/A',
            sortable: true,
            omit: !visibleCols.ofc_distance
        },

        {
            name: 'Fiber TL',
            wrap: true,
            selector: row => row?.get_fiber_tl?.name ?? '-',
            sortable: false,
            omit: !visibleCols.ftl
        },
        {
            name: 'Technician',
            wrap: true,
            selector: row => row?.get_technician?.name ?? '-',
            sortable: false,
            omit: !visibleCols.technician
        },
        {
            name: 'Problem',
            wrap: true,
            selector: row => row?.get_problem?.title ?? '-',
            sortable: true,
            omit: !visibleCols.problem
        },
        {
            name: '3rd Party Docket',
            wrap: true,
            selector: row => row?.third_party_docket_no,
            sortable: true,
            omit: !visibleCols.third_party_docket_no
        },
        {
            name: 'Hirer',
            wrap: true,
            selector: row => row?.get_hirer?.name,
            sortable: true,
            omit: !visibleCols.hirer,
            width: '150px !important',
        },
        {
            name: 'Status',
            wrap: true,
            selector: row => <div>

                {row.status === 'Scheduled' ? <span title={row.status === 'Scheduled' ? `Scheduled at ${formatDateTime(row?.scheduled_at, 'DD-MM-YYYY h:mm A')}` : row.status}>{row.status}</span> : row.status}
            </div>,
            sortable: true,
            omit: !visibleCols.status,
            width: '100px !important',
        },

        {
            name: 'Updated at',
            wrap: true,
            selector: row => formatDateTime(row?.updated_at, 'D-M-YY h:ma'),
            sortable: true,
            omit: !visibleCols.updated_at
        },
        {
            name: "Actions",
            button: true,
            width: "280px !important",
            omit: !visibleCols.actions,
            cell: (row) => (
                docketsIsLoading ? (
                    <DocketActionSkeleton />
                ) : (<div className='d-flex gap-1 '>

                    <>
                        <Link to={`/docket/${row.code}?docket=${row?.code}&circuit=${row?.get_circuit?.code}`} className='btn btn-primary pb-0 btn-style-light btn-sm' title='View'>
                            <span className="material-icons-sharp">
                                visibility
                            </span>
                        </Link>
                        <button className="btn btn-dark btn-style-light btn-sm pb-0" onClick={() => handleDocketInfoModal(row)} title={`View Info - ${row?.code}`} ><span className="material-icons-sharp">info</span></button>

                        {!maximizedRows[row.id] && <button className='btn btn-style-light  btn-secondary btn-sm pb-0' onClick={() => toggleMinimize(row.id)}>
                            <span className="material-icons-sharp">
                                {maximizedRows[row.id] ? 'arrow_forward_ios' : 'open_in_full'}
                            </span>
                        </button>}
                    </>


                    {maximizedRows[row.id] && <>


                        {row.status !== 'Cancelled' && <>

                            {row.status !== 'Closed' ? <>
                                {user?.permissions?.dockets?.change && <>

                                    {user?.roles?.includes('EXP') && <Link to={row.is_maintenance ? `/docket/maintenance/update/${row.code}` : `/docket/update/${row.code}`} className='btn btn-dark btn-sm btn-style-light pb-0' title='Edit Docket'>
                                        <span className="material-icons-sharp">
                                            mode_edit
                                        </span>
                                    </Link>}
                                    {user?.permissions?.dockets?.can_change_tl | user?.permissions?.dockets?.can_change_tech && row.status !== "Resolved" ? <button disabled={row.status === 'Scheduled'} className="btn btn-info btn-sm btn-style-light pb-0" onClick={() => { setDocData(row); setfiberTLChangeModal(true); }} title='Change Fiber Team Leader ' ><span className="material-icons-sharp">manage_accounts</span></button> : ''}
                                </>}

                                {user?.permissions?.dockets?.change && row.status !== "Resolved" && <button disabled={row.status === 'Scheduled'} className="btn btn-sm btn-warning pb-0 btn-style-light" onClick={() => { setDocketChangeModal(true); setCurrentDocketData(row) }} data-bs-toggle="tooltip" data-bs-placement="left" title="Change Status">
                                    <span className="material-icons-sharp">
                                        manage_history
                                    </span>
                                </button>}
                            </> : ''}
                            {/* {user?.permissions?.dockets?.change && <>
                            {row.status === "Resolved" && row.has_recent_docket.status ? <Link className="btn btn-sm btn-danger btn-style-light pb-0" to={`/docket/add?circuit=${row.circuit}`} title={`Docket Reopened until ${formatDateTime(row.has_recent_docket.opened_until, 'MMMM Do YYYY, h:mm a')}`}>
                                <span className="material-icons-sharp">restart_alt</span></Link> : ''}
                        </>} */}
                        </>}

                        {user?.roles?.includes('LC')  && <>
                            {row.status === 'Review' || row.status === 'Pending' ? <button className="btn btn-danger pb-0 btn-style-light" onClick={() => { setLCCancelDocket(true); setDocData(row) }} data-bs-toggle="tooltip" data-bs-placement="left" title="Cancel Docket" disabled={row.status === 'Scheduled'}>
                                <span className="material-icons-sharp">
                                    close
                                </span>
                            </button> : ''}
                        </>}
                    </>}
                </div>)
            )
        }
    ];

    // Conditionally Change Row Background Color
    const conditionalRowStyles = [
        {
            when: row => row.is_reopened && row.status === 'Resolved',
            style: {
                backgroundColor: '#04e0ce', // Cyan
                color: '#3d4040',
                '&:hover': {
                    cursor: 'pointer',
                },
            },
        },
        {
            when: row => row.status === 'Pending',
            style: {
                backgroundColor: '#f7f7f7', // White
                color: '#000000de',
                '&:hover': {
                    cursor: 'pointer',
                },
            },
        },
        {
            when: row => row.status === 'Hold',
            style: {
                backgroundColor: '#5656e7', // Blue
                color: '#fff',
                '&:hover': {
                    cursor: 'pointer',
                },
            },
        },
        {
            when: row => row.status === 'Resolved',
            style: {
                backgroundColor: '#4bad48f7', // Green
                color: '#fff',
                '&:hover': {
                    cursor: 'pointer',
                },
            },
        },
        {
            when: row => row.status === 'Cancelled',
            style: {
                backgroundColor: '#d75657', // Red
                color: '#e8eaed',
                '&:hover': {
                    cursor: 'pointer',
                },
            },
        },
        {
            when: row => row.status === 'Closed',
            style: {
                backgroundColor: '#4bad4840', // Light Green
                color: '#393939', // Light Gray
                '&:hover': {
                    cursor: 'pointer',
                },
            },
        },
    ];
    return (
        <div>
            <div className='docketsDesktop'>

                <DataTableBase
                    columns={columns}
                    data={docketsData?.data ?? docketSkeletonData}
                    direction="auto"
                    // fixedHeaderScrollHeight="300px"
                    pagination
                    responsive
                    subHeaderAlign="right"
                    subHeaderWrap
                    // progressPending={docketsIsLoading}
                    defaultPageSize={20}
                    conditionalRowStyles={conditionalRowStyles}
                />

            </div>
            <div className='docketsMobile'>
                <MobileDockets
                    data={docketsData?.data ?? docketSkeletonData}
                    user={user}
                    setDocData={setDocData}
                    docketsIsLoading={docketsIsLoading}
                    setCurrentDocketData={setCurrentDocketData}
                    handleDocketInfoModal={handleDocketInfoModal}
                    setDocketChangeModal={setDocketChangeModal}
                    setfiberTLChangeModal={setfiberTLChangeModal}
                    setBacktoPopModal={setBacktoPopModal}
                />
            </div>
        </div>
    )
}
