import { Link } from '@mui/material';
import React from 'react'
import { Helmet } from 'react-helmet';
import { useSearchParams } from 'react-router-dom';
function getFileName(fileUrl) {
    try {
        const url = new URL(fileUrl);
        const pathname = url.pathname;
        const fileName = pathname.substring(pathname.lastIndexOf('/') + 1);
        return fileName;
    } catch (error) {
        console.error('Invalid URL:', error);
        return 'Invalid File';
    }
}
export default function Reader() {

    const [searchParams] = useSearchParams();
    const fileUrl = searchParams.get('url');
    const [fileName, setFileName] = React.useState(getFileName(fileUrl));
    // const [fileName, setFileName] = useState('Unknown File');
    return (
        <div>
            <Helmet>
                <title>Meghbela Excel Reader - {fileName}</title>
            </Helmet>
            <div>
                <div className="d-flex justify-content-around align-items-center mt-4">
                    <div>
                        <Link to="/" className="" role='button'>Home</Link>
                    </div>

                    <div>
                        <h1 className='text-danger display-6 fw-bold text-center' style={{ fontSize: 12 }}>
                            Meghbela Excel Reader - {fileName}
                        </h1>
                    </div>
                    <div>
                        {/* Download button */}
                        <a href={fileUrl} target="_blank" rel="noopener noreferrer">Download</a>
                    </div>
                </div>

                <div style={{ marginTop: '20px' }}>
                    <iframe
                        src={`https://view.officeapps.live.com/op/embed.aspx?src=${fileUrl}`}
                        width="100%"
                        height="800px"
                        frameBorder="0">
                    </iframe>
                </div>
            </div>
        </div>
    )
}
