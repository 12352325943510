import React from 'react'
import TextField from '../../Common/Fields/TextField'
import { formatVehicleOptionsList, useVehiclesList } from '../../../hooks/core/vehicle';
import { Typography } from '@mui/material';
import SelectField from '../../Common/Fields/SelectField';
import DocketVehicleHoldTable from '../../Docket/DocketVehicleHoldTable';
import CheckboxField from '../../Common/Fields/CheckboxField';
import { useUser } from '../../../hooks/user';

export default function KmCalc({ formik, disabled, data }) {
    const { vehiclesData, vehiclesIsLoading } = useVehiclesList();
    const { user } = useUser();
    // console.log('formik data', formik.values)
    // console.log('main ', data)
    const renderTotalComp = (data) => {
        // console.log('renderTotalComp', data) 
        // var holdKmTotal = data.reduce((accum, item) => accum + parseInt(item?.get_vehicles?.vehicle1_total_km) + parseInt(item?.get_vehicles?.vehicle2_total_km), 0)
        var holdKmTotal = data.reduce((accum, item) => accum + parseInt(item?.get_vehicles?.vehicle1_total_km || 0) + 
             parseInt(item?.get_vehicles?.vehicle2_total_km || 0), 0);
        // console.log(holdKmTotal);
        // var holdKmTotal = 0
        return <div className='col-12 text-center'>
            <h6>Total Vehicles Utilized: {holdKmTotal} KM</h6>

            <h6 className='text-primary'>Vehicles Total Usage: {holdKmTotal + (formik?.values?.vehicle1_end_km - formik.values.vehicle1_start_km) + (formik.values.vehicle2_end_km - formik.values.vehicle2_start_km) + (formik.values.vehicle3_end_km - formik.values.vehicle3_start_km)} KM</h6>
        </div>
    }
    return (
        <div className="card">
            <div className="card-body">
                <div className="row">
                    <div className='col-12'>
                        <h3 className='text-center display-6 mb-4 text-primary' style={{ 'fontSize': '22px' }}>KM CALCULATOR</h3>
                    </div>
                    {/* {console.log(data)} */}
                    <DocketVehicleHoldTable data={data} />
                    <hr />
                    {data.docket_hold_data && renderTotalComp(data.docket_hold_data)}
                    <hr />

                </div>

                <div className='row mt-2'>
                    <div className="col-md-6 col-12 mb-2">
                        <Typography>Vehicle 1 <span className='req'>*</span></Typography>

                        <SelectField
                            name='vehicle1'
                            options={vehiclesData?.data.map(formatVehicleOptionsList)}
                            value={vehiclesData?.data.map(formatVehicleOptionsList).filter(function (option) {
                                return option.value === formik.values.vehicle1;
                            })}
                            onChange={(e) => formik.setFieldValue('vehicle1', e.value)}
                            isLoading={vehiclesIsLoading}
                            // isDisabled={user?.user_role !== 'EXP' }
                        />
                    </div>
                    <div className="col-md-2 col-4 mb-2 mt-2">
                        <TextField label='Start KM *' type="number" name='vehicle1_start_km' placeholder='Start KM' sx={{ mt: 2 }} disabled={!user?.roles?.includes('EXP')} />
                    </div>
                    <div className="col-md-2 col-4 mb-2 mt-2">
                        <TextField label='End KM *' type="number" name='vehicle1_end_km' placeholder='End KM' sx={{ mt: 2 }} />
                    </div>
                    <div className="col-md-2 col-4 mb-2 mt-2">
                        {formik.values.vehicle1 && <span className="badge text-white bg-primary mt-4">{formik.values.vehicle1_end_km - formik.values.vehicle1_start_km} KM</span>} 
                        <CheckboxField label='Back to POP' name='vehicle1_to_pop' options={['Back to POP']} />

                    </div>
                </div>
                <div className='row'>
                    <div className="col-md-6 col-12 mb-2">
                        <Typography>Vehicle 2</Typography>

                        <SelectField
                            name='vehicle2'
                            options={vehiclesData?.data.map(formatVehicleOptionsList)}
                            value={vehiclesData?.data.map(formatVehicleOptionsList).filter(function (option) {
                                return option.value === formik.values.vehicle2;
                            })}
                            onChange={(e) => formik.setFieldValue('vehicle2', e.value)}
                            isLoading={vehiclesIsLoading}
                            // isDisabled={user?.user_role !== 'EXP' }
                        />
                    </div>
                    <div className="col-md-2 col-4 mb-2 mt-2">
                        <TextField label='Start KM *' type="number" name='vehicle2_start_km' placeholder='Start KM' sx={{ mt: 2 }} disabled={!user?.roles?.includes('EXP')}/>
                    </div>
                    <div className="col-md-2 col-4 mb-2 mt-2">
                        <TextField label='End KM *' type="number" name='vehicle2_end_km' placeholder='End KM' sx={{ mt: 2 }} />
                    </div>
                    <div className="col-md-2 col-4 mb-2 mt-2">
                        {/* <span className="badge text-white bg-info mt-4">12 KM</span> */}
                        {formik.values.vehicle2 && <span className="badge text-white bg-primary mt-4">{formik.values.vehicle2_end_km - formik.values.vehicle2_start_km} KM</span>}
                        <CheckboxField label='Back to POP' name='vehicle2_to_pop' options={['Back to POP']} />
                    </div>
                </div>
                <div className='row'>
                    <div className="col-md-6 col-12 mb-2">
                        <Typography>Vehicle 3</Typography>

                        <SelectField
                            name='vehicle3'
                            options={vehiclesData?.data.map(formatVehicleOptionsList)}
                            value={vehiclesData?.data.map(formatVehicleOptionsList).filter(function (option) {
                                return option.value === formik.values.vehicle3;
                            })}
                            onChange={(e) => formik.setFieldValue('vehicle3', e.value)}
                            isLoading={vehiclesIsLoading}
                            // isDisabled={user?.user_role !== 'EXP'}
                        />
                    </div>
                    <div className="col-md-2 col-4 mb-2 mt-2">
                        <TextField label='Start KM *' type="number" name='vehicle3_start_km' placeholder='Start KM' disabled={!user?.roles?.includes('EXP')} sx={{ mt: 2 }} />
                    </div>
                    <div className="col-md-2 col-4 mb-2 mt-2">
                        <TextField label='End KM *' type="number" name='vehicle3_end_km' placeholder='End KM' sx={{ mt: 2 }} />
                    </div>
                    <div className="col-md-2 col-4 mb-2 mt-2">
                        {/* <span className="badge text-white bg-dark mt-4">12 KM</span> */}
                        {formik.values.vehicle3 && <span className="badge text-white bg-primary mt-4">{formik.values.vehicle3_end_km - formik.values.vehicle3_start_km} KM</span>}
                        <CheckboxField label='Back to POP' name='vehicle3_to_pop' options={['Back to POP']} />
                    </div>
                </div>

            </div>
        </div>

    )
}
