import React from 'react'
import Modal from 'react-modal'
import { toast } from 'react-toastify';
import { useUser } from '../../hooks/user';
import { useCircuitAssets, useCircuitAssetsList } from '../../hooks/circuit';
import Loader from '../Common/Loader';
import FileUploadForm from './CircuitAssetForm';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        maxWidth: '700px',
        width: '100%'
    },
    overlay: { zIndex: 2000 }
};
export default function CircuitAssets({ circuitData }) {
    const [modal, setModal] = React.useState(false);
    const [enableApi, setEnableApi] = React.useState(false);
    const { user } = useUser();
    const { circuitAssetsData, isCircuitAssetsLoading, filterCircuitAssets } = useCircuitAssetsList('', enableApi);


    const afterOpenModal = async () => {
        setEnableApi(true);
        filterCircuitAssets(`/${circuitData?.code}`);
    };

    const handleDownload = (item) => {
        toast.success('Download started. Please wait...');

        const fileUrl = item.get_file_path;
        const link = document.createElement('a');
        link.href = fileUrl;
        link.target = '_blank'; // Opens in a new tab
        link.rel = 'noopener noreferrer'; // Security best practice when using target="_blank"
        document.body.appendChild(link);
        link.click();
        link.remove();
    };

    return (
        <div>
            <button className="btn btn-info btn-style-light btn-sm" onClick={() => setModal(true)}>
                <i className="material-icons">cloud_upload</i> Circuit Assets</button>

            <Modal
                isOpen={modal}
                onAfterOpen={afterOpenModal}
                // onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Assets Modal"
            >
                <div className="container-fluid">

                    <div className="d-flex justify-content-between">
                        <h5 className="modal-title mb-3 text-center text-info">Circuit ({circuitData?.code}) Assets</h5>
                        <button type="button" className="btn-close shadow-none" data-bs-dismiss="modal" aria-label="Close" onClick={() => { setModal(false); }}></button>
                    </div>
                    <div className="modal-body" style={{ width: '100%' }}>
                        {['EXP', 'EX'].some(role => user?.roles?.includes(role)) && (
                            <div>
                                <FileUploadForm setModal={setModal} circuitData={circuitData} />
                                <hr />
                            </div>
                        )}
                        <div>
                            {!circuitAssetsData && isCircuitAssetsLoading && <div className="text-center"><Loader /></div>}
                            {circuitAssetsData?.data?.length > 0 && <div className="text-center text-sm">Total Assets: {circuitAssetsData?.data?.length}</div>}
                            <div className="container">
                                <div className="row">
                                    {circuitAssetsData?.success && circuitAssetsData?.data?.length > 0 ? (
                                        circuitAssetsData.data.map((item, index) => (
                                            <div key={index} className="col-6 col-md-4 col-lg-3 mb-4">
                                                <div className="card">
                                                    <img src={item?.get_file_path} alt={`Image ${item.id}`} className="card-img-top img-fluid" style={{ height: 100, width: 200 }} />
                                                    <div className="card-body d-flex justify-content-between align-items-center">
                                                        <p className="text-sm m-0 p-0">ID: {item.id}</p>
                                                        <button className="btn btn-danger btn-sm m-0 p-0" onClick={() => handleDownload(item)}>
                                                            <i className="material-symbols-outlined m-0 p-0">
                                                                download
                                                            </i>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    ) : null}
                                    {!circuitAssetsData?.success && <div className="text-center text-danger text-sm">{circuitAssetsData?.detail}</div>}
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </Modal>
        </div>
    )
}
