import React from 'react'
import MobileCircuits from '../../../components/MobileTables/MobileCircuits';
import DataTableBase from '../../../components/Common/DataTableBase';
import { formatDateTime } from '../../../utils/shortcuts';
import { Link } from 'react-router-dom';
import { ActionSkeleton, skeletonRows } from './SkeletonLoader';

export default function CircuitsTable({
    circuitsData,
    visibleCols,
    user,
    circuitsIsLoading,
    setDocketRequestData,
    setDocketRequestModal,
    setCurrentData,
    setCreateModal,
    setCorporateDocketModal
}) {

    // Set Table Data
    const columns = [
        {
            name: '# ID',
            wrap: true,
            selector: row => row.code,
            sortable: true,
        },
        {
            name: 'Customer ID 1',
            wrap: true,
            selector: row => row.customer_id,
            sortable: true,
            omit: !visibleCols.customer_id,
            style: {
                // textAlign: 'right',
                // color: 'red'
            }
        },
        {
            name: 'Customer ID 2',
            wrap: true,
            selector: row => row.customer_id_alt,
            sortable: true,
            omit: !visibleCols.customer_id_alt
        },
        {
            name: 'Circuit Type',
            wrap: true,
            selector: row => row.circuit_type,
            sortable: true,
            omit: !visibleCols.circuit_type
        },
        {
            name: 'Provider',
            wrap: true,
            selector: row => row.get_provider.title,
            sortable: false,
            omit: !visibleCols.provider
            // center: true,
            // width: 'auto',
        },
        {
            name: 'Location A',
            wrap: true,
            selector: row => row.location_a,
            omit: !visibleCols.location_a
        },
        {
            name: 'Address A',
            wrap: true,
            selector: row => row.address_a,
            omit: !visibleCols.address_a
        },
        {
            name: 'Pin A',
            wrap: true,
            selector: row => row.pin_a,
            omit: !visibleCols.pin_a
        },
        {
            name: 'Mobile A',
            wrap: true,
            selector: row => row.mobile_a,
            omit: !visibleCols.mobile_a
        },
        {
            name: 'Location B',
            wrap: true,
            selector: row => row.location_b,
            omit: !visibleCols.location_b
        },
        {
            name: 'Address B',
            wrap: true,
            selector: row => row.address_b,
            // cell: (row) => (row.address_b),
            omit: !visibleCols.address_b
        },
        {
            name: 'Pin B',
            wrap: true,
            selector: row => row.pin_b,
            omit: !visibleCols.pin_b
        },
        {
            name: 'Mobile B',
            wrap: true,
            selector: row => row.mobile_b,
            omit: !visibleCols.mobile_b
        },
        {
            name: 'Area A',
            wrap: true,
            selector: row => row.area_a,
            omit: !visibleCols.area_a
        },
        {
            name: 'Address',
            wrap: true,
            selector: row => row.address,
            omit: !visibleCols.address
        },
        {
            name: '3rd Party Circuit',
            wrap: true,
            selector: row => row.third_party_circuit_id,
            omit: !visibleCols.third_party_circuit_id
        },
        {
            name: 'Territory Incharge',
            wrap: true,
            selector: row => row?.get_ti?.account_name ?? 'N/A',
            omit: !visibleCols.territory_incharge
        },
        {
            name: 'Hub Incharge',
            wrap: true,
            selector: row => row?.get_hi?.account_name ?? 'N/A',
            omit: !visibleCols.hub_incharge
        },
        {
            name: 'Fiber TL',
            wrap: true,
            selector: row => row?.get_fiber_tl?.account_name ?? 'N/A',
            omit: !visibleCols.fiber_vendor_team_leader
        },
        {
            name: 'Type of Connectivity',
            wrap: true,
            selector: row => row.type_of_connectivity,
            omit: !visibleCols.type_of_connectivity
        },
        {
            name: 'Office Name',
            wrap: true,
            selector: row => row.office_name,
            omit: !visibleCols.office_name
        },
        {
            name: 'Customer Type',
            wrap: true,
            selector: row => row.customer_type,
            omit: !visibleCols.customer_type
        },
        {
            name: 'Hirer Name',
            wrap: true,
            selector: row => row.get_hirer.name,
            omit: !visibleCols.hirer
        },
        {
            name: 'OFC Distance',
            wrap: true,
            selector: row => row.ofc_distance || 'N/A',
            omit: !visibleCols.ofc_distance
        },
        {
            name: 'Dockets Count',
            wrap: true,
            selector: row => row.total_dockets,
            omit: !visibleCols.total_dockets
        },

        {
            name: 'Status',
            selector: row => row?.is_terminated ? 'Terminated' : row?.is_in_maintenance_mode ? 'In Maint. Mode' : row.status,
            sortable: true,
            omit: !visibleCols.status
        },
        {
            name: 'Created at',
            wrap: true,
            selector: row => formatDateTime(row.updated_at, 'D-M-YY h:ma'),
            omit: !visibleCols.created_at
        },
        {
            name: 'Last Updated',
            wrap: true,
            selector: row => formatDateTime(row.updated_at, 'D-M-YY h:ma'),
            omit: !visibleCols.updated_at
        },
        {
            name: "Actions",
            button: true,
            width: "300px",
            omit: !visibleCols.actions,
            cell: (row) => (
                circuitsIsLoading ? (
                    <ActionSkeleton />
                ) : (
                    <div className="d-flex gap-1">
                        <Link to={`/circuit/${row.id}`} className='btn btn-primary pb-0 btn-style-light btn-sm' title='View'>
                            <span className="material-icons-sharp">
                                visibility
                            </span>
                        </Link>

                        {user?.permissions?.circuits?.change && <Link to={`/circuit/update/${row.id}`} className='btn btn-dark pb-0 btn-style-light btn-sm' title='Update Circuit'>
                            <span className="material-icons-sharp">
                                mode_edit
                            </span>
                        </Link>}

                        {/* Advanced Docket */}
                        {user?.permissions?.dockets?.can_create_adv_docket && user?.roles?.includes('EXP') && !row.is_terminated && <>
                            {row?.has_recent_docket?.status ? <Link className="btn btn-danger pb-0 btn-style-light btn-sm" to={`/docket/add?circuit=${row.id}`} title={`Docket Reopened until ${formatDateTime(row.has_recent_docket.opened_until, 'MMMM Do YYYY, h:mm a')}`}>
                                <span className="material-icons-sharp">restart_alt</span></Link> : row.status === 'Active' || row.status === 'Under Termination' ? <Link to={`/docket/add?circuit=${row.id}`} className='btn btn-danger pb-0 btn-style-light btn-sm' title='New Docket'>
                                    <span className="material-icons-sharp">
                                        flag
                                    </span>
                                </Link> : ''}
                        </>}

                        {/* Normail Docket */}
                        {user?.permissions?.dockets?.can_create_docket && row.status === 'Active' && !row.is_terminated && row?.circuit_type !== 'Underground' && row?.circuit_type !== 'Leased Line' && row?.circuit_type !== 'WAN' && <button onClick={() => { setCurrentData(row); setCreateModal(true); }} className='btn btn-danger pb-0 btn-style-light btn-sm' title='Create New Docket'>
                            <span className="material-icons-sharp">
                                push_pin
                            </span>
                        </button>}

                        {/* {user?.permissions?.dockets?.can_create_docket && row.status === 'Active' && !row?.active_docket?.status && row?.circuit_type !== 'Underground' && row?.circuit_type !== 'Leased Line' && row?.circuit_type !== 'WAN' && <button onClick={() => { setCurrentData(row); setCreateModal(true); }} className='btn btn-danger pb-0 btn-style-light btn-sm' title='Create New Docket'>
                            <span className="material-icons-sharp">
                                push_pin
                            </span>
                        </button>} */}

                        {/* Corporate Docket */}
                        {user?.permissions?.dockets?.can_create_corp_docket && !row.is_terminated && row.status === 'Active' && row?.circuit_type === 'Corporate' && <button onClick={() => { setCurrentData(row); setCorporateDocketModal(true); }} className='btn btn-danger pb-0 btn-style-light btn-sm' title='New Corporate Docket'>
                            <span className="material-icons-sharp">
                                back_hand
                            </span>
                        </button>}

                        {/* Docket Request */}
                        {user?.permissions?.dockets?.can_request_docket && !row.is_under_maintenance && row.status === 'Active' && !row.is_terminated && <button onClick={() => { setDocketRequestData(row); setDocketRequestModal(true); }} className='btn btn-warning pb-0 btn-style-light  btn-sm' title='Docket Request'>
                            <span className="material-icons-sharp">
                                waving_hand
                            </span>
                        </button>}
                    </div>
                )
            )
        }
    ];


    // Conditionally Change Row Background Color
    const conditionalRowStyles = [
        {
            when: row => row.status === 'Active',
            style: {
                backgroundColor: '#fff',
                color: '#000000de',
                '&:hover': {
                    cursor: 'pointer',
                },
            },
        },
        {
            when: row => row.status === 'Deactive',
            style: {
                backgroundColor: '#f4f427',
                color: '#686767',
                '&:hover': {
                    cursor: 'pointer',
                },
            },
        },
        {
            when: row => row.is_under_termination,
            style: {
                backgroundColor: '#fe8e8e',
                color: '#fff',
                '&:hover': {
                    cursor: 'pointer',
                },
            },
        },
        {
            when: row => row.is_under_maintenance,
            style: {
                backgroundColor: '#e7ca00bf',
                color: '#2e2d2d',
                '&:hover': {
                    cursor: 'pointer',
                },
            },
        },
        {
            when: row => row.is_terminated,
            style: {
                backgroundColor: '#fe5152',
                color: '#e8eaed',
                '&:hover': {
                    cursor: 'pointer',
                },
            },
        },
    ];
    return (
        <div>


            <div className='circuitsMobile'>
                <MobileCircuits
                    data={circuitsData?.data ?? skeletonRows}
                    user={user}
                    setDocketRequestData={setDocketRequestData}
                    setDocketRequestModal={setDocketRequestModal}
                    setCurrentData={setCurrentData}
                    setCreateModal={setCreateModal}
                    setCorporateDocketModal={setCorporateDocketModal}
                    circuitsIsLoading={circuitsIsLoading}
                />
            </div>

            <div className='circuitsDesktop'>
                <DataTableBase
                    columns={columns}
                    data={circuitsData?.data ?? skeletonRows}
                    direction="auto"
                    // fixedHeaderScrollHeight="300px"
                    pagination
                    responsive
                    subHeaderAlign="right"
                    subHeaderWrap
                    // progressPending={circuitsIsLoading}
                    // paginationRowsPerPageOptions={[30, 100, 200, 500, 1000]}
                    // defaultPageSize={30}
                    conditionalRowStyles={conditionalRowStyles}
                // hiddenColumns
                />

            </div>


        </div>
    )
}
