/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext } from 'react'
import { useLogout, useUser } from '../../hooks/user';
import { NavLink, useNavigate, useLocation, Link } from 'react-router-dom';
import coreContext from '../../context/core/coreContext';
import ShiftRoasterModal from '../ShiftRoasters/ShiftRoasterModal';
// import CircuitFinderModal from '../Models/CircuitFinderModal';

export default function AppMenu({ setDocketCreateModal, setDocketCloseModal, setMyAccountModal, setPwdChangeModal, setSendCustomSMSModal }) {
    const { showMobileMenu, actions: { toggleMobileMenu } } = useContext(coreContext);
    let navigate = useNavigate();
    let location = useLocation();
    // const basePath = location.pathname.split("/", 2).slice(1).toString()
    const basePath = location.pathname.split('/').slice(-1)[0];

    const { user, isLoading } = useUser();
    // console.log('user -> ', user)

    const [shiftRoastersModal, setShiftRoastersModal] = React.useState(false);

    // Handling Logout
    const logoutAccount = useLogout()
    const handleLogout = async (e) => {
        e.preventDefault();

        const is_logged_out = await logoutAccount();
        if (is_logged_out) {
            navigate(`/account/login`);
        }
    }

    // const renderSuperAdminMenu = (data) => {
    //     const { permissions } = data;
    //     if (permissions.users.view) {
    //         return <>
    //             <li>
    //                 <Link to='/admin/users'>Users Mangement</Link>
    //             </li>
    //         </>
    //     } else {
    //         return <></>
    //     }

    // }
    // console.log(user)
    return (
        <div className={`app-menu ${showMobileMenu && 'showMenuresponsive'}`}>
            <div className="container">
                <div className='text-end'>
                    <button className='btn btn-primary btn-style-light pe-2 py-2 pl-1 d-lg-none me-2 closeMenuBtn' onClick={toggleMobileMenu}><i className="fas fa-times"></i></button>
                </div>
                <ul className="menu-list">
                    <li className={basePath.match(" ") && 'active-page'}>
                        <NavLink to='/' className={({ isActive }) => isActive ? 'active' : ''}>Dashboard</NavLink>
                    </li>
                    {user?.permissions?.circuits?.view && <li className={basePath.match("circuit") && 'active-page'}>
                        <a href="#" onClick={() => navigate(`/circuit`)}>Circuit<i className="material-icons has-sub-menu">keyboard_arrow_down</i></a>
                        <ul className="sub-menu">
                            <li>
                                <NavLink to='/circuit' className={({ isActive }) => isActive ? 'active' : ''}>List Circuits
                                    {/* <span className="badge rounded-pill badge-info float-end">87</span> */}
                                </NavLink>
                            </li>
                            {user?.permissions?.circuits?.add && <li>
                                <NavLink to='/circuit/add' className={({ isActive }) => isActive ? 'active' : ''}>New Circuit</NavLink>
                            </li>}

                        </ul>
                    </li>}
                    {user?.permissions?.dockets?.view && <li className={basePath.match("docket") && 'active-page'}>
                        <a href="#" onClick={() => navigate(`/docket`)}>Docket<i className="material-icons has-sub-menu">keyboard_arrow_down</i></a>
                        <ul className="sub-menu">
                            <li>
                                <NavLink to='/docket' className={({ isActive }) => isActive ? 'active' : ''}>List Dockets
                                    {/* <span className="badge rounded-pill badge-info float-end">87</span> */}
                                </NavLink>
                            </li>

                            {user?.permissions?.dockets?.add && <li>
                                <a onClick={() => setDocketCreateModal(true)} className='pointer'>New Docket</a>
                            </li>}
                            {user?.permissions?.dockets?.change && <li>
                                <a onClick={() => setDocketCloseModal(true)} className='pointer'>Close Docket</a>
                            </li>}

                            {user?.permissions?.dockets?.change && ['EXP'].some(role => user?.roles?.includes(role)) && <>
                                {/* <li>
                                    <NavLink to='/docket/old' className={({ isActive }) => isActive ? 'active' : ''}>Dockets (Old)</NavLink>
                                </li> */}
                                <li>
                                    {/* <a onClick={() => setDocketCloseModal(true)} className='pointer'>Close Docket</a> */}
                                    <NavLink to='/docket/update/requests' className={({ isActive }) => isActive ? 'active' : ''}>Hold/Close Reqs</NavLink>
                                </li>
                                {/* <li>
                                    <NavLink to='/docket/requests' className={({ isActive }) => isActive ? 'active' : ''}>Docket Requests</NavLink>
                                </li> */}
                                <li>
                                    <NavLink to='/docket/updates' className={({ isActive }) => isActive ? 'active' : ''}>All Notifications</NavLink>
                                </li>


                            </>}
                            {user?.permissions?.dockets?.can_accept_docket && <li>
                                <NavLink to='/docket/under-review' className={({ isActive }) => isActive ? 'active' : ''}>Under Review</NavLink>
                            </li>}
                            {['EXP'].some(role => user?.roles?.includes(role)) && <li>
                                <NavLink to='/docket/requests' className={({ isActive }) => isActive ? 'active' : ''}>Docket Requests</NavLink>
                            </li>}

                        </ul>
                    </li>}
                    {user?.permissions?.dockets?.can_view_corp_docket && <li className={basePath.match("corporate") && 'active-page'}>
                        <a href="#" onClick={() => navigate(`/docket/corporate`)}>{user?.permissions?.dockets?.view ? 'Corp. Docket' : 'Docket'}<i className="material-icons has-sub-menu">keyboard_arrow_down</i></a>
                        <ul className="sub-menu">
                            <li>
                                <NavLink to='/docket/corporate' className={({ isActive }) => isActive ? 'active' : ''}>List {user?.permissions?.dockets?.view ? 'Corp. Dockets' : 'Dockets'}
                                    {/* <span className="badge rounded-pill badge-info float-end">87</span> */}
                                </NavLink>
                            </li>
                            {user?.permissions?.dockets?.can_view_corporate_notification && <li>
                                <NavLink to='/docket/corporate/notifications' className={({ isActive }) => isActive ? 'active' : ''}>Notifications</NavLink>
                            </li>}
                        </ul>
                    </li>}

                    {(user?.permissions?.dockets?.can_export_km_report || user?.permissions?.dockets?.can_export_material_report) && <li className={basePath.match("reports") && 'active-page'}>
                        <a href="#">Reports<i className="material-icons has-sub-menu">keyboard_arrow_down</i></a>
                        <ul className="sub-menu">
                            {user?.permissions?.dockets?.can_export_km_report && <li>
                                <NavLink to='/reports/vehicle/km-report' className={({ isActive }) => isActive ? 'active' : ''}>Vehicles Km Report  </NavLink>
                            </li>}
                            {user?.permissions?.dockets?.can_export_material_report && <li>
                                <NavLink to='/reports/material/used-report' className={({ isActive }) => isActive ? 'active' : ''}>Materials Report</NavLink>
                            </li>}
                        </ul>
                    </li>}


                    {user?.permissions?.account?.is_admin && <li>
                        <a onClick={() => setSendCustomSMSModal(true)}>Send SMS</a>
                    </li>}
                    <li>
                        <a href="#">{user.account_name}<i className="material-icons has-sub-menu">keyboard_arrow_down</i></a>
                        <ul className="sub-menu">
                            <li>
                                <a onClick={() => setMyAccountModal(true)} className='pointer'>My Account</a>
                            </li>
                            <li>
                                <a onClick={() => setPwdChangeModal(true)} className='pointer'>Change Password</a>
                            </li>
                            <hr className='m-0' />
                            {['EXP', 'FTL', 'FT'].some(role => user?.roles?.includes(role)) && (
                                <li>
                                    <a onClick={() => setShiftRoastersModal(true)} className='pointer'>Shift Roasters <span className="badge bg-primary">New</span></a>
                                    {shiftRoastersModal && <ShiftRoasterModal modal={shiftRoastersModal} setModal={setShiftRoastersModal} />}
                                </li>
                            )}
                            {/* {user && !isLoading && renderSuperAdminMenu(user)} */}
                            <hr className='m-0' />
                            <li>
                                <a href='#' onClick={handleLogout}>Logout</a>
                            </li>
                        </ul>
                    </li>
                </ul>

                {/* Modals */}


            </div>
        </div>
    )
}
