import React from 'react';
import { Route, Routes } from 'react-router-dom';
// import Layout from '../components/Layout';
import ProtectedRoutes from './ProtectedRoutes';
import HomePage from '../pages/home';
import LoginPage from '../pages/account/login';
import CircuitListPage from '../pages/circuit/list';
import CircuitAddPage from '../pages/circuit/add';
import DocketListPage from '../pages/docket/list';
import DocketAddPage from '../pages/docket/add';
import PageNotFoundPage from '../pages/404';
import CircuitDetailPage from '../pages/circuit/detail';
import CircuitUpdatePage from '../pages/circuit/update';
import DocketDetailPage from '../pages/docket/detail';
import DocketUpdatePage from '../pages/docket/update';
import CloseDocketPage from '../pages/docket/close';
import UsersListPage from '../pages/admin/users/list';
import UserDetailPage from '../pages/admin/users/detail';
import UserAddPage from '../pages/admin/users/add';
import MaintenanceDocketAddPage from '../pages/docket/maintenance/add';
import MaintenanceDocketUpdatePage from '../pages/docket/maintenance/update';
import ForgotPasswordPage from '../pages/account/forgot';
import LCOLoginPage from '../pages/lco/login';
import LCODashboardPage from '../pages/lco/dashboard';
import LCOCircuitDetail from '../components/LCO/CircuitDetail';
import DocketMaterialUpdate from '../pages/docket/material_update';
import DocketRequestList from '../pages/docket/requests/list';
import AccountUpdatePage from '../pages/account/update';
import DocketUpdateList from '../pages/docket/updates/list';
import DocketUpdateRequestList from '../pages/docket/update-requests/list';
import DocketCloseRequestPage from '../pages/docket/update-requests/close-request';
import UnderReviewListPage from '../pages/docket/under-review/list';
import CorporateDocketList from '../pages/docket/corporate/list';
import CorporateDocketDetail from '../pages/docket/corporate/detail';
import CorporateDocketAddPage from '../pages/docket/corporate/add';
import CloseCorporateDocketPage from '../pages/docket/corporate/close';
import CorporateDocketNotificationsList from '../pages/docket/corporate/notifications/list';
// import DocketListPageOld from '../pages/docket/old_list';
import VehicleKmReportPage from '../pages/reports/vehicles';
import MaterialsUsedReportPage from '../pages/reports/materials';
import Reader from '../pages/tools/xlxs/Reader';


export default function AppRoutes() {
    return (
        <Routes>
            <Route path='/' element={<ProtectedRoutes />}>
                <Route index element={<HomePage />} />

                <Route path='/circuit' element={<CircuitListPage />} />
                <Route path='/circuit/add' element={<CircuitAddPage />} />
                <Route path='/circuit/update/:id' element={<CircuitUpdatePage />} />
                <Route path='/circuit/:id' element={<CircuitDetailPage />} />

                {/* // CORPORATE DOCKET */}
                {/* <Route path='/docket/corporate/add' element={<CreateCorporateDocket />} /> */}
                <Route path='/docket/corporate' element={<CorporateDocketList />} />
                <Route path='/docket/corporate/add' element={<CorporateDocketAddPage />} />
                <Route path='/docket/corporate/notifications' element={<CorporateDocketNotificationsList />} />
                <Route path='/docket/corporate/close/:id' element={<CloseCorporateDocketPage />} />
                <Route path='/docket/corporate/:id' element={<CorporateDocketDetail />} />

                <Route path='/docket' element={<DocketListPage />} />
                {/* <Route path='/docket/old' element={<DocketListPageOld />} /> */}
                <Route path='/docket/requests' element={<DocketRequestList />} />
                <Route path='/docket/update/requests' element={<DocketUpdateRequestList />} />
                <Route path='/docket/updates' element={<DocketUpdateList />} />
                <Route path='/docket/add' element={<DocketAddPage />} />
                <Route path='/docket/under-review' element={<UnderReviewListPage />} />
                <Route path='/docket/close/:id' element={<CloseDocketPage />} />
                <Route path='/docket/request/close/:id' element={<DocketCloseRequestPage />} />
                <Route path='/docket/update/:id' element={<DocketUpdatePage />} />
                <Route path='/docket/update/material/:id' element={<DocketMaterialUpdate />} />
                <Route path='/docket/maintenance/add' element={<MaintenanceDocketAddPage />} />
                <Route path='/docket/maintenance/update/:id' element={<MaintenanceDocketUpdatePage />} />
                <Route path='/docket/:id' element={<DocketDetailPage />} />

                {/* REPORTS */}
                <Route path='/reports/vehicle/km-report' element={<VehicleKmReportPage />} />
                <Route path='/reports/material/used-report' element={<MaterialsUsedReportPage />} />
                


                <Route path='/lco/dashboard' element={<LCODashboardPage />} />
                <Route path='/lco/circuit/:code' element={<LCOCircuitDetail />} />
                {/* <Route path='/coupon/category/list' element={<CouponCategoryPage />} />
                <Route path='/location/group/list' element={<LocationGroupsPage />} />
                <Route path='/location/group/logic/:id' element={<LocationGroupLogicPage />} /> */}
               
                <Route path='/account/me' element={<AccountUpdatePage />} />
                <Route path='/admin/users' element={<UsersListPage />} />
                <Route path='/admin/users/add' element={<UserAddPage />} />
                <Route path='/admin/users/:id' element={<UserDetailPage />} />
            </Route>


            <Route>
                <Route path='/account/login' element={<LoginPage />} />
                <Route path='/account/forgot' element={<ForgotPasswordPage />} />

                <Route path='/lco/login' element={<LCOLoginPage />} />
            </Route>
            <Route>
                <Route path='/tools/xlsx' element={<Reader />} />
            </Route>
            <Route path='*' element={<PageNotFoundPage />} />
        </Routes>
    )
}
