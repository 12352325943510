import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { toast } from 'react-toastify';


const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
const fileExtension = ".xlsx";

export const exportToCSV = (apiData, fileName) => {
    if (!apiData || !Array.isArray(apiData) || apiData.length === 0) {
        console.error("Invalid export data");
        toast.error("The export data is invalid.");
        return;
    }

    const ws = XLSX.utils.json_to_sheet(apiData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
};