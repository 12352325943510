import React, { useEffect, useState } from 'react';
import { useDocketRequestListAdmin } from '../../../hooks/lco/dockets';
import DataTableBase from '../../../components/Common/DataTableBase';
import { formatDateTime } from '../../../utils/shortcuts';
import { Link, useSearchParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useUser } from '../../../hooks/user';
import PageBreadcrumbs from '../../../components/Common/Breadcrumbs';
import DocketRequestsFilterModal from '../../../components/Models/DocketRequestsFilterModal';
import Alert from '../../../components/Common/Alert';
import DocketRequestModal from '../../../components/Models/DocketRequestModal';
import moment from 'moment';
import { useDebouncedCallback } from 'use-debounce';

export default function DocketRequestList() {
    const { user } = useUser();
    const [enableApi, setAPI] = useState(false)
    const { docketRequestsData, docketRequestsIsLoading, filterRequestsList } = useDocketRequestListAdmin('', enableApi);
    const [requestsFilterModal, setRequestsFilterModal] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    // console.log(docketRequestsData)
    // Handling Docket Requests
    const [docketReqModal, setDocketReqModal] = useState(false)
    const [currentDocketReqData, setCurrentDocketReqData] = useState(null)

    const columns = [
        {
            name: 'ID',
            width: '60px !important',
            selector: row => row.id,
            sortable: true,
        },
        {
            name: 'Circuit',
            width: '150px !important',
            cell: (row) => (<>
                {row?.get_circuit ? <Link to={`/circuit/${row?.get_circuit?.id}`}>{row?.get_circuit?.code}</Link> : 'N/a'}
            </>)
        },
        {
            name: 'Problem',
            width: '250px !important',
            selector: row => row?.get_problem ? row?.get_problem?.title : row?.get_maint_problem?.title,
        },
        {
            name: 'Requested by',
            width: '200px !important',
            selector: row => `${row.get_user.name} (${row?.get_user?.roles?.join(', ')})`,
        },
        {
            name: 'Customer ID',
            width: '100px !important',
            selector: row => `${row?.get_circuit?.customer_id || '-'}`,
        },
        {
            name: 'Hirer Name',
            width: '100px !important',
            selector: row => `${row?.get_circuit?.get_hirer?.name || '-'}`,
        },
        {
            name: 'Status',
            width: '100px !important',
            selector: row => row.status,
            sortable: true,
        },
        {
            name: 'Type',
            // selector: row => row.status,
            width: '100px !important',
            cell: (row) => (<>
                {row?.is_maint_docket ? <p className='text-danger'>Maint.</p> : <p className='text-primary'>Norm.</p>}
            </>),
            sortable: true,
        },
        {
            name: 'Updated by',
            width: '200px !important',
            sortable: true,
            selector: row => row?.get_updated_by?.name ?? 'N/A',
        },
        {
            name: 'Req at',
            width: '150px !important',
            sortable: true,
            selector: row => formatDateTime(row.created_at, 'D-M-YY h:ma'),
        },
        {
            name: 'Updated',
            width: '150px !important',
            sortable: true,
            selector: row => formatDateTime(row.updated_at, 'D-M-YY h:ma'),
        },
        {
            name: 'Actions',
            width: '100%',
            sortable: true,
            selector: (row) => user?.roles?.includes('EXP') ? <div className='row d-flex align-items-center'>
                <div className='col-12 '>
                    <button className="btn btn-info btn-style-light pb-0" onClick={() => {
                        setCurrentDocketReqData(row)
                        setDocketReqModal(true)
                    }} title='Docket Actions' ><span className="material-icons-sharp">info</span></button>
                </div>

                <div className='col-12'>

                    {!row.is_maint_docket && moment().tz('Asia/Kolkata').diff(row.created_at, 'hours') <= 2 && row.status !== 'Cancel' && !row?.get_circuit?.has_recent_docket?.status && !row?.get_circuit?.active_docket?.status ?

                        <code >Accepted but docket not found! </code>
                        : ''}
                </div>
            </div> : '-'
            ,
        },
    ];
    const conditionalRowStyles = [
        {
            when: row => row.status === 'Accept',
            style: {
                backgroundColor: '#70d9106b',
                color: '#000000de',
                '&:hover': {
                    cursor: 'pointer',
                },
            },
        },
        {
            when: row => row.status === 'Pending',
            style: {
                backgroundColor: 'rgba(244, 244, 39, 0.49)',
                color: 'rgb(66, 65, 65)',
                '&:hover': {
                    cursor: 'pointer',
                },
            },
        },
        {
            when: row => row.status === 'Cancel',
            style: {
                backgroundColor: '#fe8e8e',
                color: '#fff',
                '&:hover': {
                    cursor: 'pointer',
                },
            },
        },

    ];

    // // Filter Data 
    // useEffect(() => {
    //     const currentParams = Object.fromEntries([...searchParams]);
    //     let params = ''
    //     params = new URLSearchParams(currentParams).toString() || 'status=Pending&limit=20';
    //     params && filterRequestsList(`?${params}`)
    // }, [searchParams])

    // Fetch Data
    const fetchData = useDebouncedCallback(() => {
        const currentParams = Object.fromEntries([...searchParams]);
        let params = ''
        const limit = parseInt(currentParams?.limit ?? '10');
        const status = currentParams?.status ?? 'Pending';
        params = new URLSearchParams({ ...currentParams, limit, status }).toString();
        setAPI(true)
        params && filterRequestsList(`?${params}`)
    }, 300)

    useEffect(() => {
        fetchData()

    }, [searchParams])
    return (
        <>
            <Helmet>
                <title>Docket Requests - List</title>
            </Helmet>
            <PageBreadcrumbs
                title='All Docket Requests'
                alt='Docket Requests'
                RightComp={() =>
                    <div className='d-flex gap-1'>
                        {user?.permissions?.dockets?.change && <button className="btn btn-dark btn-style-light" onClick={() => setRequestsFilterModal(true)}><i className="material-icons">filter_alt</i>Filter</button>}
                    </div>
                }
            />
            <div className='col-md-12'>
                {user?.permissions?.dockets?.change ? <DataTableBase
                    columns={columns}
                    data={docketRequestsData?.data}
                    direction="auto"
                    fixedHeaderScrollHeight="300px"
                    pagination
                    responsive
                    subHeaderAlign="right"
                    subHeaderWrap
                    progressPending={docketRequestsIsLoading}
                    defaultPageSize={10}
                    conditionalRowStyles={conditionalRowStyles}
                /> :
                    <Alert
                        title='Error!'
                        text='Permission Denied!'
                        type='danger'
                    />
                }
            </div>

            <DocketRequestsFilterModal
                modal={requestsFilterModal}
                setModal={setRequestsFilterModal}
                searchParams={searchParams}
                setSearchParams={setSearchParams}
            />

            {docketReqModal && <DocketRequestModal
                modal={docketReqModal}
                setModal={setDocketReqModal}
                data={currentDocketReqData}
                setCurrentDocketReqData={setCurrentDocketReqData}

            />}
        </>
    )
}
