import { Container, Grid } from '@mui/material';
import React, { useState } from 'react'
import { Helmet } from 'react-helmet';
import { Link, useParams } from 'react-router-dom';
import PageBreadcrumbs from '../../components/Common/Breadcrumbs';
import Loader from '../../components/Common/Loader';
import DocketInformation from '../../components/Docket/Information';
import DocketChangeModal from '../../components/Models/DocketChangeModal';
import { useDocketDetail } from '../../hooks/docket';
import { formatDateTime } from '../../utils/shortcuts';
import { useUser } from '../../hooks/user';
import FiberTLChangeModal from '../../components/Models/FiberTLChangeModal';
import DocketHoldRequestModal from '../../components/Models/DocketHoldRequestModal';
import DocketResumeRequestModal from '../../components/Models/DocketResumeRequestModal';
import DocketCancelRequestModal from '../../components/Models/DocketCancelRequestModal';
import { toast } from 'react-toastify';
import { useCustomSMS } from '../../hooks/core/sms';
// import LCOCircuitInformation from '../../components/LCO/CircuitInformation';
import DocketCancelModal from '../../components/LCO/DocketCancelModal';


export default function DocketDetailPage() {
    const { user } = useUser();
    const { id } = useParams();
    const [lcDocketCancelModal, setLCCancelDocket] = useState(false);
    const [docketChangeModal, setDocketChangeModal] = useState(false);
    const [fiberTLChangeModal, setfiberTLChangeModal] = useState(false);
    const [holdReqModal, setHoldReqModal] = useState(false);
    const [holdResumeModal, setResumeReqModal] = useState(false);
    const [docketCancelReqModal, setReqCancelModal] = useState(false);
    const [historyTab, setHistoryTab] = useState(false);
    const [currentDocketData, setCurrentDocketData] = useState({});
    const { docketData, docketIsLoading } = useDocketDetail(id);
    const { handleCustomSms, isSendingSms } = useCustomSMS()
    // console.log(docketData)
    // console.log('docket data -> ', docketData);
    const renderDocketInformation = (data) => {
        // console.log(data)
        if (data.success) {
            return <DocketInformation data={data.data} historyTab={historyTab} setHistoryTab={setHistoryTab} />

        } else {
            return <div className='alert alert-warning' role='alert'>
                Something wents wrong! <br /> <Link to='/docket' className='btn btn-sm btn-dark mt-2'>Back to Dockets</Link>
            </div>
        }
    }

    const sendLocalConfirmationSMS = async (data) => {

        const smsTemplate = `Dear ${data?.docketed_by ?? 'Customer'} Docket No :${data?.code} Please check & confirm the present link's status via portal or call 033-40291140. Same will be auto closed after 1 hourMeghbela Broadband.`

        if (data?.docketed_by_contact) {
            const resp = await handleCustomSms({
                mobile: data?.docketed_by_contact,
                text: smsTemplate,
                content_id: '1707170322423985039'
            });
            if (resp.success) {
                toast.info('SMS has been sent to LCO.')
            } else {
                toast.error(resp.detail)
            }

        } else {
            toast.error('LCO number not found.')
        }

    }
    return (
        <>
            <Helmet>
                <title>Dockets - {`${docketData?.data?.code ?? 'Loading...'} ${docketData?.data?.is_maintenance ? 'Maintenance Docket' : ''} Information`}</title>
            </Helmet>
            <Container maxWidth="xxl" disableGutters>
                <PageBreadcrumbs
                    title={docketData?.data?.code ?? 'Loading...'}
                    history={[{ name: 'Docket', to: '/docket' }]}
                    RightComp={() => {
                        if (false) {
                            return <code>Limited Action</code>
                        } else {
                            return docketData?.data && docketData?.success && !docketIsLoading && <div className='d-flex gap-1'>

                                {docketData?.data?.status !== 'Closed' && <>
                                    {['EXP'].some(role => user?.roles?.includes(role)) && <Link to={docketData?.data?.is_maintenance ? `/docket/maintenance/update/${docketData?.data?.code}` : `/docket/update/${docketData?.data?.code}`} className="btn btn-secondary btn-style-light btn-sm" title='Edit'><i className="material-icons">edit</i> </Link>}

                                </>}
                                {docketData?.data?.status === 'Resolved' && ['EXP'].some(role => user?.roles?.includes(role)) && <>
                                    <Link to={`/docket/close/${docketData?.data?.code}`} className="btn btn-secondary btn-style-light btn-sm" title="Closure Update"><i className="material-icons">move_up</i> Closure Update</Link>

                                </>}
                                {docketData?.data?.status === 'Pending' | docketData?.data?.status === 'Hold' ? <>
                                    {user?.permissions?.dockets?.can_close_docket && <Link to={`/docket/close/${docketData?.data?.code}`} className="btn btn-success btn-style-light btn-sm" title='Close Docket'><i className="material-icons">done_all</i></Link>}
                                    {/* REQUEST BUTTONS START */}
                                    {user?.permissions?.dockets?.can_req_close && <Link to={`/docket/request/close/${docketData?.data?.code}`} className="btn btn-warning btn-style-light btn-sm" title='Close Request'><i className="material-icons">stop</i></Link>}

                                    {user?.permissions?.dockets?.can_req_hold && docketData?.data?.status !== 'Hold' && <button className="btn btn-warning btn-style-light btn-sm" onClick={() => { setHoldReqModal(true); setCurrentDocketData(docketData?.data) }} title='Hold Request'><i className="material-icons">pause</i></button>}

                                    {user?.permissions?.dockets?.can_req_hold && docketData?.data?.status === 'Hold' && <button className="btn btn-success btn-style-light btn-sm" onClick={() => { setResumeReqModal(true); setCurrentDocketData(docketData?.data) }} title='Resume Request'><i className="material-icons">play_arrow</i></button>}

                                    {user?.permissions?.dockets?.can_req_hold && docketData?.data?.status !== 'Hold' && <button className="btn btn-danger btn-style-light btn-sm" onClick={() => { setReqCancelModal(true); setCurrentDocketData(docketData?.data) }} title='Cancel Request'><i className="material-icons">block</i></button>}
                                    {/* REQUEST BUTTONS END */}
                                    {user?.permissions?.dockets?.can_view_status_button && <button className="btn btn-warning btn-sm" title='Change Status' onClick={() => { setDocketChangeModal(true); setCurrentDocketData(docketData?.data) }}>
                                        <i className="material-icons">manage_history</i></button>}

                                    {user?.permissions?.dockets?.can_change_tl | user?.permissions?.dockets?.can_change_tech ? <button className="btn btn-info btn-sm" title='Change FTL' onClick={() => { setfiberTLChangeModal(true); }}>
                                        <i className="material-icons">settings_accessibility</i></button> : ''}

                                    {user?.permissions?.dockets?.can_send_lc_conf_sms ? <button className="btn btn-info btn-sm" title='Send Conf. SMS' disabled={isSendingSms} onClick={() => { sendLocalConfirmationSMS(docketData?.data); }}>
                                        <i className="material-icons">sms</i></button> : ''}
                                </> : ''}

                                {/* LCO */}
                                {['LC'].some(role => user?.roles?.includes(role)) && <>
                                    {docketData?.data?.status === 'Review' || docketData?.data?.status === 'Pending' ? <button className="btn btn-danger pb-0 btn-style-light btn-sm" onClick={() => { setLCCancelDocket(true); setCurrentDocketData(docketData?.data) }} data-bs-toggle="tooltip" data-bs-placement="left" title="Cancel Docket">
                                        <span className="material-icons-sharp">
                                            close
                                        </span>
                                    </button> : ''}
                                </>}

                                {docketData?.data?.status === 'Resolved' && docketData?.data?.has_recent_docket.status ? <Link className="btn btn-warning btn-style-light btn-sm" to={`/docket/add?circuit=${docketData?.data?.circuit}`} title={`Reopened until ${formatDateTime(docketData?.data?.has_recent_docket?.opened_until, 'MMMM Do YYYY, h:mm a')}`}>
                                    <i className="material-icons">restart_alt</i></Link> : ''}

                                {user?.permissions?.dockets?.can_view_history_button && <button className="btn btn-dark btn-style-light btn-sm" title='History' onClick={() => setHistoryTab(!historyTab)}>
                                    <i className="material-icons">settings_backup_restore</i>{historyTab ? '' : ''}</button>}
                            </div>
                        }
                    }}
                />

                <Grid container>
                    <Grid item xs={12} sx={{ p: 1 }}>
                        {docketIsLoading && <Loader />}
                        {docketData && !docketIsLoading && renderDocketInformation(docketData)}
                    </Grid>
                </Grid>
                <DocketChangeModal
                    modal={docketChangeModal}
                    setModal={setDocketChangeModal}
                    currentDocketData={currentDocketData}
                    setCurrentDocketData={setCurrentDocketData}
                />
                <DocketHoldRequestModal
                    modal={holdReqModal}
                    setModal={setHoldReqModal}
                    currentDocketData={currentDocketData}
                    setCurrentDocketData={setCurrentDocketData}
                />
                <DocketResumeRequestModal
                    modal={holdResumeModal}
                    setModal={setResumeReqModal}
                    currentDocketData={currentDocketData}
                    setCurrentDocketData={setCurrentDocketData}
                />

                <DocketCancelRequestModal
                    modal={docketCancelReqModal}
                    setModal={setReqCancelModal}
                    currentDocketData={currentDocketData}
                    setCurrentDocketData={setCurrentDocketData}
                />
                <FiberTLChangeModal
                    modal={fiberTLChangeModal}
                    setModal={setfiberTLChangeModal}
                    docketCode={docketData?.data?.code}
                    data={docketData?.data}
                />
                <DocketCancelModal
                    modal={lcDocketCancelModal}
                    setModal={setLCCancelDocket}
                    currentData={currentDocketData}
                    setCurrentData={setCurrentDocketData}
                />
            </Container>
        </>
    )
}
