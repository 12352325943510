/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import PageBreadcrumbs from '../components/Common/Breadcrumbs';
import { useUser } from '../hooks/user';
import AdminDashboardPage from '../components/Dashboard/AdminDashboardPage';

export default function HomePage() {
    const { user } = useUser();


    return (
        <>
            <Helmet>
                <title>Docket App - Dashboard</title>
            </Helmet>
            <PageBreadcrumbs
                title='Dashboard'
                RightComp={() =>
                    <>
                        {user?.permissions?.circuits?.add && !user?.roles?.includes('LC') && <Link to='/circuit/add' className="btn btn-info btn-style-light"><i className="material-icons">add</i>Create Circuit</Link>}
                    </>
                }
            />
            
            <div className='container-fluid'>
                <span className="badge badge-dark">{user?.roles?.join(', ')}</span>
                <h3 className='display-6'>Hi, {user.account_name}! </h3>
                <p className='text-success'>Welcome to our online management portal.</p>
                <hr />
            </div>
            
            <AdminDashboardPage user={user} />

        </>
    )
}
