import { useMutation, useQuery } from "@tanstack/react-query";
import { getTokens } from "../../utils/shortcuts";
import { fetchJson } from "../../libs/api";
import { endpoints } from "../../config/endpoints";
import { API_HOST_URL } from "../../config";
import { useState } from "react";

const SHIFT_ROASTERS_QUERY_KEY = 'shiftroasters'


export function useShiftRoastersList(defaultParam = '', enabled = false) {
    const [param, setParam] = useState(null);
    const { isLoading, data } = useQuery([SHIFT_ROASTERS_QUERY_KEY, { param: param, defaultParam }], async (key) => {
        const param = key.queryKey[1].param;
        const defaultParam = key.queryKey[1].defaultParam;
        try {
            const { accessToken } = getTokens();
            const param_url = param ? param : defaultParam;
            const res = await fetchJson(`${API_HOST_URL}/${endpoints.core.shift_roasters}${param_url}`, {
                headers: { 'Authorization': `Bearer ${accessToken}` },
            }, true)
            return await res.json()

        } catch (err) {
            return { success: false, detail: err };
        }
    }, {
        cacheTime: Infinity,
        staleTime: 30_000,
        enabled: enabled,
    })
    return {
        shiftRoastersData: data,
        shiftRoastersIsLoading: isLoading,
        filterShiftRoastersList: async (value) => setParam(value),
    }
}

