import React, { useContext, useEffect, useState } from 'react'
import DocketStatics from './DocketStatics';
import providerContext from '../../context/provider/providerContext';
import { useDocketStatics } from '../../hooks/docket/statics';
import ChartComp from './Chards/Chart';
import ResizableBox from '../Common/ResizableBox';

export default function AdminDashboardPage({ user }) {
    const { currentProvider } = useContext(providerContext);
    const [enableApi, setAPI] = useState(false)
    const { docketStaticsData, isDocketStaticsLoading, handleStaticsParam } = useDocketStatics('', enableApi);
    // console.log(user)
    useEffect(() => {
        setAPI(true)
        handleStaticsParam(`?provider=${currentProvider?.id}`)
    }, [currentProvider])
    return (
        <div className="chartDesign">
            <div className="InerChart">
                {user?.permissions?.dockets?.view && !user?.roles?.includes('LC') && <ChartComp />}
            </div>
            <ResizableBox>
                <div className="row">
                    {docketStaticsData && !isDocketStaticsLoading &&
                        <div className='col-xl-12'>
                            {user?.permissions?.dockets?.view && <DocketStatics
                                data={docketStaticsData}
                                heading='Total Dockets'
                                provider={currentProvider}
                                docketStaticsData={docketStaticsData}
                                isDocketStaticsLoading={isDocketStaticsLoading}
                            />}
                        </div>}

                    <div className='col-xl-12'>
                        {user?.permissions?.dockets?.view && !user?.roles?.includes('LC')  &&
                            <DocketStatics
                                data={docketStaticsData?.today}
                                isToday={true}
                            />}
                    </div>
                </div>
            </ResizableBox>

            <div>


            </div>
        </div>
    )
}
