/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useEffect, useRef, useState } from 'react'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import { APP_CLUSTER, CURRENT_PROVIDER_LOCAL_DATA, DARK_MODE_LOCAL, ENABLE_PUSHER, PUSHER_APP_KEY, PUSHER_DEFAULT_CHANNEL, PUSHER_DEFAULT_EVENT } from '../../config'
import { useProviderList } from '../../hooks/core/provider'
import { getLocal, setLocal } from '../../utils/localStorage'
import { isEmptyArr } from '../../utils'
import providerContext from '../../context/provider/providerContext'
import coreContext from '../../context/core/coreContext'
import { useUser } from '../../hooks/user'
import { useDocketRequestList } from '../../hooks/lco/dockets'
import moment from 'moment'
import DocketRequestModal from '../Models/DocketRequestModal'
import MaintDocketRequestModal from '../Models/MaintDocketRequestModal'
import { useDocketUpdatesList } from '../../hooks/docket'
import { formatDateTime, showPushNotification } from '../../utils/shortcuts'
import { toast } from 'react-toastify'
import NotificationSound from "../../notification-sound.wav";
import Pusher from 'pusher-js';
import { useCorporateDocketNotificationList } from '../../hooks/docket/corporate'
import { useDebouncedCallback } from 'use-debounce'


export default function Header({ setDocketCreateModal, setDocketRequestModal }) {
    const { user } = useUser();
    const [searchParams] = useSearchParams();
    const [enableApi, setAPI] = useState(false)
    const [enableCorpApi, setCorpAPI] = useState(false)
    const [enableDocReqApi, setDocReqAPI] = useState(false)
    let navigate = useNavigate();

    // const [currentProvider, setCurrentProvider] = useState(JSON.parse(getLocal(CURRENT_PROVIDER_LOCAL_DATA)))
    const [darkMode, setDarkMode] = useState(parseInt(getLocal(DARK_MODE_LOCAL)))
    const { currentProvider, actions: { setCurrentProvider } } = useContext(providerContext);
    const { actions: { toggleMobileMenu } } = useContext(coreContext);
    const { providersData, providerIsLoading } = useProviderList();

    // Handling Docket Requests
    const { docketRequestsData, DocketRequestsIsLoading, filterDocReqList } = useDocketRequestList('', enableDocReqApi)
    const [docketReqModal, setDocketReqModal] = useState(false)
    const [maintDocketReqModal, setMaintDocketReqModal] = useState(false)
    const [currentDocketReqData, setCurrentDocketReqData] = useState(null)
    // Handling Docket Updates
    const { docketUpdatesData, docketUpdatesIsLoading, retriveUpdateData, filterUpdatesList } = useDocketUpdatesList('', enableApi)

    const { corpDocketNotifications, corpDocketNotificationsIsLoading, retriveCorpNotificationData, filterUpdatesList: corpDocketNotificationFilter } = useCorporateDocketNotificationList('', enableCorpApi)
    // console.log(docketUpdatesData)
    const handleSwitchProvider = (data) => {
        setCurrentProvider(data)
        setLocal(CURRENT_PROVIDER_LOCAL_DATA, JSON.stringify(data))
    }
    useEffect(() => {
        const data = JSON.parse(getLocal(CURRENT_PROVIDER_LOCAL_DATA))
        if (isEmptyArr(data) | data) {
            if (providersData && !providerIsLoading) {
                setCurrentProvider(providersData?.data[0])
                setLocal(CURRENT_PROVIDER_LOCAL_DATA, JSON.stringify(providersData?.data[0]))
                navigate(0)
            }
        }
    }, [navigate, providerIsLoading, providersData])

    // Handling Dark Mode / Light Mode
    useEffect(() => {
        let el = document.getElementById('body');
        if (darkMode === 1) {
            el.classList.add('darkMode');
        } else {
            el.classList.remove('darkMode');
        }
    }, [darkMode])

    const toggleDarkLightMode = () => {
        setDarkMode(darkMode === 1 ? 0 : 1)
        setLocal(DARK_MODE_LOCAL, darkMode === 1 ? 0 : 1)
    }

    const handleSeenUpdate = async (id, data) => {
        const res = await retriveUpdateData(id)
        // console.log('res -> ', res)
        if (res.success) {
            if (data?.corp_docket) {
                navigate(`/docket/corporate/${res?.data?.get_docket?.code}?ref=notificaion`);
            } else {
                navigate(`/docket/${res?.data?.get_docket?.code}?ref=notificaion`);
            }
        } else {
            toast.error(res.detail);
        }
    }
    const handleCorporateSeenUpdate = async (id) => {
        // console.log(id)
        const res = await retriveCorpNotificationData(id)
        // console.log('res -> ', res)
        if (res.success) {
            // alert('Marked as read')
            navigate(`/docket/corporate/${res?.data?.get_docket_code}?ref=notificaion`);
            // navigate(`/docket/${res.data.get_docket.code}?ref=notificaion`);
        } else {
            toast.error(res.detail);
        }
    }

    // NOTIFICATION THINGS
    const audioPlayer = useRef(null);
    function playAudio() {
        audioPlayer.current.loop = false;
        audioPlayer.current.currentTime = 0;
        audioPlayer.current.play();
    }
    const pusher = new Pusher(PUSHER_APP_KEY, {
        cluster: APP_CLUSTER,
        // enabledTransports: ['ws'],
        // forceTLS: true
    });



    const handlePusher = useDebouncedCallback(() => {
        if (user?.roles?.includes('EXP') && ENABLE_PUSHER) {
            // console.log('CALLED SOCKET')
            var channel = pusher.subscribe(PUSHER_DEFAULT_CHANNEL);
            // when a new member successfully subscribes to the channel
            channel.bind(`${PUSHER_DEFAULT_EVENT}`, (data) => {
                // console.log('GOOTTT', data)
                playAudio()
                // showPushNotification(data)
                // toast.info('1 new notification.');

                // Dismiss all existing toasts
                toast.dismiss();

                toast('🔔 1 new notification!', {
                    position: "top-right",
                    autoClose: 1000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: false,
                    pauseOnFocusLoss: false,
                    draggable: false,
                    progress: undefined,
                    theme: "light",
                    // transition: Bounce,
                });
            });
            return () => {
                pusher.unsubscribe(PUSHER_DEFAULT_CHANNEL);
            };
        }
    }, 300)

    const fetchNotifications = useDebouncedCallback(() => {
        const currentParams = Object.fromEntries([...searchParams]);
        let params = ''
        // const limit = parseInt(currentParams?.limit ?? '10');
        const limit = 10;
        const status = 'Pending';
        const type = 'all';
        params = new URLSearchParams({ ...currentParams, limit, status, type }).toString();
        setAPI(true)
        params && filterUpdatesList(`?${params}`)
    }, 600)

    const fetchCorpNotifications = useDebouncedCallback(() => {
        const currentParams = Object.fromEntries([...searchParams]);
        let params = ''
        // const status = 'Pending';
        // const limit = parseInt(currentParams?.limit ?? '10');
        const limit = 10;
        params = new URLSearchParams({ ...currentParams, limit }).toString();
        setCorpAPI(true)
        params && corpDocketNotificationFilter(`?${params}`)
    }, 600)

    const fetchDocReqList = useDebouncedCallback(() => {
        const currentParams = Object.fromEntries([...searchParams]);
        let params = ''
        const status = 'Pending';
        // const limit = parseInt(currentParams?.limit ?? '10');
        const limit = 10;
        params = new URLSearchParams({ ...currentParams, limit, status }).toString();
        setDocReqAPI(true)
        params && filterDocReqList(`?${params}`)
    }, 400)

    const initNotification = useDebouncedCallback(() => {
        // if (user?.user_role === 'EXP') {
        //     handlePusher()
        //     fetchNotifications()
        //     fetchDocReqList()
        // }

        if (['EXP'].some(role => user?.roles?.includes(role))) {
            handlePusher()
            fetchNotifications()
            fetchDocReqList()
        }
        if (user?.permissions?.dockets?.can_view_corporate_notification) {
            fetchCorpNotifications()
        }
    }, 100)

    useEffect(() => {

        initNotification()

    }, []);



    return (
        <div className="app-header mainHeader" style={{ backgroundColor: currentProvider?.background }}>
            <audio ref={audioPlayer} src={NotificationSound} />
            <nav className="navbar navbar-light navbar-expand-lg container">
                <div className="container-fluid">
                    <div className="navbar-nav" id="navbarNav">
                        <div className="logo">
                            <div className="navImageLogo">
                                <div className="navImgLogoIner">
                                    <img src="/assets/images/logo.png" alt="logo" className='img-fluid' />
                                </div>
                                <div className="navTextLogo">
                                    <Link to='/' className="active">{currentProvider?.title ?? 'Loading ...'}</Link>
                                </div>
                            </div>

                        </div>
                        <ul className="navbar-nav">
                            {!user?.roles?.includes('LC') && <li className="nav-item dropdown">
                                <a className="nav-link dropdown-toggle" href="#" id="addDropdownLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    <i className="material-icons">add</i>
                                </a>
                                <ul className="dropdown-menu" aria-labelledby="addDropdownLink">

                                    {user?.permissions?.dockets?.can_create_adv_docket && <li>
                                        <a onClick={() => setDocketCreateModal(true)} className='pointer'>New Docket <small>Adv</small></a>
                                    </li>}
                                    {user?.permissions?.circuits?.add && <li>
                                        <Link to='/circuit/add' className="dropdown-item">New Circuit</Link>
                                    </li>}
                                    {user?.permissions?.dockets?.can_request_docket && <li>
                                        <a onClick={() => setDocketRequestModal(true)} className='pointer'>New Docket Request</a>
                                    </li>}
                                    {user?.permissions?.dockets?.can_request_maint_docket && <li>
                                        <a onClick={() => setMaintDocketReqModal(true)} className='pointer'>Maint. Docket Request</a>
                                    </li>}

                                </ul>
                            </li>}
                            <li className="nav-item dropdown">
                                <a className="nav-link dropdown-toggle" href="#" id="exploreDropdownLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    <i className="material-icons-outlined">domain</i>
                                </a>
                                <ul className="dropdown-menu dropdown-lg large-items-menu" aria-labelledby="exploreDropdownLink">
                                    <li>
                                        <h6 className="dropdown-header">Switch Provider</h6>
                                    </li>
                                    {providersData && !providerIsLoading && providersData?.data?.map((data, i) =>
                                        <li key={i}>
                                            <a className="dropdown-item" href="#" onClick={() => handleSwitchProvider(data)}>
                                                <h5 className="dropdown-item-title">
                                                    {data.title}
                                                    {currentProvider?.id === data.id && <span className="badge badge-info" style={{ background: data.background }}>Active</span>}
                                                    <span className="hidden-helper-text">switch<i className="material-icons">keyboard_arrow_right</i></span>
                                                </h5>
                                            </a>
                                        </li>
                                    )}
                                </ul>
                            </li>
                        </ul>
                    </div>
                    <div className="d-flex ms-auto">
                        <ul className="navbar-nav">
                            <li className="nav-item d-lg-none" >
                                <a className="nav-link" href="#" onClick={toggleMobileMenu}><i className="fas fa-bars"></i></a>
                            </li>
                            {['EXP'].some(role => user?.roles?.includes(role)) ? <li className="nav-item">
                                <a className="nav-link language-dropdown-toggle notificationBtn" href="#" id="languageDropDown" data-bs-toggle="dropdown">
                                    <i className="far fa-bell"></i>
                                    <span className='notificationIconText'>{docketUpdatesData?.count ?? 0}</span>
                                </a>
                                <ul className="dropdown-menu dropdown-menu-end notifications-dropdown" aria-labelledby="languageDropDown">
                                    <div className="notifications-dropdown-list inerNotification">
                                        {docketUpdatesData && !docketUpdatesIsLoading && docketUpdatesData?.data?.map((data, i) =>
                                            <li key={i} onClick={() => handleSeenUpdate(data.id, data)}>
                                                <a>{data?.get_docket?.code} - {data.reason}</a>
                                                <small>{data.user} - {formatDateTime(data.created_at, 'D/M/YY h:ma')}</small>
                                            </li>

                                        )}
                                        <li>
                                            <Link to='/docket/updates' style={{ paddingBottom: '9px' }}>All notifications</Link>
                                        </li>
                                    </div>

                                </ul>
                            </li> : ''}
                            {user?.permissions?.dockets?.can_view_corporate_notification ? <li className="nav-item">
                                <a className="nav-link language-dropdown-toggle notificationBtn" href="#" id="languageDropDown" data-bs-toggle="dropdown">
                                    <i className="far fa-comments"></i>
                                    <span className='notificationIconText'>{corpDocketNotifications?.data?.length ?? '0'}</span>
                                </a>
                                <ul className="dropdown-menu dropdown-menu-end notifications-dropdown" aria-labelledby="languageDropDown">
                                    <div className="notifications-dropdown-list inerNotification">
                                        {corpDocketNotifications && !corpDocketNotificationsIsLoading && corpDocketNotifications?.data?.map((data, i) =>
                                            <li key={i} onClick={() => handleCorporateSeenUpdate(data.id)}>
                                                <a>New correspondence on {data?.get_docket_code}</a>
                                                <small>{data.comment}</small>
                                                <small> - {data?.get_created_by?.account_name} - {formatDateTime(data.created_at, 'D/M/YY h:ma')}</small>
                                            </li>

                                        )}
                                        <li>
                                            <Link to='/docket/corporate/notifications' style={{ paddingBottom: '9px' }}>All notifications</Link>
                                        </li>
                                    </div>

                                </ul>
                            </li> : ''}
                            {['EXP'].some(role => user?.roles?.includes(role)) ? <li className="nav-item">
                                <a className="nav-link nav-notifications-toggle" id="notificationsDropDown" href="#" data-bs-toggle="dropdown">{docketRequestsData?.data?.length ?? 0}</a>
                                <div className="dropdown-menu dropdown-menu-end notifications-dropdown" aria-labelledby="notificationsDropDown">
                                    <h6 className="dropdown-header">Docket Requests</h6>
                                    <div className="notifications-dropdown-list">

                                        {docketRequestsData && !DocketRequestsIsLoading && docketRequestsData?.data?.map((data, i) => <a href="#" key={i} onClick={() => {
                                            setCurrentDocketReqData(data)
                                            setDocketReqModal(true)
                                        }}>
                                            <div className="notifications-dropdown-item">
                                                <div className="notifications-dropdown-item-image">
                                                    <span className="notifications-badge bg-info text-white">
                                                        <i className="material-icons-outlined">campaign</i>
                                                    </span>
                                                </div>
                                                <div className="notifications-dropdown-item-text">
                                                    {data?.is_maint_docket ? <p className="bold-notifications-text">
                                                        Maint. Docket Req from {data?.get_provider?.title} <br />
                                                        <small>{data?.get_maint_problem?.title}</small> <br />
                                                    </p> : <p className="bold-notifications-text">
                                                        {data?.get_circuit?.code} <br />
                                                        <small>{data?.get_problem?.title}</small> <br />
                                                    </p>}
                                                    <small>{data?.get_user?.name} - {data?.created_at ? moment(data?.created_at).fromNow() : '-'}</small>
                                                </div>
                                            </div>
                                        </a>)}
                                    </div>
                                </div>
                            </li> : ''}
                        </ul>
                    </div>
                    <div className="d-flex">
                        <ul className="navbar-nav mt-2">
                            {darkMode ? <li className="nav-item lightMode" onClick={toggleDarkLightMode}>
                                <a className="nav-link modeChange" href="#">
                                    <span className='d-block iconLight'><i className="far fa-lightbulb"></i></span>
                                    <span className='lightMenuText'>Light Mode</span>
                                </a>
                            </li> :
                                <li className="nav-item darkMode" onClick={toggleDarkLightMode}>
                                    <a className="nav-link modeChange" href="#">
                                        <span className='d-block iconLight'><i className="fas fa-moon"></i></span>
                                        <span className='lightMenuText'>Dark Mode</span>
                                    </a>
                                </li>}
                        </ul>
                    </div>
                </div >
            </nav >
            {docketReqModal && <DocketRequestModal
                modal={docketReqModal}
                setModal={setDocketReqModal}
                data={currentDocketReqData}
                setCurrentDocketReqData={setCurrentDocketReqData}

            />}
            {maintDocketReqModal && <MaintDocketRequestModal
                modal={maintDocketReqModal}
                setModal={setMaintDocketReqModal}
                data={currentDocketReqData}
                setCurrentDocketReqData={setCurrentDocketReqData}
            />}
        </div>
    )
}
