import React, { useContext, useEffect, useState } from 'react'
import { useCorpateDocketList } from '../../../hooks/docket/corporate'
import DataTableBase from '../../../components/Common/DataTableBase'
import { CORP_DOCKET_LIST_COLUMNS } from '../../../config';
import { getLocal, setLocal } from '../../../utils/localStorage';
import { isObjectEmpty, secondsToHMS, truncate } from '../../../utils';
import { formatDateTime } from '../../../utils/shortcuts';
import { useUser } from '../../../hooks/user';
import { Link, useSearchParams } from 'react-router-dom';
import providerContext from '../../../context/provider/providerContext';
import moment from 'moment';
import DocketCounter from '../../../components/Counter/DocketCounter';
import { Container, Grid } from '@mui/material';
import Loader from '../../../components/Common/Loader';
import Alert from '../../../components/Common/Alert';
import PageBreadcrumbs from '../../../components/Common/Breadcrumbs';
import { Helmet } from 'react-helmet';
import CorporateDocketFilterColumnsModal from '../../../components/Models/CorporateDocketFilterColumnsModal';
import SortRecordsModal from '../../../components/Models/SortRecordsModal';
import CorporateDocketFilterModal from '../../../components/Models/CorporateDocketFilterModal';
import CorporateDocketInfoModal from '../../../components/Models/CorporateDocketInfoModal';
import CorporateDocketTicketModal from '../../../components/Models/CorporateDocketTicketModal';
import CorpDocketChangeModal from '../../../components/Models/CorpDocketChangeModal';
import ExportToExcel from '../../../components/Export/ExportToExcel';
import { export_corp_docket_columns } from '../../../utils/corp_docket_export';
import MobileCorporateDockets from '../../../components/MobileTables/MobileCorporateDockets';
import { useDebouncedCallback } from 'use-debounce';


export default function CorporateDocketList(props) {
    const { user } = useUser();
    const { currentProvider } = useContext(providerContext);
    const [searchParams, setSearchParams] = useSearchParams();

    const [enableApi, setAPI] = useState(false)
    const { corporateDockets, isCorporateDocketsLoading, filterDocketsList } = useCorpateDocketList('', enableApi);
    const [docData, setDocData] = useState(null);
    const [exportData, setExportData] = useState([]);

    // Modals
    const [docketFilterModal, setDocketFilterModal] = useState(false);
    const [docketSortModal, setDocketSortModal] = useState(false);
    const [docInfoModal, setDocInfoModal] = useState(false)
    const [docketTicketModal, setDocketTicketModal] = useState(false)
    const [corpDocketChangeModal, setCorpDocketChangeModal] = useState(false)



    // Set Export Data
    const handleExportData = useDebouncedCallback(() => {
        if (corporateDockets && !isCorporateDocketsLoading) {
            // HANDLING EXPORT
            const customHeadings = export_corp_docket_columns(corporateDockets?.data)
            setExportData(customHeadings)
        }
    }, 300)
    useEffect(() => {
        if (corporateDockets && !isCorporateDocketsLoading) {
            // HANDLING EXPORT
            handleExportData()
        }
    }, [corporateDockets, isCorporateDocketsLoading])

    // Hide / Show Table Columns
    const [filterColumnsModal, setFilterColumnsModal] = useState(false)
    const [visibleCols, setVisibleCols] = useState(JSON.parse(getLocal(CORP_DOCKET_LIST_COLUMNS)) ?? {})
    useEffect(() => {
        const data = JSON.parse(getLocal(CORP_DOCKET_LIST_COLUMNS))
        if (!data || isObjectEmpty(data)) {
            console.log('CALLING')
            setVisibleCols({
                // ftl: false,
                // technician: false,
                problem: true,
                // hirer: false,
                // third_party_docket_no: false,
                ofc_distance: false,

                circuit_id: true,
                location_a: false,
                address_a: false,
                location_b: false,
                address_b: false,
                customer_id: false,
                customer_id_alt: false,
                team: true,
                alt_team: false,
                field_team: false,


                created_at: true,
                updated_at: true,
                status: true,
                actions: true,

                select_all: false,
            })
            setLocal(CORP_DOCKET_LIST_COLUMNS, JSON.stringify(visibleCols))
        }
    }, [])

    // Docket Table Data
    const columns = [
        {
            name: 'Docket ID',
            wrap: true,
            selector: row => {
                return <div role='button' onClick={() => {
                    // setBacktoPopModal(true);
                    setDocData(row);
                }}>
                    {row?.get_closed_info?.vehicle1_to_pop && !row?.get_closed_info?.vehicle1_pop_km | row?.get_closed_info?.vehicle2_to_pop && !row?.get_closed_info?.vehicle2_pop_km | row?.get_closed_info?.vehicle3_to_pop && !row?.get_closed_info?.vehicle3_pop_km ? <span className="badge text-danger bg-light" role='button'>update</span> : ''}
                    {row.code}
                </div>
            },
            sortable: true,
            width: '150px !important',
        },
        {
            name: 'Created at',
            wrap: true,
            // selector: row => formatDateTime(row.created_at, 'DD/MM/YY hh:mm A'),
            selector: row => formatDateTime(row.created_at, 'D-M-YY h:ma'),
            // selector: row => moment(row.created_at).utc().endOf('minutes').fromNow(),
            sortable: true,
            omit: !visibleCols.created_at
        },
        {
            name: 'Circuit ID',
            wrap: true,
            selector: row => row?.get_circuit?.is_maintenance ? '-' : row?.get_circuit?.code,
            sortable: true,
            omit: !visibleCols.circuit_id,
            width: '150px !important',
        },
        {
            name: 'Customer ID 1',
            wrap: true,
            selector: row => row?.get_circuit?.customer_id,
            sortable: true,
            omit: !visibleCols.customer_id,
            width: '150px !important',
        },
        {
            name: 'Customer ID 2',
            wrap: true,
            selector: row => row?.get_circuit?.customer_id_alt,
            sortable: true,
            omit: !visibleCols.customer_id_alt,
            width: '150px !important',
        },
        {
            name: 'Location A',
            wrap: true,
            selector: row => row?.get_circuit?.location_a,
            sortable: true,
            omit: !visibleCols.location_a
        },
        {
            name: 'Location A Addr.',
            wrap: true,
            selector: row => row?.get_circuit?.address_a,
            sortable: true,
            omit: !visibleCols.address_a
        },
        {
            name: 'Location B',
            wrap: true,
            selector: row => row?.get_circuit?.location_b,
            sortable: true,
            omit: !visibleCols.location_b
        },
        {
            name: 'Location B Addr.',
            wrap: true,
            selector: row => row?.get_circuit?.address_b,
            sortable: true,
            omit: !visibleCols.address_b
        },
        {
            name: 'Ofc. Distance',
            wrap: true,
            selector: row => row?.get_circuit?.ofc_distance || 'N/A',
            sortable: true,
            omit: true
        },
        {
            name: 'Team',
            wrap: true,
            selector: row => row?.get_team?.name ?? '-',
            sortable: false,
            omit: !visibleCols.team
        },
        {
            name: 'Alt. Team',
            wrap: true,
            selector: row => row?.get_alt_team?.name ?? '-',
            sortable: false,
            omit: !visibleCols.alt_team
        },
        {
            name: 'Field Team',
            wrap: true,
            selector: row => row?.get_field_team?.name ?? '-',
            sortable: false,
            omit: !visibleCols.field_team
        },
        {
            name: 'Problem',
            wrap: true,
            selector: row => row?.get_problem?.title ? truncate(row?.get_problem?.title, 50) : '-',
            sortable: true,
            omit: !visibleCols.problem
        },
        {
            name: 'Status',
            wrap: true,
            selector: row => row.status,
            sortable: true,
            omit: !visibleCols.status,
            width: '100px !important',
        },

        {
            name: 'Updated at',
            wrap: true,
            selector: row => formatDateTime(row.updated_at, 'D-M-YY h:ma'),
            sortable: true,
            omit: !visibleCols.updated_at
        },
        {
            name: "Actions",
            button: true,
            width: "350px",
            omit: !visibleCols.actions,
            cell: (row) => (
                <div className='d-flex gap-1'>

                    <Link to={`/docket/corporate/${row.code}`} className='btn btn-sm btn-primary pb-0 btn-style-light' title='View'>
                        <span className="material-icons-sharp">
                            visibility
                        </span>
                    </Link>
                    {user?.permissions?.dockets?.can_view_corp_docket_ticket || user?.permissions?.dockets?.can_add_corp_docket_ticket ? <button className="btn btn-warning btn-style-light btn-sm pb-0" onClick={() => { setDocketTicketModal(true); setDocData(row); }} title='Ticket Support' ><span className="material-icons-sharp">live_help</span></button> : ""}
                    {row.status !== 'Cancelled' && <>
                        <button className="btn btn-dark btn-sm btn-style-light pb-0" onClick={() => { setDocInfoModal(true); setDocData(row); }} title='Quick Info' ><span className="material-icons-sharp">info</span></button>

                        {row.status !== 'Closed' ? <>

                            {user?.permissions?.dockets?.can_change_teams_corp_docket ? <button className="btn btn-info btn-style-light pb-0" onClick={() => { setDocData(row); setCorpDocketChangeModal(true); }} title='Change Teams' ><span className="material-icons-sharp">group</span></button> : ''}

                            {user?.permissions?.dockets?.can_close_corp_docket ? <Link className="btn btn-danger btn-style-light pb-0" to={`/docket/corporate/close/${row.code}`} title='Close Docket' ><span className="material-icons-sharp">stop</span></Link> : ''}

                        </> : ''}

                    </>}


                </div>
            )
        }
    ];

    const conditionalRowStyles = [
        {
            when: row => row.status === 'Pending',
            style: {
                backgroundColor: '#f7f7f7', // White
                color: '#000000de',
                '&:hover': {
                    cursor: 'pointer',
                },
            },
        },
        {
            when: row => row.status === 'Hold',
            style: {
                backgroundColor: '#5656e7', // Blue
                color: '#fff',
                '&:hover': {
                    cursor: 'pointer',
                },
            },
        },
        {
            when: row => row.status === 'Resolved',
            style: {
                backgroundColor: '#4bad48f7', // Green
                color: '#fff',
                '&:hover': {
                    cursor: 'pointer',
                },
            },
        },
        {
            when: row => row.status === 'Cancelled',
            style: {
                backgroundColor: '#d75657', // Red
                color: '#e8eaed',
                '&:hover': {
                    cursor: 'pointer',
                },
            },
        },
        {
            when: row => row.status === 'Closed',
            style: {
                backgroundColor: '#4bad4840', // Light Green
                color: '#393939', // Light Gray
                '&:hover': {
                    cursor: 'pointer',
                },
            },
        },
        {
            when: row => row.is_reopened && row.status === 'Pending',
            style: {
                backgroundColor: '#d3d3d37a', // Light Gray
                color: '#000000de',
                '&:hover': {
                    cursor: 'pointer',
                },
            },
        },
    ];

    // Fetch Data
    const fetchData = useDebouncedCallback(() => {
        const currentParams = Object.fromEntries([...searchParams]);
        let params = ''
        const limit = parseInt(currentParams?.limit ?? '20');
        const provider = parseInt(currentProvider?.id);

        if (currentParams.date_after) {
            params = new URLSearchParams({
                ...currentParams,
                date_after: moment(currentParams?.date_after).format('YYYY-MM-DD') ?? null,
                date_before: moment(currentParams?.date_before).format('YYYY-MM-DD') ?? null,
            }).toString();
        } else {
            params = new URLSearchParams({ ...currentParams, limit, provider }).toString();
        }
        setAPI(true)
        params && filterDocketsList(`?${params}`)
    }, 300)

    useEffect(() => {
        fetchData()

    }, [currentProvider, searchParams])
    return (
        <div>
            <Helmet>
                <title>Corporate Dockets - List</title>
            </Helmet>
            <PageBreadcrumbs
                title='All Corp. Dockets'
                alt='Corporate Docket List'
                RightComp={() =>
                    <div className='d-flex gap-1'>
                        {user?.permissions?.dockets?.can_add_manual_corp_docket && <Link to='/docket/corporate/add' className="btn btn-primary btn-style-light btn-sm"><i className="material-icons">add</i>Manual Docket</Link>}

                        {user?.permissions?.dockets?.can_view_corp_docket && <button className="btn btn-dark btn-style-light btn-sm" onClick={() => setDocketFilterModal(true)}><i className="material-icons">filter_alt</i>Filter</button>}
                        {user?.permissions?.dockets?.can_view_corp_docket && <button className="btn btn-dark btn-style-light btn-sm" onClick={() => setDocketSortModal(true)}><i className="material-icons">filter_list</i>Sort</button>}

                        {user?.permissions?.dockets?.can_view_corp_docket && <button className="btn btn-dark btn-style-light btn-sm" onClick={() => setFilterColumnsModal(true)}><i className="material-icons">segment</i>Visibility</button>}

                        {user?.permissions?.dockets?.can_export_corporate_docket ? <ExportToExcel exportData={exportData} isDisabled={isCorporateDocketsLoading} fileName={`Corporate Docket Export - ${formatDateTime('today', 'DD-MM-YYYY')}`} /> : ''}

                        {/* {user?.permissions?.circuits?.add && user?.permissions?.dockets?.add && <Link to='/docket/maintenance/add' className="btn btn-primary btn-style-light"><i className="material-icons">add</i>Maintenance Docket</Link>} */}
                    </div>
                }
            />
            <Container maxWidth="xxl" disableGutters>
                {user?.permissions?.dockets?.can_view_corp_docket && <DocketCounter
                    searchParams={searchParams}
                    setSearchParams={setSearchParams}
                    data={corporateDockets?.counts}
                    type='corporate'
                />}

                <Grid container sx={{ mt: 2 }}>
                    <Grid item xs={12}>
                        {isCorporateDocketsLoading && <Loader />}

                        {corporateDockets && !isCorporateDocketsLoading && !corporateDockets.success && <Alert
                            title='Warning!'
                            text={corporateDockets.detail}
                            type='info'
                        />}

                        {corporateDockets && !isCorporateDocketsLoading && corporateDockets.success && <div>
                            <div className='docketsDesktop'>

                                <DataTableBase
                                    columns={columns}
                                    data={corporateDockets?.data}
                                    direction="auto"
                                    // fixedHeaderScrollHeight="300px"
                                    pagination
                                    responsive
                                    subHeaderAlign="right"
                                    subHeaderWrap
                                    progressPending={isCorporateDocketsLoading}
                                    defaultPageSize={20}
                                    conditionalRowStyles={conditionalRowStyles}
                                />

                            </div>
                            <div className='docketsMobile'>
                                <MobileCorporateDockets
                                    data={corporateDockets?.data}
                                    user={user}
                                    setDocData={setDocData}
                                    setDocInfoModal={setDocInfoModal}
                                    setCorpDocketChangeModal={setCorpDocketChangeModal}
                                    setDocketTicketModal={setDocketTicketModal}

                                />
                            </div>
                        </div>}

                        {/* <DocketChangeModal
                            modal={docketChangeModal}
                            setModal={setDocketChangeModal}
                            currentDocketData={currentDocketData}
                            setCurrentDocketData={setCurrentDocketData}
                        />*/}
                        <CorporateDocketFilterModal
                            modal={docketFilterModal}
                            setModal={setDocketFilterModal}
                            searchParams={searchParams}
                            setSearchParams={setSearchParams}
                        />
                        <SortRecordsModal
                            modal={docketSortModal}
                            setModal={setDocketSortModal}
                            searchParams={searchParams}
                            setSearchParams={setSearchParams}
                        />

                        <CorporateDocketFilterColumnsModal
                            modal={filterColumnsModal}
                            setModal={setFilterColumnsModal}
                            visibleCols={visibleCols}
                            setVisibleCols={setVisibleCols}
                        />

                        {docInfoModal && <CorporateDocketInfoModal
                            modal={docInfoModal}
                            setModal={setDocInfoModal}
                            // cirData={circuitData?.data}
                            docData={docData}
                            setDocData={setDocData}
                        />}
                        {docketTicketModal && <CorporateDocketTicketModal
                            modal={docketTicketModal}
                            setModal={setDocketTicketModal}
                            // cirData={circuitData?.data}
                            docData={docData}
                        />}
                        {corpDocketChangeModal && <CorpDocketChangeModal
                            modal={corpDocketChangeModal}
                            setModal={setCorpDocketChangeModal}
                            docData={docData}
                            setDocData={setDocData}
                        />}

                    </Grid>
                </Grid>
            </Container>

        </div>
    )
}
