/* eslint-disable react-hooks/exhaustive-deps */
import { Container, Grid } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet';
import { Link, useSearchParams } from 'react-router-dom';
import PageBreadcrumbs from '../../components/Common/Breadcrumbs';
import DocketCounter from '../../components/Counter/DocketCounter';
import DocketChangeModal from '../../components/Models/DocketChangeModal';
import DocketFilterModal from '../../components/Models/DocketFilterModal';
import SortRecordsModal from '../../components/Models/SortRecordsModal';
import { useDocketListLite } from '../../hooks/docket';
import { formatDateTime } from '../../utils/shortcuts';
import { isObjectEmpty, secondsToHMS, truncate } from '../../utils';
import providerContext from '../../context/provider/providerContext';
import Alert from '../../components/Common/Alert';
import { useUser } from '../../hooks/user';
import DocketFilterColumnsModal from '../../components/Models/DocketFilterColumnsModal';
import { DOCKET_LIST_COLUMNS } from '../../config';
import { getLocal, setLocal } from '../../utils/localStorage';
import FiberTLChangeModal from '../../components/Models/FiberTLChangeModal';
import DocketCancelModal from '../../components/LCO/DocketCancelModal';
import BackToPopModal from '../../components/Models/BackToPopModal';
import DocketTables from './Components/DocketTables';
import { useDebouncedCallback } from 'use-debounce';
import DocketInfoModalPromise from '../../components/Models/DocketInfoModalPromise';
import DocketExportModal from '../../components/Docket/DocketExportModal';

export default function DocketListPage() {
    const { user } = useUser();
    const { currentProvider } = useContext(providerContext);
    const [searchParams, setSearchParams] = useSearchParams();
    const [enableApi, setAPI] = useState(false)
    const { docketsData, docketsIsLoading, handleParam } = useDocketListLite('', enableApi); // API Data
    // const { docketStaticsData, handleStaticsParam } = useDocketStatics(); // API Req
    const [docketChangeModal, setDocketChangeModal] = useState(false);
    const [docketFilterModal, setDocketFilterModal] = useState(false);
    const [docketSortModal, setDocketSortModal] = useState(false);
    const [currentDocketData, setCurrentDocketData] = useState({});
    // const [exportData, setExportData] = useState([]);
    const [docData, setDocData] = useState(null);
    const [docInfoModal, setDocInfoModal] = useState(false)
    const [fiberTLChangeModal, setfiberTLChangeModal] = useState(false);
    const [lcDocketCancelModal, setLCCancelDocket] = useState(false);
    const [backToPopModal, setBacktoPopModal] = useState(false);
    const [docketExportModal, setDocketExportModal] = useState(false);

    // Hide / Show Table Columns
    const [filterColumnsModal, setFilterColumnsModal] = useState(false)
    const [visibleCols, setVisibleCols] = useState(JSON.parse(getLocal(DOCKET_LIST_COLUMNS)) ?? {})
    useEffect(() => {
        const data = JSON.parse(getLocal(DOCKET_LIST_COLUMNS))
        if (!data || isObjectEmpty(data)) {
            setVisibleCols({
                ftl: false,
                technician: false,
                problem: true,
                hirer: false,
                third_party_docket_no: false,
                ofc_distance: false,

                circuit_id: true,
                location_a: false,
                address_a: false,
                location_b: false,
                address_b: false,
                customer_id: false,
                customer_id_alt: false,

                // tat_total: false,
                // total_km: false,

                created_at: true,
                updated_at: true,
                status: true,
                actions: true,

                select_all: false,
            })
            setLocal(DOCKET_LIST_COLUMNS, JSON.stringify(visibleCols))
        }
    }, [])

    // Docket Info Modal
    const handleDocketInfoModal = (data) => {
        setDocInfoModal(true)
        setDocData(data)
    }


    // Fetch Data
    const fetchData = useDebouncedCallback(() => {
        const currentParams = Object.fromEntries([...searchParams]);
        let params = ''
        const limit = parseInt(currentParams?.limit ?? '20');
        const provider = parseInt(currentProvider?.id);

        if (currentParams.date_after) {
            params = new URLSearchParams({
                ...currentParams,
                date_range: `${formatDateTime(currentParams?.date_after, 'YYYY-MM-DD H:m')},${formatDateTime(currentParams?.date_before, 'YYYY-MM-DD H:m')}`,
                limit,
                provider
            }).toString();
        } else {
            params = new URLSearchParams({ ...currentParams, limit, provider }).toString();
        }
        setAPI(true)
        params && handleParam(`?${params}`)
    }, 300)

    useEffect(() => {
        fetchData()

    }, [currentProvider, searchParams])

    return (
        <>
            <Helmet>
                <title>Dockets - List</title>
            </Helmet>
            <PageBreadcrumbs
                title='All Dockets'
                alt='Docket'
                RightComp={() =>
                    <div className='d-flex gap-1'>
                        {user?.permissions?.dockets?.view && <button className="btn btn-dark btn-style-light btn-sm" onClick={() => setDocketFilterModal(true)}><i className="material-icons">filter_alt</i>Filter</button>}
                        {user?.permissions?.dockets?.view && <button className="btn btn-dark btn-style-light btn-sm" onClick={() => setDocketSortModal(true)}><i className="material-icons">filter_list</i>Sort</button>}

                        {user?.permissions?.dockets?.view && <button className="btn btn-dark btn-style-light btn-sm" onClick={() => setFilterColumnsModal(true)}><i className="material-icons">segment</i>Visibility</button>}
                        
                        {['EXP', 'EX'].some(role => user?.roles?.includes(role)) ? <button onClick={(e) => setDocketExportModal(true)} disabled={docketsIsLoading} className='btn btn-dark btn-style-light exportBtn btn-sm'><i className="material-icons">save</i> Export</button> : ''}

                        {user?.permissions?.circuits?.add && user?.permissions?.dockets?.add && <Link to='/docket/maintenance/add' className="btn btn-primary btn-style-light btn-sm"><i className="material-icons">add</i>Maint. Docket</Link>}
                    </div>
                }
            />
            <Container maxWidth="xxl" disableGutters>
                {user?.permissions?.dockets?.view && <DocketCounter
                    searchParams={searchParams}
                    setSearchParams={setSearchParams}
                    data={docketsData?.counts}
                />}

                <Grid container sx={{ mt: 2 }}>
                    <Grid item xs={12}>
                        
                        {docketsData && !docketsIsLoading && !docketsData.success && <Alert
                            title='Warning!'
                            text={docketsData.detail}
                            type='info'
                        />}

                        {/* DOCKET TABLE */}
                        <DocketTables
                            docketsData={docketsData}
                            docketsIsLoading={docketsIsLoading}
                            visibleCols={visibleCols}
                            setBacktoPopModal={setBacktoPopModal}
                            setDocData={setDocData}
                            setCurrentDocketData={setCurrentDocketData}
                            handleDocketInfoModal={handleDocketInfoModal}
                            setfiberTLChangeModal={setfiberTLChangeModal}
                            setDocketChangeModal={setDocketChangeModal}
                            setLCCancelDocket={setLCCancelDocket}
                            user={user}
                        />


                        {/* MODALS */}
                        {docketChangeModal && <DocketChangeModal
                            modal={docketChangeModal}
                            setModal={setDocketChangeModal}
                            currentDocketData={currentDocketData}
                            setCurrentDocketData={setCurrentDocketData}
                        />}
                        {docketFilterModal && <DocketFilterModal
                            modal={docketFilterModal}
                            setModal={setDocketFilterModal}
                            searchParams={searchParams}
                            setSearchParams={setSearchParams}
                        />}
                        {docketSortModal && <SortRecordsModal
                            modal={docketSortModal}
                            setModal={setDocketSortModal}
                            searchParams={searchParams}
                            setSearchParams={setSearchParams}
                        />}
                        {filterColumnsModal && <DocketFilterColumnsModal
                            modal={filterColumnsModal}
                            setModal={setFilterColumnsModal}
                            visibleCols={visibleCols}
                            setVisibleCols={setVisibleCols}
                        />}
                        {docData && docInfoModal && <DocketInfoModalPromise
                            modal={docInfoModal}
                            setModal={setDocInfoModal}
                            data={docData}
                        />}
                        {/* Fiber Team Leader Change Modal */}
                        {docData && fiberTLChangeModal && <FiberTLChangeModal
                            modal={fiberTLChangeModal}
                            setModal={setfiberTLChangeModal}
                            docketCode={docData?.code}
                            data={docData}
                        />}
                        {docData && lcDocketCancelModal && <DocketCancelModal
                            modal={lcDocketCancelModal}
                            setModal={setLCCancelDocket}
                            currentData={docData}
                            setCurrentData={setDocData}
                        />}
                        {docData && backToPopModal && <BackToPopModal
                            modal={backToPopModal}
                            setModal={setBacktoPopModal}
                            docData={docData}
                            setDocData={setDocData}
                        />}
                        {docketExportModal && <DocketExportModal
                            modal={docketExportModal}
                            setModal={setDocketExportModal}
                            searchParams={searchParams}
                            data={docketsData?.counts ?? {}}
                        />}
                    </Grid>
                </Grid>
            </Container>
        </>
    )
}
